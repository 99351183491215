import React from "react";
import Welcome from "../master_local";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';
import {Button, Form} from "react-bootstrap";
import {DateTime} from "luxon";
import swal from "sweetalert";

class CourierLocal extends React.Component{

    state = {
        courier: [],
        TxtFechaInicial: '',
        TxtFechaFinal: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            courier: [],

        }
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        let TxtFechaInicial = this.state.TxtFechaInicial;
        let TxtFechaFinal = this.state.TxtFechaFinal;
        let Seccion = localStorage.getItem('seccion');

        let startDate = DateTime.fromFormat(TxtFechaInicial, "yyyy-MM-dd");
        let newStartDate = startDate.c.year.toString() + ('00' + startDate.c.month.toString()).slice(-2) + ('00' + startDate.c.day.toString()).slice(-2);

        let finishDate = DateTime.fromFormat(TxtFechaFinal, "yyyy-MM-dd");
        let newFinishDate = finishDate.c.year.toString() + ('00' + finishDate.c.month.toString()).slice(-2) + ('00' + finishDate.c.day.toString()).slice(-2);

        this.peticion(Seccion,newStartDate,newFinishDate);

    }


    peticion(seccion, fchin, fchfn){
        axios.get(apiUrl.apiUrl+ "tracking_courier_loc/" + seccion + "/" + fchin + "/" + fchfn, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({courier: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }


    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> COURIER LOCAL
                            <small>
                                Ingresa las fechas a realizar la busqueda
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="col-sm-12 row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <img src="src/img/ICONO-FACTURA.png" width="100%"/>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">Fecha Inicial:</label>
                                                <Form.Control value={this.state.TxtFechaInicial}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaInicial" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">Fecha Final:</label>
                                                <Form.Control value={this.state.TxtFechaFinal}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaFinal" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-2">
                                                <Button className={"btn btn-info btn-block btn-lg"} onClick = {e => this.onSubmit(e)} >Busqueda</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br/>
                        <br/>


                        <div className="panel-content">
                            {this.state.courier.map((gen) =>

                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2" style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src="src/img/RECOLECTA-LOCAL.png" width="100%"/>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <h3><b>GUIA: {gen.no_guia}</b></h3>
                                                        <h3><b>TIPO: {gen.tipo}</b></h3>
                                                        <h5><b>PAIS:</b> {gen.pais}</h5>
                                                        <h5><b>FECHA:</b> {gen.fecha_guia}</h5>
                                                        <h5><b>EMPRESA REMITENTE:</b> {gen.empresa_remitente}</h5>
                                                        <h5><b>EMPRESA DESTINATARIO:</b> {gen.empresa_destinatario}</h5>
                                                        <h5><b>FACTURA:</b> {gen.serie_factura} - {gen.no_factura}</h5>
                                                        <h5><b>PESO:</b> {gen.peso}</h5>
                                                        <h5><b>FACTURA:</b> {gen.serie_fel} - {gen.factura_fel}</h5>
                                                        <h5><b>VALOR:</b> {gen.valor}</h5>
                                                    </div>
                                                    <div className="col-sm-3" style={{backgroundColor: (gen.ultimo_estatus.split('|')[1] !== undefined) ?  (gen.ultimo_estatus.split('|')[1].length > 0) ? gen.ultimo_estatus.split('|')[1] :'#81b71a' :'#81b71a' , height:"1000", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <font color="#FFFFF">
                                                            <p><b>{
                                                                (gen.ultimo_estatus !== undefined) ? gen.ultimo_estatus.split('|')[0].trim() : ''
                                                            }</b></p>
                                                        </font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default CourierLocal;
