import React from "react";
import {Link, Route, withRouter} from "react-router-dom";
import ConfigRouting from "../Routes/ConfigMenu";
import { map } from "lodash";
import styles from "../css/MenuLateral.module.css";

class MenuLateral extends React.Component {

    componentDidMount(){
        let p_seccion = localStorage.getItem("seccion");

        if (p_seccion == null || p_seccion == "" || p_seccion == 0 || p_seccion == undefined){
            this.props.history.push("/Login");
        }
    };


    render() {
        let p_nombre = localStorage.getItem("nombre");
        let p_email = localStorage.getItem("email");
        let p_seccion = localStorage.getItem("seccion");
        return (
            <aside
                className={
                    `page-sidebar ${styles.animate} ` +
                    (this.props.collapsed ? `${styles.collapsed}` : "") +
                    (this.props.hide ? `${styles.asideHide}` : "") +
                    (this.props.showMobile ? `${styles.showSide}` : "")
                }
            >
                <div align="center">
                    <img src="src/img/logos/ICONO-TE.png" width="85%" />
                </div>

                <nav
                    id="js-primary-nav"
                    className="primary-nav"
                    role="navigation"
                >
                    <div
                        className={
                            "info-card " +
                            (this.props.collapsed
                                ? `${styles.infoCardHide}`
                                : "")
                        }
                    >
                        <img
                            src="src/img/logos/usuario_perfil.png"
                            className="profile-image rounded-circle"
                            alt="Dr. Codex Lantern"
                        />
                        <div
                            className={
                                "info-card-text " +
                                (this.props.collapsed ? `${styles.hide}` : "")
                            }
                        >
                            <a
                                href="#"
                                className="d-flex align-items-center text-white"
                            >
                                <span className="text-truncate text-truncate-sm d-inline-block">
                                    {p_nombre}
                                </span>
                            </a>
                            <span className="d-inline-block text-truncate text-truncate-sm">
                                <b>Sección:</b> {p_seccion}
                            </span>
                            <span className="d-inline-block text-truncate text-truncate-sm">
                                <b>Email:</b> {p_email}
                            </span>
                        </div>
                    </div>
                    <ul id="js-nav-menu" className="nav-menu">
                        {map(ConfigRouting, (data, index) => (
                            // <Route key={index} path={route.path} exact={route.exact}>
                            //     <route.page />
                            // </Route>
                            <Link to={data.path}>
                                <li>
                                    <a
                                        href="#"
                                        title={data.name}
                                        data-filter-tags="utilities"
                                    >
                                        <i className={data.icono} />
                                        <span
                                            className={`nav-link-text ${
                                                this.props.collapsed
                                                    ? `${styles.hide}`
                                                    : "inactive"
                                            }`}
                                            data-i18n="nav.utilities"
                                        >
                                            {data.name}
                                        </span>
                                    </a>
                                </li>
                            </Link>
                        ))}
                    </ul>
                    <div className="filter-message js-filter-message bg-success-600" />
                </nav>
                {/* END PRIMARY NAVIGATION */}
            </aside>
        );
    }
}

export default withRouter(MenuLateral);
