import React from "react";
import Welcome from "../master";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';

class MisPaquetes extends React.Component{



    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g text-center">
                            <img src={'src/img/servicios.png'}/>
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default MisPaquetes;
