import React from "react";
import Welcome from "../master_pobox";
import {Button, Form} from "react-bootstrap";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import jsPDF from 'jspdf';
import swal from "sweetalert";

class Prealerta extends React.Component{

    state = {
        ChkTerminosCondiciones: false,
        Txtcarrier: '',
        Txtdescripcion:'',
        Txttracking: '',
        Txttienda:'',
        Txtcorreo: localStorage.getItem('email'),
        Txtmonto: '',
        UploadImage: null,
    };

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;

        this.setState({
            ChkTerminosCondiciones: value,
        });
    };

    fileSelectedPerfil = (event) => {
        try {
            let file = event.target.files[0];
            const name = event.target.files[0].name;
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1).toLowerCase();

            if (file.size > 10e6) {
                cogoToast.warn(
                    <div>
                        <b>Advertencia</b>
                        <div>El tamaño maximo del archivo debe de ser de 8MB</div>
                    </div>
                );
                return;
            }

            if (ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "pdf") {
                this.setState({
                    UploadImage: event.target.files[0],
                });
            } else {
                cogoToast.warn(
                    <div>
                        <b>Advertencia</b>
                        <div>El formato del archivo debe de ser PNG, JPG, JPEG, PDF</div>
                    </div>
                );
                return;
            }
        } catch (e) {}
    };

        convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    onSubmit = async (e) => {
        e.preventDefault();

        var P_ChkTerminosCondiciones = this.state.ChkTerminosCondiciones;
        var P_Txtcarrier = this.state.Txtcarrier;
        var P_Txtdescripcion =  this.state.Txtdescripcion;
        var P_Txttracking = this.state.Txttracking;
        var P_Txttienda = this.state.Txttienda;
        var P_Txtcorreo = this.state.Txtcorreo;
        var P_Txtmonto = this.state.Txtmonto;
        var P_UploadImage = this.state.UploadImage;

        if (P_ChkTerminosCondiciones == false){
            cogoToast.warn("Se necesitan aceptar los terminos y condiciones antes de continuar");
            return;
        }

        if (P_Txtcarrier == false){
            cogoToast.warn("El carrier es necesario antes de continuar");
            return;
        }

        if (P_Txtdescripcion == false){
            cogoToast.warn("La descripcion es necesaria antes de continuar");
            return;
        }

        if (P_Txttienda == false){
            cogoToast.warn("Se necesita la tienda antes de continuar");
            return;
        }

        if (P_Txtcorreo == false){
            cogoToast.warn("Se necesita el correo antes de continuar");
            return;
        }

        if (P_Txtmonto == false){
            cogoToast.warn("Se necesita el monto antes de continuar");
            return;
        }

        if (!this.state.UploadImage) {
            cogoToast.warn(
                <div>
                    <b>Campo requerido</b>
                    <div>
                        Se necesita el documento para poder continuar
                    </div>
                </div>
            );
            return;
        }

        let today = new Date();
        let fchGenerada = today.getFullYear().toString() + ('00' + today.getMonth().toString()).slice(-2) + ('00' + today.getDate().toString()).slice(-2);
        var base64 = await this.convertBase64(P_UploadImage);


         if (!base64.includes("application/pdf"))
         {
             var doc = new jsPDF('p', 'px', 'letter',true);

             var width = doc.internal.pageSize.getWidth();
             var height = doc.internal.pageSize.getHeight();
             if ( base64.includes("image/png") )
             {
                 doc.addImage(base64,'PNG', 0, 0, width, height,"","FAST");
             }
             else
             {
                 doc.addImage(base64,'JPEG', 0, 0, width, height,"","FAST");
             }

             var output = doc.output('datauristring');

             var base64_2 = output.replace("data:application/pdf;filename=generated.pdf;base64,","");
         }
         else
         {
             var base64_2 = base64.replace("data:application/pdf;base64,","");
         }


         let data = {
             section_id: localStorage.getItem('seccion'),
             carrier: P_Txtcarrier,
             date: fchGenerada,
             description: P_Txtdescripcion,
             tracking_number: P_Txttracking,
             invoice: base64_2,
             store: P_Txttienda,
             amount: P_Txtmonto,
             email: P_Txtcorreo
         };
         const url_service = `${apiUrl.apiUrl}prealertamiami`;
         axios.post(url_service, data).then(
             (response) => {
                console.log(response.data);
                 let respuesta = response.data.codigo;
                 let mensaje = response.data.msg;

                if ( respuesta == "1")
                {
                    swal("Ayuda", "Se ha grabado correctamente su pre-alerta", "success");
                }
                else
                {
                    swal("Ayuda", "Se genero un error al momento de generar su alerta. Error: " + mensaje, "error");
                }

               this.setState({
                   ChkTerminosCondiciones: false,
                   Txtcarrier: '',
                   Txtdescripcion:'',
                   Txttracking: '',
                   Txttienda:'',
                   Txtcorreo: localStorage.getItem('email'),
                   Txtmonto: '',
                   UploadImage: null,
                   showAlert: true,
                   tituloAlert: "Información",
                   mensajeAlert:
                       "Alerta agregada correctamente",
                   tipoAlert: "success",
                   redirecciona: 0,
               })
             },
             (error) => {

                 this.setState({
                     showAlert: true,
                     tituloAlert: "Error",
                     mensajeAlert:
                         "Ha ocurrido un error, contactate con el administrador del sitio web",
                     tipoAlert: "warning",
                     redirecciona: 0,
                 });
             }
         );
    };




    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> PreAlerta
                            <small>
                                Realiza la alerta de tus paquetes acá
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">Carrier</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.Txtcarrier} name="Txtcarrier" onChange={e => this.change(e)}>
                                        <option selected>Seleccione un carrier</option>
                                        <option value={"FEDEX"}>FEDEX</option>
                                        <option value={"UPS"}>UPS</option>
                                        <option value={"DHL"}>DHL</option>
                                        <option value={"USPS"}>USPS</option>
                                        <option value={"OTROS"}>OTROS</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Descripcion</label>
                                    <Form.Control value={this.state.Txtdescripcion}  className="form-control form-control-lg" type="text" as="textarea" rows={3} placeholder="Ingrese una descripcion" name="Txtdescripcion" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Tracking</label>
                                    <Form.Control value={this.state.Txttracking}  className="form-control form-control-lg" type="text" placeholder="Ingrese el tracking" name="Txttracking" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Tienda</label>
                                    <Form.Control value={this.state.Txttienda}  className="form-control form-control-lg" type="text" placeholder="Ingrese el nombre de la tienda" name="Txttienda" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Correo Electronico</label>
                                    <Form.Control value={this.state.Txtcorreo}  className="form-control form-control-lg" type="text" placeholder="Ingrese el correo" name="Txtcorreo" onChange={e => this.change(e)}/>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Agregue factura</label>
                                    <Form.File
                                        className="position-relative"
                                        required
                                        name="file"
                                        label="Archivo de Pre-alerta"
                                        id="validationFormik107"
                                        feedbackTooltip
                                        onChange={this.fileSelectedPerfil}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Monto</label>
                                    <Form.Control value={this.state.Txtmonto}  className="form-control form-control-lg" type="number" step="0.01" placeholder="Ingrese el monto" name="Txtmonto" onChange={e => this.change(e)}/>
                                </div>

                                <p align="justify">
                                    Hago constar que todos los datos contenidos en esta solicitud son sobre mi paquete recibido por Telefacsimil, S.A., que son verídicos y pueden ser utilizados para la declaración jurada ante las autoridades Aduaneras de Guatemala. Queda a criterio del Vista de Aduana aceptar o no dicho documento. Así mismo, certifico que toda la información que he proporcionado a Telefacsimil, S.A., descrita en esta PRE-ALERTA, es verídica y comprobable; y que acepto la responsabilidad que conlleva el envío de esta información.
                                </p>

                                <div>
                                    <Form.Check
                                        name="ChkInternet"
                                        type="checkbox"
                                        checked={this.state.ChkTerminosCondiciones}
                                        onChange={this.handleInputChange}
                                        label= "Acepta las condiciones y términos antes expuestos"
                                    />
                                </div>
                                <br/>
                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Grabar</Button>
                            </form>
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default Prealerta;
