import React from 'react';
import apiUrl from "../../enviroments/enviroment";
import axios from 'axios';

class CatDepartamento extends React.Component {

    state = {
        departamentos: [],
    };


    componentDidMount() {
        this.peticion();
    }

    peticion  = async () => {
        axios.get(`${apiUrl.apiUrl}departamentos`, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({departamentos: res.data});
        });
        console.log(this.state.departamentos);
    }

    render(){
        return(
            this.state.departamentos.map((gen) => <option  key={gen.id_departamento} value={gen.id_departamento}>{gen.nombre}</option>)
        );
    }

}

export default CatDepartamento;
