

export default [

    {
        path: "/pobox",
        name: "P.O.BOX USA & Guatemala",
        type: "option",
        icono: "fal fa-globe"
    },
    {
        path:"/internacional",
        name: "Courier Internacional",
        type: "option",
        icono: "fal fa-cube"
    },
    {
        path:"/local",
        name: "Courier Local",
        type: "option",
        icono: "fal fa-th-list"
    },
    {
        path: "/CambioContrasena",
        name: "Cambio Contraseña",
        type: "option",
        icono: "fal fa-cog"
    },
    {
        path:"/Ayuda",
        name: "Ayuda",
        type: "option",
        icono: "fal fa-info-circle"
    },
    {
        path: "/Login",
        name: "Salir",
        type: "option",
        icono: "fal fa-shield-alt"
    }

]
