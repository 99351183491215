import React from "react";
import apiUrl from '../enviroments/enviroment';
import {Button, Form} from "react-bootstrap";
import cogoToast from "cogo-toast";
import Welcome from "../master_local";
import axios from "axios";
import swal from "sweetalert";


class RecolectaLocal extends React.Component{

    state = {
        Txtseccion: '',
        Txtdireccion: 'CIUDAD',
        Txttelefono: '',
        Txtnombre_entrega: '',
        Txtempresa: '',
        Txthora_envio_listo: '',
        Txtobservaciones: '',
        Txtpiezas: '',
        Txtnombre_llama: '',
        Txthora_espera: '',
        Txtid_tipo: '',
        Txttipo_uso: ''
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();
        const url_service = `${apiUrl.apiUrl}recolecta_courier_loc`;

        let Txtdireccion = this.state.Txtdireccion;
        let Txttelefono = this.state.Txttelefono;
        let Txtnombre_entrega = this.state.Txtnombre_entrega;
        let Txtempresa = this.state.Txtempresa;
        let Txthora_envio_listo = this.state.Txthora_envio_listo;
        let Txtobservaciones = this.state.Txtobservaciones;
        let Txtpiezas = this.state.Txtpiezas;
        let Txtnombre_llama = this.state.Txtnombre_llama;
        let Txthora_espera = this.state.Txthora_espera;
        let Txtid_tipo = this.state.Txtid_tipo;
        let Txttipo_uso = this.state.Txttipo_uso;

        if (Txtdireccion == null || Txtdireccion.toString().trim().length == 0){
            cogoToast.warn("La direccion no es valida, es necesaria para continuar");
            return;
        }

        if (Txttelefono == null || Txttelefono.toString().trim().length == 0){
            cogoToast.warn("El telefono no es valido, es necesario para continuar");
            return;
        }

        if (Txtnombre_entrega == null || Txtnombre_entrega.toString().trim().length == 0){
            cogoToast.warn("El nombre de quien entrega no es valido, es necesario para continuar");
            return;
        }

        if (Txtempresa == null || Txtempresa.toString().trim().length == 0){
            cogoToast.warn("El nombre de la empresa no es valido, es necesario para continuar");
            return;
        }

        if (Txthora_envio_listo == null || Txthora_envio_listo.toString().trim().length == 0){
            cogoToast.warn("La hora de envío no es valida, es necesaria para continuar");
            return;
        }

        if (Txtobservaciones == null || Txtobservaciones.toString().trim().length == 0){
            cogoToast.warn("La observacion no es valida, es necesaria para continuar");
            return;
        }

        if (Txtpiezas == null || Txtpiezas.toString().trim().length == 0){
            cogoToast.warn("La cantidad de piezas no es valida, es necesario para continuar");
            return;
        }

        if (Txtpiezas < 1){
            cogoToast.warn("Se necesita una cantidad valida de piezas para continuar");
            return;
        }

        if (Txtnombre_llama == null || Txtnombre_llama.toString().trim().length == 0){
            cogoToast.warn("El nombre quien llama no es valido, es necesario para continuar");
            return;
        }

        if (Txthora_espera == null || Txthora_espera.toString().trim().length == 0){
            cogoToast.warn("La hora que espera no es valida, es necesario para continuar");
            return;
        }

        if (Txtid_tipo == null || Txtid_tipo.toString().trim().length == 0){
            cogoToast.warn("El tipo no es valido, es necesario para continuar");
            return;
        }

        if (Txttipo_uso == null || Txttipo_uso.toString().trim().length == 0){
            cogoToast.warn("El tipo de uso no es valido, es necesario para continuar");
            return;
        }

        var data ={
            seccion: localStorage.getItem('seccion'),
            direccion: Txtdireccion,
            telefono: Txttelefono,
            nombre_entrega: Txtnombre_entrega,
            empresa: Txtempresa,
            hora_envio_listo: Txthora_envio_listo,
            observaciones: Txtobservaciones +
                ' [ Piezas ' +
                Txtpiezas +
                ' ]',
            nombre_llama: Txtnombre_llama,
            hora_espera: Txthora_espera,
            id_tipo: Txtid_tipo,
            tipo_uso: Txttipo_uso
        };

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                let respuesta = response.data.mensaje;
                console.log(response.data);
                if (respuesta == "NOTIFICACION INSERTADA"){
                    swal("Recolecta Courier Local", "Su notificación ha sido insertada", "success");

                    this.setState({
                        Txtseccion: '',
                        Txtdireccion: '',
                        Txttelefono: '',
                        Txtnombre_entrega: '',
                        Txtempresa: '',
                        Txthora_envio_listo: '',
                        Txtobservaciones: '',
                        Txtpiezas: '',
                        Txtnombre_llama: '',
                        Txthora_espera: '',
                        Txtid_tipo: '',
                        Txttipo_uso: ''
                    })
                }else{
                    swal("Recolecta Courier Local", "Ha ocurrido un error, si persiste comunicate con el administrador", "error");
                }
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });

    }


    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Recolecta Local
                            <small>
                                Formulario para poder realizar recolecciones locales
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">


                        <div className="panel-content">
                            <div align="center">
                                <img src="./src/img/RECOLECTA-LOCAL.png" width="20%"/>
                            </div>
                            <form>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Dirección</label>
                                    <Form.Control value={this.state.Txtdireccion}  as="textarea" rows={3} type="text" placeholder="Ingrese la direccion" name="Txtdireccion" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-password">Telefono</label>
                                    <Form.Control value={this.state.Txttelefono} type="number" placeholder="Ingrese el número de telefono" name="Txttelefono" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-palaceholder">Nombre Entrega</label>
                                    <Form.Control value={this.state.Txtnombre_entrega} type="text" placeholder="Ingrese el nombre de entrega" name="Txtnombre_entrega" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-palaceholder">Empresa</label>
                                    <Form.Control value={this.state.Txtempresa} type="text" placeholder="Ingrese el nombre de la empresa" name="Txtempresa" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="example-palaceholder">Hora Envío</label>
                                        <Form.Control value={this.state.Txthora_envio_listo} type="time" placeholder="Ingrese la hora de envío" name="Txthora_envio_listo" onChange={e => this.change(e)}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-palaceholder">Piezas</label>
                                    <Form.Control value={this.state.Txtpiezas} type="text" placeholder="Ingrese el número de piezas" name="Txtpiezas" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-palaceholder">Observaciones</label>
                                    <Form.Control value={this.state.Txtobservaciones} type="text" as="textarea" rows={6} placeholder="Ingrese las observaciones" name="Txtobservaciones" onChange={e => this.change(e)}/>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-palaceholder">Nombre quien llama</label>
                                    <Form.Control value={this.state.Txtnombre_llama} type="text" placeholder="Ingrese el nombre de quien llama" name="Txtnombre_llama" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="example-palaceholder">Hora Espera</label>
                                        <Form.Control value={this.state.Txthora_espera} type="time" placeholder="Ingrese la hora espera" name="Txthora_espera" onChange={e => this.change(e)}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Tipo</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.Txtid_tipo} name="Txtid_tipo" onChange={e => this.change(e)}>
                                        <option selected>Seleccione un valor</option>
                                        <option value={1}>Documentos</option>
                                        <option value={2}>Muestras</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Tipo Uso</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.Txttipo_uso} name="Txttipo_uso" onChange={e => this.change(e)}>
                                        <option selected>Seleccione un valor</option>
                                        <option value={"I"}>Individual</option>
                                        <option value={"E"}>Empresarial</option>
                                    </Form.Control>
                                </div>

                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Grabar Recolecta</Button>
                            </form>
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default RecolectaLocal;


