export default {
    /*idw: '1',
    id_web: 'visanetgt_transexpress',
    key_web: 'eb395bb1-8dfa-46eb-af8b-aa28897fdd39',
    secret_web: 'l8xMjp2hoylBbq+4RQUJ08UVd7d5/wGfrX7E+1/yMPU=',
    idi: '3',
    id_imp: 'visanetgt_telefacsimil',
    key_imp: 'daa3ef15-785a-4ffa-be8d-6f8a9c64193b',
    secret_imp: 'VH2rFRadvIJr0ofmoeKSJOqLBTsLumAgukSuihBz0NM=',
    ida: '2',
    id_app: 'visanetgt_texpressapp',
    key_app: 'd66b1d3e-5dcf-406d-b0d7-3eee974bdadc',
    secret_app: 'vgd+2JXOEKdifSC6bE0u7Dvlf4AM0raiK0F6AIqZc00='*/

    /*idw: '1',
    id_web: 'visanetgt_transexpress',
    key_web: 'eb395bb1-8dfa-46eb-af8b-aa28897fdd39',
    secret_web: 'l8xMjp2hoylBbq+4RQUJ08UVd7d5/wGfrX7E+1/yMPU=',*/
    idw: '1',
    id_web: 'visanetgt_transexpress',
    key_web: '6fe4b5a6-a17f-4563-9ad0-f25561ebd85a',
    secret_web: 'jFd6Ck37UT4w7Y81WX5q9B5Sdn0V8ZdDz0TGvah4nHY=',
    ida: '2',
    id_app: 'visanetgt_texpressapp',
    key_app: 'd66b1d3e-5dcf-406d-b0d7-3eee974bdadc',
    secret_app: 'vgd+2JXOEKdifSC6bE0u7Dvlf4AM0raiK0F6AIqZc00=',
    idi: '3',
    id_imp: 'visanetgt_telefacsimil',
    key_imp: 'e060172b-7a31-4d6a-b496-f1c7d66dfd07',              
    secret_imp: 'DPCkwJIAyT2A6RVX28FRHlcct7T1PYLb45X2abghdTI=',                     
};