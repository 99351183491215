import React, { useState } from "react";
import MenuLateral from "./Components/MenuLateral_pobox";
import Header from "./Components/Header";
import styles from "./css/MenuLateral.module.css";

export default function Welcome(props) {
    const { children } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [isHideSidebar, setIsHideSidebar] = useState(false);
    const [showMobile, setShowMobile] = useState(false);

    // handle events
    const handleCollapse = () => {
        setIsHideSidebar(false);
        setCollapsed(!collapsed);
    };

    const handleHide = () => {
        setIsHideSidebar(!isHideSidebar);
        if (!isHideSidebar) {
            setCollapsed(false);
        }
    };

    const handleShowMobile = () => {
        setShowMobile(!showMobile);
        setCollapsed(false);
        setIsHideSidebar(false);
    };

    return (
        <div>
            <div className="page-wrapper">
                <div className="page-inner">
                    <MenuLateral
                        collapsed={collapsed}
                        hide={isHideSidebar}
                        showMobile={showMobile}
                    ></MenuLateral>
                    <div className="page-content-wrapper">
                        <Header
                            handleCollapse={handleCollapse}
                            handleHide={handleHide}
                            handleShowMobile={handleShowMobile}
                        ></Header>

                        {children}
                        {/* this overlay is activated only when mobile menu is triggered */}
                        <div
                            className={
                                "page-content-overlay " +
                                (showMobile ? `${styles.showModal}` : "")
                            }
                            data-action="toggle"
                            data-class="mobile-nav-on"
                            onClick={handleShowMobile}
                        />
                        {/* END Page Content */}
                        <footer className="page-footer" role="contentinfo">
                            <div className="d-flex align-items-center flex-1 text-muted">
                                <span className="hidden-md-down fw-700">
                                    2021 © Transexpress
                                </span>
                            </div>
                            <div></div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
}
