import axios from "axios";

export default class wsObtenerToken{

    obtenerTokens(){
        return new Promise((resolve, reject) => {
            var config = {
                method: 'get',
                url: 'https://webservices.transexpress.com.gt/api/token/retrieve/' + localStorage.getItem('id_cuenta'),
                headers: {
                    'userid': 'transexp',
                    'pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
                }
            };

            axios(config).then(response => {
                //return();
                resolve(response.data);
                //resolve(JSON.stringify(response.data));
                //console.log(JSON.stringify(response.data));
            }).catch(function (error) {
                swal("Ayuda", error, "error");
                reject();
            });
        })
    }
}