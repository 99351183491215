

export default [
    {
        path:"/FuelCharge",
        name: "Tabla FuelCharge",
        type: "option",
        icono: "fal fa-clipboard-list-check"
    },
    {
        path:"/RecolectaInternacional",
        name: "Solicitud de Recolecta",
        type: "option",
        icono: "fal fa-clipboard-list-check"
    },
    {
        path:"/CotizacionInternacional",
        name: "Cotizador",
        type: "option",
        icono: "fal fa-newspaper"
    },
    {
        path:"/SolicitudSuministros",
        name: "Solicitud de Suministros",
        type: "option",
        icono: "fal fa-envelope"
    },
    {
        path:"/CourierInternacional",
        name: "Seguimiento por fecha",
        type: "option",
        icono: "fal fa-calendar-day"
    },
    {
        path:"/CourierInternacionalGuia",
        name: "Seguimiento por guía",
        type: "option",
        icono: "fal fa-box-open"
    },
    {
        path:"/GuiaInternacional",
        name: "Creación de guias",
        type: "option",
        icono: "fal fa-clipboard-list-check"
    },

    {
        path:"/AyudaInt",
        name: "Ayuda",
        type: "option",
        icono: "fal fa-info-circle"
    },
    {
        path: "/Inicio",
        name: "Volver menu principal",
        type: "option",
        icono: "fal fa-shield-alt"
    }

]
