import React from "react";
import Welcome from "../master_pobox";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';
import {Button, Form} from "react-bootstrap";
import {DateTime} from "luxon";
import swal from "sweetalert";
import Table from 'react-bootstrap/Table';

class MisPaquetes extends React.Component{

    state = {
        paquetes: [],
        TxtFechaInicial: '',
        TxtFechaFinal: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            paquetes: [],
            TxtFechaInicial: '',
            TxtFechaFinal: ''
        }
    }

    onSubmit = e => {
        e.preventDefault();

        let TxtFechaInicial = this.state.TxtFechaInicial;
        let TxtFechaFinal = this.state.TxtFechaFinal;
        let Seccion = localStorage.getItem('seccion');

        let startDate = DateTime.fromFormat(TxtFechaInicial, "yyyy-MM-dd");
        let newStartDate = startDate.c.year.toString() + ('00' + startDate.c.month.toString()).slice(-2) + ('00' + startDate.c.day.toString()).slice(-2);

        let finishDate = DateTime.fromFormat(TxtFechaFinal, "yyyy-MM-dd");
        let newFinishDate = finishDate.c.year.toString() + ('00' + finishDate.c.month.toString()).slice(-2) + ('00' + finishDate.c.day.toString()).slice(-2);

        this.peticion(Seccion,newStartDate,newFinishDate);

    }

    peticion(seccion, fchin, fchfn){
        axios.get(apiUrl.apiUrl+ "tracking_pobox/" + seccion + "/" + fchin + "/" + fchfn, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({paquetes: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /* cambio en como mostrar la informacion
    * <div className="panel-content">
                            {this.state.paquetes.map((gen) =>
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2" style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src="src/img/ICONO-PAQUETE.png" width="40%"/>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <h3><b>GUA{gen.codigoBarra}</b></h3>
                                                        <h5><b>TRACKING:</b> {gen.tracking}</h5>
                                                        <h5><b>CONTENIO:</b> {gen.contenido}</h5>
                                                        <h5><b>FECHA:</b> {gen.fechaEnvio}</h5>
                                                        <label style={{backgroundColor: (gen.ultimo_estatus || []).split("|")[1].length > 0 ? (gen.ultimo_estatus || []).split("|")[1] : "#000e2e" , height:"100", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                            <font color="#FFFFF">
                                                                {(gen.ultimo_estatus || []).split("|").map(function(estado, i) {
                                                                    return <p><b>{estado}</b></p>
                                                                })}
                                                            </font>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
    *
    * */


    /*linea de ultimo estatus
    *
    * <td>
                                        <label style={{backgroundColor: (gen.ultimo_estatus || []).split("|")[1].length > 0 ? (gen.ultimo_estatus || []).split("|")[1] : "#000e2e" , height:"100", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                            <font color="#FFFFF">
                                                {(gen.ultimo_estatus || []).split("|").map(function(estado, i) {
                                                    return <p><b>{estado}</b></p>
                                                })}
                                            </font>
                                        </label>
                                    </td>
    * */



    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader" >
                        <h1 className="subheader-block"  >
                            <i className="fal fa-info-circle"/>
                                Mis paquetes
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">

                        <div className="col-sm-12 row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <label class="label other" >
                                                    <i className="fal fa-search fa-6x" />
                                                </label>

                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">Fecha Inicial:</label>
                                                <Form.Control value={this.state.TxtFechaInicial}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaInicial" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">Fecha Final:</label>
                                                <Form.Control value={this.state.TxtFechaFinal}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaFinal" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-2">
                                                <Button variant="primary" className={"btn btn-succes btn-block btn-lg "} onClick = {e => this.onSubmit(e)} >Busqueda</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Table responsive striped bordered hover size="sm">
                            <thead >
                                <tr class="bg-primary text-white">
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Codigo de Barra</th>
                                    <th scope="col">Tracking</th>
                                    <th scope="col">Contenido</th>

                                    <th scope="col">Numero de piezas</th>
                                    <th scope="col">Remitente</th>
                                    <th scope="col">Consignatario</th>
                                    <th scope="col">Peso</th>
                                    <th scope="col">Factura</th>
                                    <th scope="col">Valor</th>


                                    <th scope="col">Ultimo Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.paquetes.map((gen) =>
                                    <tr>
                                        <td>{gen.fechaEnvio}</td>
                                        <td>GUA{gen.codigoBarra}</td>
                                        <td>{gen.tracking}</td>
                                        <td>{gen.contenido}</td>

                                        <td>{gen.noPiezas}</td>
                                        <td>{gen.remitente}</td>
                                        <td>{gen.consignatario}</td>
                                        <td>{gen.peso}</td>
                                        <td>{gen.serie_fel} - {gen.factura_fel}</td>
                                        <td>{gen.valor}</td>

                                        <td>{gen.ultimo_estatus}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default MisPaquetes;
