import React, {useState} from "react";
import Welcome from "../master_pobox";
import {Button, Form, Modal, Alert, InputGroup, Row} from "react-bootstrap";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";
import { FaCreditCard } from 'react-icons/fa';
//import cybersource from 'cybersource-rest-client'
//import cybersource2 from 'cybersource-rest-client'

function Demo1() {
    return null;
}

class DespachoDomicilio extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            paquetes: [],
            paquetes_agregados: [],
            total_pagar: '',
            opciones: [],
            mesTarjeta: [],
            anioTarjeta: [],
            chkGrabarTarjeta: true,
            validated: false,
            setValidated : false,
            tarjetas: [],
            direcciones: [],
            tarjetaReg: ''
        };
        this.hideComponent = this.hideComponent.bind(this);
    }


    state = {
        CmbFormaPago: '',
        txtMontoPagar: '',
        TxtDireccion: '',
        total_pagar: '',
        paquetes: [],
        paquetes_agregados: [],
        opciones: [],
        txtTarjeta: '',
        txtNombreTarjeta: '',
        txtFechaVencimientomes: '',
        txtFechaVencimientoanio: '',
        txtCvv: '',
        mesTarjeta: [],
        CmbMesTarjeta: '',
        CmbAnioTarjeta: '',
        CmbDireccion: ''

    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault()

        let CmbFormaPago = this.state.CmbFormaPago;
        let CmbDireccion =  this.state.CmbDireccion
        let TxtMontoPagar = this.state.TxtMontoPagar;

        if (CmbFormaPago == 10){
            if(this.state.tarjetaReg == '0'){
                let chkGrabarTarjeta = this.state.chkGrabarTarjeta;
                let txttarjeta = this.state.txtTarjeta;
                let txtNombreTarjeta = this.state.txtNombreTarjeta;
                let txtFechaVencimientoanio = this.state.CmbAnioTarjeta;
                let txtFechaVencimientomes = this.state.CmbMesTarjeta;

                if (txttarjeta == null || txttarjeta.toString().trim().length == 0){
                    cogoToast.warn("Numero de tarjeta vacia");
                    return;
                }
                if (txtNombreTarjeta == null || txtNombreTarjeta.toString().trim().length == 0){
                    cogoToast.warn("Nombre de tarjeta vacia");
                    return;
                }
                if (txtFechaVencimientomes == null || txtFechaVencimientomes.toString().trim().length == 0 || txtFechaVencimientomes == 0){
                    cogoToast.warn("Mes no valido");
                    return;
                }
                if (txtFechaVencimientoanio == null || txtFechaVencimientoanio.toString().trim().length == 0 ||  txtFechaVencimientoanio == 0){
                    cogoToast.warn("Año no valido");
                    return;
                }
            }
        }

        if (CmbFormaPago == null || CmbFormaPago.toString().trim().length == 0){
            cogoToast.warn("Seleccione la forma de pago");
            return;
        }

        if (TxtMontoPagar == null || TxtMontoPagar.toString().trim().length == 0){
            cogoToast.warn("Debe haber un monto a pagar para continuar");
            return;
        }

        if ( this.state.paquetes_agregados.length == 0 )
        {
            cogoToast.warn("Debe Seleccionar al menos un paquete");
            return;
        }

        let jsonData = "";
        let jsonTmp = "";
        let observaciones = "";
        var respuesta = "";
        jsonData += "[";
        this.state.paquetes_agregados.map((gen) =>
            jsonData += '{"codigo_barra":"' + gen.codigo + '","anio":"' + gen.anio + '","monto":"' + (Math.round(gen.montof * 100) / 100).toFixed(2) + '"},'
        )
        jsonTmp = jsonData.substring(0, jsonData.length-1);
        jsonData = jsonTmp;
        jsonData += "]";

        observaciones = "Confirmación realizada desde la APP";

        var FormData = require('form-data');
        var data = new FormData();
        data.append('seccion', '3560');
        data.append('jsonPaquetes', jsonData);
        data.append('persona_pagara', localStorage.getItem('nombre'));
        data.append('fomaPago', CmbFormaPago);
        data.append('observaciones', observaciones);
        data.append('direccion_entrega', CmbDireccion);
        data.append('monto', this.state.total_pagar);

        var config = {
            method: 'post',
            url: `${apiUrl.apiUrl}confirmacion_paquetes`,
            headers: {
                'Userid': 'transexpress',
                'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            },
            data : data
        };

        var axios = require('axios');
        axios(config).then((response) =>{
            swal("Despacho a Domicilio", "Sus paquetes han sido correctamente confirmados", "success");
            //let p_direccion = localStorage.getItem("direccion_entrega");
            this.recargar_paquetes()
            this.setState({TxtMontoPagar: "",CmbFormaPago: "0"});
        },(error) =>{
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        }).catch(function (error) {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    componentDidMount() {
        const d = new Date();
        let year = d.getFullYear();
        let p_seccion = localStorage.getItem("seccion");
        let p_direccion = localStorage.getItem("direccion_entrega");
        this.setState({TxtDireccion: p_direccion});
        this.state.opciones.push({ value:"1", label: "Pago en efectivo" });
        if ( localStorage.getItem("pago_cheque") == "1")
            this.state.opciones.push({ value:"2", label: "Paga con cheque" });
        if ( localStorage.getItem("pago_tarjeta") == "1")
            this.state.opciones.push({ value:"4", label: "Descargo Tarjeta de crédito" });
        this.state.opciones.push({ value:"9", label: "Pago con tarjeta de crédito en destino" });
        this.state.opciones.push({ value:"10", label: "Tarjeta" });


        for (let i = 1; i <= 12; i++) {
            this.state.mesTarjeta.push({ value:i, label: i });
        }
        for (let i = year; i <= (year+5); i++) {
            this.state.anioTarjeta.push({ value:i, label: i });
        }

        this.obtiene_tarjeta()
        this.obtiene_direccion()
        this.recargar_paquetes()

        //var CyberSource2 = require('cybersource-rest-client/src/authentication');
        //var CyberSource = require('cybersource-rest-client/src/ApiClient');

        const User = require('../Components/Clases/claseprueba')
        setTimeout(function()  { let user = new User(); }, 300);
        //var cybersourceRestApi = require('cybersource-rest-client');
        //const dd = require('cybersource-rest-client/src/index');
    }



    hideComponent(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        e.preventDefault();
        let CmbFormaPago = e.target.value;
        let tarjeta = document.getElementById("divTarjeta");
        let tarjetaReg = document.getElementById("divTarjetaReg");


        if (CmbFormaPago == 10) {
            if (this.state.tarjetas == null || this.state.tarjetas.toString().trim().length == 0) {
                tarjeta.removeAttribute("hidden");
                this.setState({ tarjetaReg: '0' })
            } else {
                tarjetaReg.removeAttribute("hidden");
                this.setState({ tarjetaReg: '1' })
            }
        }else{
            tarjeta.setAttribute("hidden", "hidden");
            tarjetaReg.setAttribute("hidden", "hidden");
        }
    }

    hideComponentClick(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        e.preventDefault();
        let tarjeta = document.getElementById("divTarjeta");
        let tarjetaReg = document.getElementById("divTarjetaReg");

        tarjeta.removeAttribute("hidden");
        tarjetaReg.setAttribute("hidden", "hidden");
    }

    handleChange(evt) {
        this.setState({ chkGrabarTarjeta: evt.target.checked });
    }

    paquete_seleccionado(data,e)
    {
        let isChecked = e.target.checked;
        if ( isChecked )
        {
            this.state.paquetes_agregados.push(data.gen);
            let total_a_pagar =  this.state.total_pagar * 1 ;
            total_a_pagar = total_a_pagar + ( data.gen.montof * 1 )
            this.state.total_pagar = ( total_a_pagar * 1 ).toFixed(2);
            this.setState({TxtMontoPagar:this.state.total_pagar });
        }
        else
        {
            let codigo = data.gen.codigo;
            let total_a_pagar =  this.state.total_pagar * 1 ;
            total_a_pagar = total_a_pagar - ( data.gen.montof * 1 )
            this.state.total_pagar = ( total_a_pagar * 1 ).toFixed(2);
            const temporal = this.state.paquetes_agregados.filter(paquete => paquete.codigo !== codigo );
            this.state.paquetes_agregados = temporal;
            this.setState({TxtMontoPagar:this.state.total_pagar });
        }
    }

    recargar_paquetes()
    {
        axios.get(`${apiUrl.apiUrl}paquetes_pendientes/` + localStorage.getItem('seccion'), {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({paquetes: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }
    obtiene_direccion(){
        var config = {
            method: 'get',
            url: 'https://webservices.transexpress.com.gt/api/obtiene_dir_cliente?seccion=5368',
            headers: {
                'userid': 'transexpress',
                'pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
            }
        };

        axios(config).then(response => {
            this.setState({direcciones: response.data});
        }).catch(function (error) {
            swal("Ayuda", error, "error");
            console.log(error);
        });
    }

    obtiene_tarjeta(){
        var FormData = require('form-data');
        var data = new FormData();
        data.append('seccion', '5368');
        data.append('cuenta', '3560');

        var config = {
            method: 'post',
            url: `${apiUrl.apiUrl}FC6HYXJ4`,
            headers: {
                'Userid': 'transexpress',
                'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
            },
            data : data
        };

        axios(config).then(response => {
            this.setState({tarjetas: response.data});
        }).catch(function (error) {
            swal("Ayuda", error, "error");
            console.log(error);
        });
    }

    render(){
        //

        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Despacho a Domicilio
                            <small>
                                Seleccion los paquetes que desea sean llevados a su domicilio e ingrese la información requerida<br/><br/>
                                IMPORTANTE:<br/><br/>
                                1. Si su confirmación es antes de las11:30 AM sus paquetes se enviaran en la ruta de la tarde el mismo día.<br/>
                                2. Si su confirmación es antes de las 4:30 PM sus paquetes se enviaran en la ruta de la mañana del siguiente día.<br/>
                                3. Si la dirección no es correcta o la visita no se pudo realizar por error en el dato ingresado, la siguiente entrega tendrá costo.
                            </small>
                        </h1>
                    </div>


                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            {this.state.paquetes.map((gen) =>
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <img src="src/img/ICONO-PAQUETE.png" width="100%"/>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type={'checkbox'}
                                                            id={gen.codigo}
                                                            label=""
                                                            onChange = {e => this.paquete_seleccionado({gen},e)}
                                                        />
                                                        <h3><b>GUA{gen.codigo}</b></h3>
                                                        <h5><b>CONTENIDO:</b> {gen.remitente}</h5>
                                                        <h5><b>REMITENTE:</b> {gen.peso}</h5>
                                                        <h5><b>PESO:</b> {gen.peso}</h5>
                                                        <h5><b>CONSIGNATARIO:</b> {gen.consignatario}</h5>
                                                        <h5><b>MONTO A PAGAR:</b> {(Math.round(gen.montof * 100) / 100).toFixed(2)}</h5>
                                                        <h5><b>FECHA:</b> {gen.fecha_factura}</h5>
                                                    </div>
                                                    <div className="col-sm-2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Monto a Pagar</label>
                                    <Form.Control value={this.state.TxtMontoPagar} type="number" step="0.01" placeholder="Monto a pagar" name="TxtMontoPagar" onChange={e => this.change(e)} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Seleccione una dirección registrada</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="1"  value={this.state.CmbDireccion} name="CmbDireccion" onChange={e => this.change(e)}>
                                        {this.state.direcciones.map(opt => (
                                            <option key={opt.id_entrega} value={opt.direccion_entrega}>{opt.direccion_entrega}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Forma de Pago</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.CmbFormaPago} name="CmbFormaPago" onChange={e => this.hideComponent(e, "1")}>
                                        <option key={0} value={0} selected>Seleccione una forma de pago</option>
                                        {this.state.opciones.map(opt => (
                                            <option key={opt.value} value={opt.value}>{opt.label}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group" hidden="hidden" id="divTarjetaReg">
                                    <label className="form-label">Seleccione una tarjeta registrada</label>
                                    <Form.Control  as="select" className="custom-select form-control">
                                        {this.state.tarjetas.map(opt => (
                                            <option key={opt.id_tarjeta} value={opt.id_tarjeta}>{opt.numero_tarjeta_ult}</option>
                                        ))}
                                    </Form.Control>
                                    <Button variant="secondary" size="sm" onClick={e => this.hideComponentClick(e)}>
                                        Agregar/Cambiar
                                    </Button>
                                </div>
                                <div className="form-group" hidden="hidden" id="divTarjeta">
                                    <h1><FaCreditCard></FaCreditCard></h1>
                                    <Form>
                                        <Form.Group className="mb-3" >
                                            <label className="form-label" >Numero de tarjeta</label>
                                            <Form.Control value={this.state.txtTarjeta} type="number" step="0.01" placeholder="No. Tarjeta" name="txtTarjeta" onChange={e => this.change(e)}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <label className="form-label" >Nombre tarjeta</label>
                                            <Form.Control value={this.state.txtNombreTarjeta} type="text" placeholder="Nombre Tarjeta" name="txtNombreTarjeta" onChange={e => this.change(e)}/>
                                        </Form.Group>
                                        <label className="form-label" >Fecha Vencimiento</label>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Row className="mb-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Control as="select" className="custom-select form-control" value={this.state.CmbMesTarjeta} name="CmbMesTarjeta" onChange={e => this.change(e)}>
                                                        <option key={0} value={0} selected>--</option>
                                                        {this.state.mesTarjeta.map(opt => (
                                                            <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Control as="select" className="custom-select form-control" value={this.state.CmbAnioTarjeta} name="CmbAnioTarjeta" onChange={e => this.change(e)}>
                                                        <option key={0} value={0} selected>----</option>
                                                        {this.state.anioTarjeta.map(opt => (
                                                            <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <label className="form-label" >CVV</label>
                                            <Form.Control value={this.state.txtCvv} type="number" step="0.01" placeholder="CVV" name="txtCvv" onChange={e => this.change(e)}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" hidden="hidden">
                                            <label className="form-label" >Grabar tarjeta</label>
                                            <Form.Check checked={this.state.chkGrabarTarjeta} type="checkbox" onChange={e => this.handleChange(e)}/>
                                        </Form.Group>
                                    </Form>
                                    <h1><FaCreditCard></FaCreditCard></h1>
                                </div>
                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Enviar Información</Button>
                            </form>
                        </div>
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default DespachoDomicilio;
