import React from "react";
import Welcome from "../master_internacional";
import {Button, Form, Modal, Alert} from "react-bootstrap";
import CatPais from "../Components/Servicios/CatPais"
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class CotizacionLocal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            cotizacion: [],
            tipo_envio: [],
            TxtPeso: '',
            TxtAlto: '',
            TxtLargo: '',
            TxtAncho: '',
            cmbCiudadSecundaria: '',
            ModalCotizacion: false,

        }
    }
    state = {
        ModalCotizacion: false,
        cmbTipoEnvio: '',
        cmbPais: '',
        TxtPeso: '',
        TxtAlto: '',
        TxtAncho: '',
        cmbCiudadSecundaria: '',
        TxtLargo: '',
        tipo_envio: [],
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = e => {
        e.preventDefault();

        let cmbTipoEnvio = this.state.cmbTipoEnvio;
        let cmbPais = this.state.cmbPais;
        let TxtPeso = this.state.TxtPeso;
        let TxtAlto = this.state.TxtAlto;
        let TxtAncho = this.state.TxtAncho;
        let TxtLargo = this.state.TxtLargo;
        let cmbCiudadSecundaria = this.state.cmbCiudadSecundaria;

        if (cmbTipoEnvio == 0 ||cmbTipoEnvio == null ){
            cogoToast.warn("Seleccione un tipo de envio");
            return;
        }
        if (cmbPais == 0 ||cmbPais == null ){
            cogoToast.warn("Seleccione un departamento");
            return;
        }
        if (TxtPeso == null || TxtPeso.toString().trim().length == 0){
            cogoToast.warn("El peso es necesario para continuar");
            return;
        }
        if (TxtAlto == null || TxtAlto.toString().trim().length == 0){
            TxtAlto = "0";
        }
        if (TxtAncho == null || TxtAncho.toString().trim().length == 0){
            TxtAncho = "0";
        }
        if (TxtLargo == null || TxtLargo.toString().trim().length == 0){
            TxtLargo = "0";
        }
        if ( cmbCiudadSecundaria == null || cmbCiudadSecundaria == "" )
            cmbCiudadSecundaria = "0";

        var data ={
            id_pais: cmbPais,
            alto: TxtAlto,
            largo: TxtLargo,
            ancho: TxtAncho,
            tipo_envio: cmbTipoEnvio,
            ciudad_secundaria: cmbCiudadSecundaria,
            peso_neto: TxtPeso,
            seccion:"0",
            uuid: "",
        };
        let url = apiUrl.apiUrl + "cotizacion_internacional";


        axios.post(url, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                this.setState({
                    ModalCotizacion: true,
                    cotizacion: response.data[0]
                })
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });



    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Cotización Courier Local
                            <small>
                                Ingresa la informacion para poder realizar una cotización de courier local
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">Tipo Envío:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbTipoEnvio} name="cmbTipoEnvio" onChange={e => this.change(e)}>
                                        <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                        <option key={1} value={1}>DOCUMENTOS</option>
                                        <option key={2} value={2}>MUESTRAS</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtPeso">Peso real envío Lbs:</label>
                                    <Form.Control value={this.state.TxtPeso} type="number" step="0.01" placeholder="Ingrese el peso" name="TxtPeso" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <h4>MEDIDAS EN CM - PESO VOLUMÉTRICO</h4>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtAlto">Alto en centímetros</label>
                                    <Form.Control value={this.state.TxtAlto} type="number" step="0.01" placeholder="Alto en centímetros" name="TxtAlto" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtAncho">Ancho en centímetros</label>
                                    <Form.Control value={this.state.TxtAncho} type="number" step="0.01" placeholder="Ancho en centímetros" name="TxtAncho" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtLargo">Largo en centímetros</label>
                                    <Form.Control value={this.state.TxtLargo} type="number" step="0.01" placeholder="Largo en centímetros" name="TxtLargo" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">País:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbPais} name="cmbPais" onChange={e => this.change(e)}>
                                        <option value={0} selected>Seleccione un país</option>
                                        <CatPais></CatPais>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Ciudad Secundaria:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbCiudadSecundaria} name="cmbCiudadSecundaria" onChange={e => this.change(e)}>
                                        <option key={"0"} value={"0"}>NO</option>
                                        <option key={"1"} value={"1"}>SI</option>
                                    </Form.Control>
                                </div>
                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Calcular Cotización</Button>
                            </form>
                        </div>

                    </div>
                </main>


                <Modal show={this.state.ModalCotizacion} onHide={() => this.setState({ ModalCotizacion: false })} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Alert variant="success">
                            <Alert.Heading>Cotización Internacional Generada</Alert.Heading>
                            <hr/>
                            <table>
                                <tr>
                                    <td><h4><b>Peso Envío:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{this.state.cotizacion.peso_cotizado }</h4></td>
                                </tr>

                                <tr>
                                    <td><h4><b>Sub Total $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.valor_cotizacion_dolar * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Fuel Charge $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.valor_fuel * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Descuento $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.valor_descuento * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Ciudad Secundaria $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.valor_ciudad_secundaria * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Total sin IVA $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.total_valor_dolar_sin_iva * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Total con IVA $:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.total_valor_dolar * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Total con IVA Q:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.total_valor_quetzal * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                            </table>
                        </Alert>


                    </Modal.Body>
                </Modal>

            </Welcome>
        )
    }
}
export default CotizacionLocal;
