import React, { Component } from "react";
import apiUrl from "../enviroments/enviroment";
import publicUrl from "../enviroments/enviroment";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Link, NavLink, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            TxtUsuario: "",
            TxtContrasena: "",
        };
    }

    state = {
        TxtUsuario: "",
        TxtContrasena: "",
    };

    componentDidMount() {
        localStorage.clear();
    }

    olvido_contrasena=()=>{

        this.props.history.push("/CambiarContrasena");
    }
    olvido_usuario=()=>{
        this.props.history.push("/OlvidoUsuario");
    }
    registrate=()=>{
        this.props.history.push("/Registrate");
    }
    LoginService = async () => {
        var sha256 = require("js-sha256");

        var PUSUARIO = this.state.TxtUsuario;
        var PCONTRASENA = sha256(this.state.TxtContrasena);

        const url_service =
            `${apiUrl.apiUrl}login/` + PUSUARIO + "/" + PCONTRASENA;
        console.log(url_service);
        var respuesta = false;

        if (PUSUARIO == null || PUSUARIO.toString().trim().length == 0) {
            cogoToast.warn("El usuario es necesario para poder continuar");
            return;
        }

        if (PCONTRASENA == null || PCONTRASENA.toString().trim().length == 0) {
            cogoToast.warn("La contraseña es necesaria para poder continuar");
            return;
        }

        axios
            .get(url_service, {
                headers: {
                    userid: "transexpress",
                    pwduser: "JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S",
                },
            })
            .then(
                (response) => {

                    respuesta = response.data[0].existe;

                    if (parseInt(respuesta) == 1) {
                        localStorage.setItem(
                            "bloqueado",
                            response.data[0].bloqueado
                        );
                        if ( response.data[0].reiniciar == "1" )
                            this.props.history.push("/CambiarContrasena");
                        else
                        {
                            localStorage.setItem("email", response.data[0].email);
                            localStorage.setItem("existe", response.data[0].existe);
                            localStorage.setItem("nombre", response.data[0].nombre);
                            localStorage.setItem("pin", response.data[0].pin);
                            localStorage.setItem("pago_cheque", response.data[0].pago_cheque);
                            localStorage.setItem("pago_tarjeta", response.data[0].pago_tarjeta);
                            localStorage.setItem("direccion_entrega", response.data[0].direccion_entrega);
                            localStorage.setItem("seccion",response.data[0].seccion);
                            localStorage.setItem("rtu_nombre",response.data[0].rtu_nombre);
                            localStorage.setItem("rtu_apellido",response.data[0].rtu_apellido);
                            localStorage.setItem("rtu_direccion",response.data[0].rtu_direccion);
                            localStorage.setItem("retenida",response.data[0].retenida);
                            localStorage.setItem("rtu_celular",response.data[0].rtu_celular);
                            localStorage.setItem("id_cuenta",response.data[0].id_cuenta);
                            this.props.history.push("/Inicio");

                        }


                    } else {
                        swal(
                            "Login",
                            "El usuario o la contraseña son incorrectos, verifique",
                            "error"
                        );
                    }
                },
                (error) => {
                    swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
                }
            );
    };

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type,
        });
    };

    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.LoginService();
        }
    };

    render() {
        return (
            <div>
                <div className="page-wrapper auth">
                    <div className="page-inner bg-brand-gradient">
                        <div className="page-content-wrapper bg-transparent m-0">
                            <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                                <div className="d-flex align-items-center container p-0">
                                    <img
                                        src={"../src/img/logos/ICONO-TE.png"}
                                        alt="SmartAdmin WebApp"
                                        width="200"
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                        </div>
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                            <div className="card p-4 rounded-plus bg-faded">
                                                <form
                                                    id="js-login"
                                                    noValidate
                                                    action="intel_analytics_dashboard.html"
                                                >
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="username"
                                                        >
                                                            Usuario
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .TxtUsuario
                                                            }
                                                            className="form-control form-control-lg"
                                                            type="text"
                                                            placeholder="Ingrese su usuario"
                                                            name="TxtUsuario"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="password"
                                                        >
                                                            Contraseña
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .TxtContrasena
                                                            }
                                                            type="password"
                                                            className="form-control form-control-lg"
                                                            placeholder="Ingrese la contraseña"
                                                            name="TxtContrasena"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                            onKeyPress={
                                                                this.onKeyUp
                                                            }
                                                        />
                                                    </div>
                                                    <div className="row no-gutters">
                                                        <div className="col-lg-12 pr-lg-1 my-2">
                                                            <Button
                                                                className={
                                                                    "btn btn-primary btn-block btn-lg"
                                                                }
                                                                onClick={
                                                                    this
                                                                        .LoginService
                                                                }
                                                            >
                                                                Ingresar
                                                            </Button>
                                                            <Button
                                                                className={
                                                                    "btn btn-success btn-block btn-lg"
                                                                }
                                                                onClick={
                                                                    this
                                                                        .registrate
                                                                }
                                                            >
                                                                Crear Cuenta
                                                            </Button>
                                                            <Button
                                                                className={
                                                                    "btn btn-warning btn-block btn-lg"
                                                                }
                                                                onClick={
                                                                    this
                                                                        .olvido_usuario
                                                                }
                                                            >
                                                                Olvido Usuario
                                                            </Button>
                                                            <Button
                                                                className={
                                                                    "btn btn-light btn-block btn-lg"
                                                                }
                                                                onClick={
                                                                    this
                                                                        .olvido_contrasena
                                                                }
                                                            >
                                                                Cambiar Contraseña
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                        </div>
                                    </div>
                                    <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                        2021 ©{" "}
                                        <a
                                            href="http://www.transexpress.com.gt/"
                                            className="text-white opacity-40 fw-500"
                                            title="Transexpress"
                                            target="_blank" rel="noreferrer"
                                        >
                                            Transexpress
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    change = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
}

export default withRouter(Login);

