import Test from "../Pages/test";
import Login from "../Pages/Login";
import RecolectaInternacional from "../Pages/RecolectaInternacional";
import RecolectaLocal from "../Pages/RecolectaLocal";
import Cotizacion from "../Pages/Cotizacion";
import Prealerta from "../Pages/Prealerta";
import EstadoCuenta from "../Pages/EstadoCuenta";
import MisPaquetes from "../Pages/MisPaquetes";
import MisContactos from "../Pages/MisContactos";
import CambioContrasena from "../Pages/CambioContrasena";
import Ayuda from "../Pages/Ayuda";
import Registrate from "../Pages/Registrate";
import CrearCasillero from "../Pages/CrearCasillero";
import CourierLocal from "../Pages/CourierLocal";
import Home from "../Pages/Home";
import CourierInternacional from "../Pages/CourierInternacional";
import OlvidoUsuario from "../Pages/OlvidoUsuario";
import CambiarContrasena from "../Pages/CambiarContrasena";
import pobox from "../Pages/pobox";
import internacional from "../Pages/internacional";
import local from "../Pages/local";
import Ayuda_pobox from "../Pages/Ayuda_pobox";
import AyudaInternacional from "../Pages/AyudaInternacional";
import AyudaLocal from "../Pages/AyudaLocal";
import DespachoADomiciolio from "../Pages/DespachoDomicilio";
import CourierLocalGuia from "../Pages/CourierLocalGuia";
import CotizacionLocal from "../Pages/CotizacionLocal";
import GuiaLocal from "../Pages/GuiaLocal";
import FuelCharge from "../Pages/FuelCharge";
import CotizacionInternacional from "../Pages/CotizacionInternacional"
import CourierInternacionalGuia from "../Pages/CourierInternacionalGuia";
import SolicitudSuministros from "../Pages/SolicitudSuministros";
import GuiaInternacional from "../Pages/GuiaInternacional";
import DespachoADomiciolio2 from "../Pages/DespachoDomicilio2";
import MisDirecciones from "../Pages/MisDirecciones";
import pruebaa from "../Pages/prueba";
import MisTarjetas from "../Pages/MisTarjetas";

export default [
    {
        path: `/Inicio`,
        exact: true,
        page: Home,
    },
    {
        path: `/SolicitudSuministros`,
        exact: true,
        page: SolicitudSuministros,
    },
    {
        path: `/CourierInternacionalGuia`,
        exact: true,
        page: CourierInternacionalGuia,
    },
    {
        path: `/CotizacionInternacional`,
        exact: true,
        page: CotizacionInternacional,
    },
    {
        path: `/RecolectaLocal`,
        exact: true,
        page: RecolectaLocal,
    },
    {
        path: `/RecolectaInternacional`,
        exact: true,
        page: RecolectaInternacional,
    },
    {
        path: `/login`,
        exact: true,
        page: Login,
    },
    {
        path: `/FuelCharge`,
        exact: true,
        page: FuelCharge,
    },
    {
        path: `/DespachoADomiciolio`,
        exact: true,
        page: DespachoADomiciolio,
    },
    {
        path: `/DespachoADomiciolio2`,
        exact: true,
        page: DespachoADomiciolio2,
    },
    {
        path: `/GuiaLocal`,
        exact: true,
        page: GuiaLocal,
    },
    {
        path: `/GuiaInternacional`,
        exact: true,
        page: GuiaInternacional,
    },
    {
        path: `/RecolectaInternacional`,
        exact: true,
        page: RecolectaInternacional,
    },
    {
        path: `/RecolectaLocal`,
        exact: true,
        page: RecolectaLocal,
    },
    {
        path: `/Cotizacion`,
        exact: true,
        page: Cotizacion,
    },
    {
        path: `/Prealerta`,
        exact: true,
        page: Prealerta,
    },
    {
        path: `/EstadoCuenta`,
        exact: true,
        page: EstadoCuenta,
    },
    {
        path: `/MisPaquetes`,
        exact: true,
        page: MisPaquetes,
    },
    {
        path: `/Contactos`,
        exact: true,
        page: MisContactos,
    },
    {
        path: `/Direcciones`,
        exact: true,
        page: MisDirecciones,
    },
    {
        path: `/CambioContrasena`,
        exact: true,
        page: CambioContrasena,
    },
    {
        path: `/AyudaPobox`,
        exact: true,
        page: Ayuda_pobox,
    },
    {
        path: `/AyudaInt`,
        exact: true,
        page: AyudaInternacional,
    },
    {
        path: `/AyudaLoc`,
        exact: true,
        page: AyudaLocal,
    },
    {
        path: `/AyudaPobox`,
        exact: true,
        page: Ayuda_pobox,
    },

    {
        path: `/Ayuda`,
        exact: true,
        page: Ayuda,
    },
    {
        path: `/Registrate`,
        exact: true,
        page: Registrate,
    },
    {
        path: `/CrearCasillero`,
        exact: true,
        page: CrearCasillero,
    },
    {
        path: `/CourierLocal`,
        exact: true,
        page: CourierLocal,
    },
    {
        path: `/CotizacionLocal`,
        exact: true,
        page: CotizacionLocal,
    },
    {
        path: `/CourierLocalGuia`,
        exact: true,
        page: CourierLocalGuia,
    },
    {
        path: `/CourierInternacional`,
        exact: true,
        page: CourierInternacional,
    },
    {
        path: `/OlvidoUsuario`,
        exact: true,
        page: OlvidoUsuario,
    },
    {
        path: `/CambiarContrasena`,
        exact: true,
        page: CambiarContrasena,
    },
    {
        path: `/pobox`,
        exact: true,
        page: pobox,
    },
    {
        path: `/internacional`,
        exact: true,
        page: internacional,
    },
    {
        path: `/local`,
        exact: true,
        page: local,
    },
    {
        path: `/Tarjetas`,
        exact: true,
        page: MisTarjetas,
    },
    {
        path: "**",
        page: Login,
    },
];
