import React from "react";
import cogoToast from "cogo-toast";
import generarFacturas from "../Components/Clases/claseFactura";
import swal from "sweetalert";
import data from "../Components/Clases/claseCyber";
import generarDescargo from "../Components/Clases/claseprueba";
import generarRecibo from "../Components/Clases/claseRecibo";
import apiUrl from "../enviroments/enviroment";
import axios from "axios";
import Welcome from "../master_pobox";
import {Button, Card, Form, Row} from "react-bootstrap";
import {FaCreditCard} from "react-icons/fa";
import CatDepartmaento from "../Components/Servicios/CatDepartmaento";


class MisDirecciones extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            zona: [{zona: 1},
                {zona: 2},
                {zona: 3},
                {zona: 4},
                {zona: 5},
                {zona: 6},
                {zona: 7},
                {zona: 8},
                {zona: 9},
                {zona: 10},
                {zona: 11},
                {zona: 12},
                {zona: 13},
                {zona: 14},
                {zona: 15},
                {zona: 16},
                {zona: 17},
                {zona: 18},
                {zona: 19},
                {zona: 20},
                {zona: 21}],
            chkDireccionDefault: false,
            txtDireccion: '',
            txtAlias: '',
            txtDetalleDireccion: '',
            txtZona: '1',
            direcciones: [],
            direccionActualiza: '',
            txtDepartamentoDestinatario: '0',
            txtMunicipioDestinatario: '0',
            tmpMunicipios_destinatario: [],
        };
    }

    state = {
        semaforoDire: false,
        tmpMunicipios_destinatario: [],
    }
    componentDidMount() {
        this.obtiene_direccion()
        let direccionesGuardadas = document.getElementById("divDireccionesGuardadas");
        direccionesGuardadas.removeAttribute("hidden");

        axios.get(`${apiUrl.apiUrl}municipios` , {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({municipios: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    handleChange(evt) {
        this.setState({ chkDireccionDefault: evt.target.checked });
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    obtiene_direccion(){
        var config = {
            method: 'get',
            url: 'https://webservices.transexpress.com.gt/api/obtiene_dir_cliente?seccion=' + localStorage.getItem('seccion'),
            headers: {
                'userid': 'transexpress',
                'pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
            }
        };

        axios(config).then(response => {
            this.setState({direcciones: response.data});
            //console.log(JSON.stringify(response.data))
        }).catch(function (error) {
            swal("Ayuda", error, "error");
            //console.log(error);
        });
    }

    onSubmit = async e => {
        e.preventDefault()

        var axios = require('axios');
        var data = JSON.stringify({
            "seccion": localStorage.getItem('seccion'),
            "direccion_entrega": this.state.txtDireccion,
            "detalle": this.state.txtDetalleDireccion,
            "alias": this.state.txtAlias,
            "zona": this.state.txtZona,
            "predeterminada": this.state.chkDireccionDefault,
            "id_departamento": this.state.txtDepartamentoDestinatario,
            "id_municipio": this.state.txtMunicipioDestinatario
        });

        var config = {
            method: 'post',
            url: 'https://webservices.transexpress.com.gt/api/save_address',
            headers: {
                'Userid': 'transexpress',
                'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(function (response) {
                let prueb = JSON.stringify(response.data);
                let userObj = JSON.parse(prueb);
                //console.log(userObj['mensaje']);
                if(userObj['mensaje'] == '1'){
                    swal("Dirección", "Su dirección ha sido grabada", "success");
                }else{
                    swal("Dirección", "No se guardo la dirección, intente mas tarde", "error");
                }
            })
            .catch(function (error) {
                swal("Dirección", "No se guardo la dirección, intente mas tarde", "error");
            });
        this.delay(2000).then(() => console.log('Recargano'));
        this.valTarDefaultd();
        this.obtiene_direccion();

        let direccionesGuardadas = document.getElementById("divDireccionesGuardadas");
        let datosDireccion = document.getElementById("divDatosDireccion");
        direccionesGuardadas.removeAttribute("hidden");
        datosDireccion.setAttribute("hidden", "hidden");
    }

    onSubmitUpdate = async e => {
        e.preventDefault()

        var axios = require('axios');
        var data = JSON.stringify({
            "id_entrega": this.state.direccionActualiza,
            "seccion": localStorage.getItem('seccion'),
            "direccion_entrega": this.state.txtDireccion,
            "detalle": this.state.txtDetalleDireccion,
            "alias": this.state.txtAlias,
            "zona": this.state.txtZona,
            "predeterminada": this.state.chkDireccionDefault
        });

        var config = {
            method: 'post',
            url: 'https://webservices.transexpress.com.gt/api/update_address',
            headers: {
                'Userid': 'transexpress',
                'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(function (response) {
                let prueb = JSON.stringify(response.data);
                let userObj = JSON.parse(prueb);
                if(userObj[0]['response'] == 'Actualizada'){
                    swal("Dirección", "Su dirección ha sido actualizada", "success");
                }else{
                    swal("Dirección", "No se actualizo la dirección, intente mas tarde", "error");
                }
            })
            .catch(function (error) {
                swal("Dirección", "No se actualizo la dirección, intente mas tarde", "error");
            });
        this.delay(2000).then(() => console.log('Recargano'));
        this.obtiene_direccion();
        this.valTarDefaultd();
        this.setState({ semaforoDire: false });
        this.hideComponent(1,0)

        let bottonnuevo = document.getElementById("divBotonNuevo");
        let botoncancelar = document.getElementById("divBotonCancelar");


        let direccionesGuardadas = document.getElementById("divDireccionesGuardadas");
        let datosDireccion = document.getElementById("divDatosDireccion");
        direccionesGuardadas.removeAttribute("hidden");
        datosDireccion.setAttribute("hidden", "hidden");
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    valTarDefaultd(){
        this.setState({txtDireccion: ''});
        this.setState({txtAlias: ''});
        this.setState({txtDetalleDireccion: ''});
        this.setState({txtZona: '1'});
        this.setState({chkDireccionDefault: false});
    }

    onBorrar(data){
        var axios = require('axios');
        var config = {
            method: 'post',
            url: 'https://webservices.transexpress.com.gt/api/delete_address/' + data,
            headers: {
                'Userid': 'transexpress',
                'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
            }
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                let prueb = JSON.stringify(response.data);
                let userObj = JSON.parse(prueb);

                if(userObj[0]['response'] == 'Eliminada'){
                    swal("Dirección", "Dirección borrada", "success");
                }else{
                    swal("Dirección", "No se borro la dirección, intente mas tarde", "error");
                }
            })
            .catch(function (error) {
                swal("Dirección", "No se borro la dirección, intente mas tarde", "error");
            });
        this.delay(2000).then(() => console.log('Recargano'));
        this.obtiene_direccion()
    }

    onActualizar(data){

        /*let datad2 = this.state.direcciones[data];
        console.log(datad2);*/

        var obj = this.state.direcciones;
        var x;
        for (x of obj) {
            if(x.id_entrega == data){
                this.setState({direccionActualiza: data})
                this.setState({txtDireccion: x.direccion_entrega});
                this.setState({txtAlias: x.alias});
                this.setState({txtDetalleDireccion: x.detalle});
                this.setState({txtZona: x.zona});
                var select = document.getElementById('divZona');
                select.value = x.zona;
                //document.getElementById('divZona').getElementsByTagName('zona').selected = x.zona
                //this.countryForm.controls['zona'].setValue(zona.filter(c => c.id === zona.country.id));
                if(x.predeterminada == "1")
                    this.setState({chkDireccionDefault: true});
                else
                    this.setState({chkDireccionDefault: false});
            }
        }

        this.hideComponent(2, 0);
    }

    change_departamento_destinatario= e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_departamento == e.target.value );
        this.setState({
            [e.target.name]: e.target.value, tmpMunicipios_destinatario: temporal
        })
    }

    change_municipio_destinatario= e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_municipio == e.target.value );
        if ( temporal.length > 0 )
        {
            this.state.txtNombreMunicipio = temporal[0].nombre;
        }
        else
            this.state.txtNombreMunicipio = "";
        console.log(this.state.txtNombreMunicipio );
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    hideComponent(e, ee) {
        let direccionesGuardadas = document.getElementById("divDireccionesGuardadas");
        let datosDireccion = document.getElementById("divDatosDireccion");
        let bottonnuevo = document.getElementById("divBotonNuevo");
        let botoncancelar = document.getElementById("divBotonCancelar");
        let btnActualizar = document.getElementById("btnActualizar");
        let btnGuardar = document.getElementById("btnGuardar");

        if(this.state.semaforoDire){
            direccionesGuardadas.removeAttribute("hidden");
            bottonnuevo.removeAttribute("hidden");
            botoncancelar.setAttribute("hidden", "hidden");
            datosDireccion.setAttribute("hidden", "hidden");

            this.setState({ semaforoDire: false });
        }else{
            direccionesGuardadas.setAttribute("hidden", "hidden");
            bottonnuevo.setAttribute("hidden", "hidden");
            botoncancelar.removeAttribute("hidden");
            datosDireccion.removeAttribute("hidden");

            this.setState({ semaforoDire: true });
        }

        if(e == 1){
            this.valTarDefaultd();
            btnActualizar.setAttribute("hidden", "hidden");
            btnGuardar.removeAttribute("hidden");
        }else{
            btnActualizar.removeAttribute("hidden");
            btnGuardar.setAttribute("hidden", "hidden");
        }
    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-address-book"/> MIS DIRECCIONES
                            <small>
                                Direcciones de entrega POBOX
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g" id="divBotonNuevo">
                        <div className="panel-content">
                            <form>
                                <Button variant="primary" onClick={e => this.hideComponent(1, 0)}>Agregar dirección de entrega</Button>
                            </form>
                        </div>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g" hidden="hidden" id="divBotonCancelar">
                        <div className="panel-content">
                            <form>
                                <Button variant="primary" onClick={e => this.hideComponent(1, 0)}>Cancelar</Button>
                            </form>
                        </div>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g" hidden="hidden" id="divDireccionesGuardadas">
                        <div className="panel-content">
                            {this.state.direcciones.map((gen) =>
                                <div className="row">
                                <div className="col-sm-2">
                                    <img src="src/img/ICONO-PAQUETE.png" width="100%"/>
                                </div>
                                    <div className="col-sm-2">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Body>
                                                <Card.Title>{gen.alias}</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted">Zona {gen.zona}</Card.Subtitle>
                                                <Card.Text>{gen.direccion_entrega}</Card.Text>
                                                <Card.Link href="#" onClick = {e => this.onBorrar((gen.id_entrega))}>Borrar dirección</Card.Link>
                                                <Card.Link href="#" onClick = {e => this.onActualizar((gen.id_entrega))}>Modificar dirección</Card.Link>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g" hidden="hidden" id="divDatosDireccion">
                        <Form>
                            <Form.Group className="mb-3" controlId="formAlias">
                                <Form.Label>Alias</Form.Label>
                                <Form.Control type="text" placeholder="Casa/Oficina/Otro" name="txtAlias" value={this.state.txtAlias} onChange={e => this.change(e)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDireccion">
                                <Form.Label>Dirección de entrega</Form.Label>
                                <Form.Control as="textarea" rows={3} name="txtDireccion" value={this.state.txtDireccion} onChange={e => this.change(e)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formDetalleDireccion">
                                <Form.Label>Detalle</Form.Label>
                                <Form.Control as="textarea" rows={3} name="txtDetalleDireccion" value={this.state.txtDetalleDireccion} onChange={e => this.change(e)}/>
                            </Form.Group>
                            <Form.Group controlId="formZona">
                                <Form.Label>Zona</Form.Label>
                                <Form.Control  as="select" className="custom-select form-control" defaultValue="1" id="divZona" name="txtZona" value={this.state.txtZona} onChange={e => this.change(e)}>
                                    {this.state.zona.map(opt => (
                                        <option key={opt.zona} value={opt.zona}>{opt.zona}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formZonaDefault">
                                <label className="form-label" >Dirección por defecto</label>
                                <Form.Check checked={this.state.chkDireccionDefault} type="checkbox" onChange={e => this.handleChange(e)}/>
                            </Form.Group>

                            <Form.Group controlId="formdepmun">
                                <div className="form-group">
                                    <label className="form-label">Departamento Destinatario</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtDepartamentoDestinatario} name="txtDepartamentoDestinatario" onChange={e => this.change_departamento_destinatario(e)}>
                                        <option value={0} selected>Seleccione un departamento</option>
                                        <CatDepartmaento></CatDepartmaento>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Municipio Destinatario</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtMunicipioDestinatario } name="txtMunicipioDestinatario" onChange={e => this.change_municipio_destinatario(e)}>
                                        <option key={0} value={0}  selected>Seleccione un Municipio</option>
                                        {this.state.tmpMunicipios_destinatario.map(opt => (
                                            <option key={opt.id_municipio} value={opt.id_municipio}>{opt.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Form.Group>
                            <Button variant="primary" id="btnGuardar" block onClick = {e => this.onSubmit(e)} >Guardar Información</Button>
                            <Button variant="primary" id="btnActualizar" block onClick = {e => this.onSubmitUpdate(e)} >Actualizar Información</Button>
                        </Form>
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default MisDirecciones;