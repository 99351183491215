import React from "react";
import Welcome from "../master_internacional";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';
import {Button, Form} from "react-bootstrap";
import {DateTime} from "luxon";
import swal from "sweetalert";

class CourierInternacionalGuia extends React.Component{

    state = {
        courier: [],
        TxtNoGuia: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            courier: []
        }
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        let TxtNoGuia = this.state.TxtNoGuia;
        let Seccion = localStorage.getItem('seccion');

        console.log(TxtNoGuia);

        this.peticion(Seccion,TxtNoGuia);

    }


    peticion(seccion, no_guia){
        axios.get(apiUrl.apiUrl+ "guia_courier/" + no_guia , {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            console.log(res.data);
            this.setState({courier: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> COURIER INTERNACIONAL
                            <small>
                                Ingresa la guía a realizar la busqueda
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="col-sm-12 row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <img src="src/img/ICONO-FACTURA.png" width="100%"/>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">No. de Guía:</label>
                                                <Form.Control value={this.state.TxtNoGuia}  className="form-control form-control-lg" type="text" placeholder="No. Guía" name="TxtNoGuia" onChange={e => this.change(e)}/>
                                            </div>
                                           <div className="col-sm-6">
                                                <Button className={"btn btn-info btn-block btn-lg"} onClick = {e => this.onSubmit(e)} >Busqueda</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="panel-content">
                            {this.state.courier.map((gen) =>

                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2" style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src="src/img/RECOLECTA-LOCAL.png" width="100%"/>
                                                    </div>
                                                    <div className="col-sm-10">
                                                        <h5><b>FECHA:</b> {gen.FECHA}</h5>
                                                        <h5><b>ESTADO:</b> {gen.DESCRIPCION}</h5>
                                                        <h5><b>DETALLE:</b> {gen.DETALLE}</h5>
                                                        <h5><b>DESTINATARIO:</b> {gen.DESTINATARIO}</h5>
                                                        <h5><b>REMITENTE:</b> {gen.REMITENTE}</h5>
                                                        <h5><b>PESO:</b> {gen.PESO}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default CourierInternacionalGuia;
