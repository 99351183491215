import React from "react";
import Welcome from "../master";

class Test extends React.Component{
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Privacy
                            <small>
                                SmartAdmin Privacy
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">


                    </div>
                </main>
            </Welcome>
        )
    }
}
export default Test;
