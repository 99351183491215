import React from "react";
import Welcome from "../master_internacional";
import {Button, Form, Modal, Alert, Tab, Tabs} from "react-bootstrap";
import CatPais from "../Components/Servicios/CatPais"
import CatRemitentes from "../Components/Servicios/CatRemitentes"
import CatDestinatarios  from "../Components/Servicios/CatDestintarios";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class GuiaInternacional extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            seccion: '',
            persona_llama: '',
            txt_nombre_remitente: '',
            txt_email_remitente: '',
            txt_empresa_remitente: '',
            txt_direccion_remitente: '',
            txt_zipcode_remitente: '',
            txt_ciudad_remitente: '',
            txt_pais_remitente: '',
            txt_telefono_remitente: '',
            txt_nombre_destinatario: '',
            txt_email_destinatario: '',
            txt_empresa_destinatario: '',
            txt_direccion_destinatario: '',
            txt_estado_destinatario: '',
            txt_zipcode_destinatario: '',
            txt_telefono_destinatario: '',
            slPais: '',
            txt_piezas: '',
            txt_peso: '',
            txt_valor_declarado: '',
            txt_descripcion: '',
            txt_instrucciones: '',
            txtEmpresa: '',
            txtDireccion: '',
            txtTelefono: '',
            txtEntrega: '',
            txtListo: '',
            txtEspera: '',
            slTipo: '',
            slTipoUso: '',
            txtObservaciones: '',
        }
    }
    state = {
        seccion: '',
        persona_llama: '',
        txt_nombre_remitente: '',
        txt_email_remitente: '',
        txt_empresa_remitente: '',
        txt_direccion_remitente: '',
        txt_zipcode_remitente: '',
        txt_ciudad_remitente: '',
        txt_pais_remitente: '',
        txt_telefono_remitente: '',
        txt_nombre_destinatario: '',
        txt_email_destinatario: '',
        txt_empresa_destinatario: '',
        txt_direccion_destinatario: '',
        txt_estado_destinatario: '',
        txt_zipcode_destinatario: '',
        txt_telefono_destinatario: '',
        slPais: '',
        txt_piezas: '',
        txt_peso: '',
        txt_valor_declarado: '',
        txt_descripcion: '',
        txt_instrucciones: '',
        txtEmpresa: '',
        txtDireccion: '',
        txtTelefono: '',
        txtEntrega: '',
        txtListo: '',
        txtEspera: '',
        slTipo: '',
        slTipoUso: '',
        txtObservaciones: '',
        txt_Pais: '',

    }

    change_remitente = e => {
        let id_remitente = e.target.value;
        if ( id_remitente != null && id_remitente != 0)
        {

            axios.get(`${apiUrl.apiUrl}remitente_courier/`+id_remitente, {
                headers: {
                    userid: 'transexpress',
                    pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                }
            }).then(res => {
                this.setState({
                    txt_nombre_remitente : res.data[0].nombre ,
                    txt_email_remitente : res.data[0].correo_electronico ,
                    txt_empresa_remitente : res.data[0].empresa ,
                    txt_direccion_remitente : res.data[0].direccion ,
                    txt_zipcode_remitente : res.data[0].zip_code ,
                    txt_ciudad_remitente : res.data[0].ciudad ,
                    txt_pais_remitente : res.data[0].pais ,
                    txt_telefono_remitente : res.data[0].telefono ,
                })

            });

        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    change_destinatario = e => {

        let id_destinatario = e.target.value;
        if ( id_destinatario != null && id_destinatario != 0)
        {

            axios.get(`${apiUrl.apiUrl}destinatario_courier/`+id_destinatario, {
                headers: {
                    userid: 'transexpress',
                    pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                }
            }).then(res => {
                this.setState({
                    txt_nombre_destinatario : res.data[0].nombre ,
                    txt_email_destinatario : res.data[0].correo_electronico ,
                    txt_empresa_destinatario : res.data[0].empresa ,
                    txt_direccion_destinatario : res.data[0].direccion ,
                    txt_estado_destinatario : res.data[0].ciudad ,
                    txt_zipcode_destinatario : res.data[0].zip_code ,
                    txt_telefono_destinatario : res.data[0].telefono ,
                    slPais : res.data[0].id_pais ,
                })

            });

        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    change2 = e => {
        this.setState({
            [e.target.name]: e.target.value
        })

        this.setState({txt_Pais: e.target.options[e.target.selectedIndex].text})
        //str = JSON.stringify(e);
       //alert(str);
        
    }

    componentDidMount() {

        this.state.seccion = localStorage.getItem('seccion');
        this.state.persona_llama = localStorage.getItem('nombre');

    }
    submitHandler = e => {

        let txt_nombre_remitente = this.state.txt_nombre_remitente;
        let txt_email_remitente = this.state.txt_email_remitente;
        let txt_empresa_remitente = this.state.txt_empresa_remitente;
        let txt_direccion_remitente = this.state.txt_direccion_remitente;
        let txt_zipcode_remitente = this.state.txt_zipcode_remitente;
        let txt_ciudad_remitente = this.state.txt_ciudad_remitente;
        let txt_pais_remitente = this.state.txt_pais_remitente;
        let txt_telefono_remitente = this.state.txt_telefono_remitente;
        let txt_nombre_destinatario = this.state.txt_nombre_destinatario;
        let txt_email_destinatario = this.state.txt_email_destinatario;
        let txt_empresa_destinatario = this.state.txt_empresa_destinatario;
        let txt_direccion_destinatario = this.state.txt_direccion_destinatario;
        let txt_estado_destinatario = this.state.txt_estado_destinatario;
        let txt_zipcode_destinatario = this.state.txt_zipcode_destinatario;
        let txt_telefono_destinatario = this.state.txt_telefono_destinatario;
        let slPais = this.state.slPais;
        let txt_piezas = this.state.txt_piezas;
        let txt_peso = this.state.txt_peso;
        let txt_valor_declarado = this.state.txt_valor_declarado;
        let txt_descripcion = this.state.txt_descripcion;
        let txt_instrucciones = this.state.txt_instrucciones;
        let txtEmpresa = this.state.txtEmpresa;
        let txtDireccion = this.state.txtDireccion;
        let txtTelefono = this.state.txtTelefono;
        let txtEntrega = this.state.txtEntrega;
        let txtListo = this.state.txtListo;
        let txtEspera = this.state.txtEspera;
        let slTipo = this.state.slTipo;
        let slTipoUso = this.state.slTipoUso;
        let txtObservaciones = this.state.txtObservaciones;

        if (txt_nombre_remitente == null || txt_nombre_remitente.toString().trim().length == 0){
            cogoToast.warn("Nombre del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_email_remitente == null || txt_email_remitente.toString().trim().length == 0){
            cogoToast.warn("Email del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_empresa_remitente == null || txt_empresa_remitente.toString().trim().length == 0){
            cogoToast.warn("Compañía del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_direccion_remitente == null || txt_direccion_remitente.toString().trim().length == 0){
            cogoToast.warn("Dirección del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_zipcode_remitente == null || txt_zipcode_remitente.toString().trim().length == 0){
            cogoToast.warn("ZipCode del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_ciudad_remitente == null || txt_ciudad_remitente.toString().trim().length == 0){
            cogoToast.warn("Ciudad del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_pais_remitente == null || txt_pais_remitente.toString().trim().length == 0){
            cogoToast.warn("Pais del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_telefono_remitente == null || txt_telefono_remitente.toString().trim().length == 0){
            cogoToast.warn("Teléfono del remitente es necesario para continuar");
            e.preventDefault();
            return;
        }

        if (txt_nombre_destinatario == null || txt_nombre_destinatario.toString().trim().length == 0){
            cogoToast.warn("Nombre del destinatario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_email_destinatario == null || txt_email_destinatario.toString().trim().length == 0){
            cogoToast.warn("Email del destinatario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_empresa_destinatario == null || txt_empresa_destinatario.toString().trim().length == 0){
            cogoToast.warn("Empresa del destinatario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_direccion_destinatario == null || txt_direccion_destinatario.toString().trim().length == 0){
            cogoToast.warn("Dirección del destintario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_estado_destinatario == null || txt_estado_destinatario.toString().trim().length == 0){
            cogoToast.warn("Estado del destintario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_zipcode_destinatario == null || txt_zipcode_destinatario.toString().trim().length == 0){
            cogoToast.warn("Zipcode del destinatario es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txt_telefono_destinatario == null || txt_telefono_destinatario.toString().trim().length == 0){
            cogoToast.warn("Teléfono del destinatario  es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (slPais == 0 ||slPais == null ){
            cogoToast.warn("Seleccione un destino");
            e.preventDefault();
            return;
        }


        if (txt_piezas == null || txt_piezas.toString().trim().length == 0){
            cogoToast.warn("Dato de piezas es necesario para continuar");
            e.preventDefault();
            return;
        }

        if (txt_peso == null || txt_peso.toString().trim().length == 0){
            cogoToast.warn("Datos del peso es necesario para continuar");
            e.preventDefault();
            return;
        }

        if (txt_valor_declarado == null || txt_valor_declarado.toString().trim().length == 0){
            cogoToast.warn("Valor declarado es necesario para continuar");
            e.preventDefault();
            return;
        }

        if (txt_descripcion == null || txt_descripcion.toString().trim().length == 0){
            cogoToast.warn("Descripción es necesario para continuar");
            e.preventDefault();
            return;
        }

        if (txt_instrucciones == null || txt_instrucciones.toString().trim().length == 0){
            cogoToast.warn("Instrucciones especiales es necesario para continuar");
            e.preventDefault();
            return;
        }


        if (txtEmpresa == null || txtEmpresa.toString().trim().length == 0){
            cogoToast.warn("Empresa es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txtDireccion == null || txtDireccion.toString().trim().length == 0){
            cogoToast.warn("Dirección es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txtTelefono == null || txtTelefono.toString().trim().length == 0){
            cogoToast.warn("Teléfono es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txtEntrega == null || txtEntrega.toString().trim().length == 0){
            cogoToast.warn("Persona entrega es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txtListo == null || txtListo.toString().trim().length == 0){
            cogoToast.warn("Hora listo es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (txtEspera == null || txtEspera.toString().trim().length == 0){
            cogoToast.warn("Hora Espera es necesario para continuar");
            e.preventDefault();
            return;
        }
        if (slTipo == 0 ||slTipo == null ){
            cogoToast.warn("Seleccione el tipo del envío");
            e.preventDefault();
            return;
        }
        if (slTipoUso == 0 ||slTipoUso == null ){
            cogoToast.warn("Seleccione el tipo de uso del envío");
            e.preventDefault();
            return;
        }
        if (txtObservaciones == null || txtObservaciones.toString().trim().length == 0){
            cogoToast.warn("Observaciones es necesario para continuar");
            e.preventDefault();
            return;
        }

    }

    CargarRemitente = e => {
        e.preventDefault();

        this.setState( {
            txtEmpresa: this.state.txt_empresa_remitente,
            txtDireccion: this.state.txt_direccion_remitente,
            txtEntrega: this.state.txt_nombre_remitente,
            txtTelefono: this.state.txt_telefono_remitente,
        });

    }
    GrabarRemitente = e => {
        e.preventDefault();
        let txt_nombre_remitente = this.state.txt_nombre_remitente;
        let txt_email_remitente = this.state.txt_email_remitente;
        let txt_direccion_remitente = this.state.txt_direccion_remitente;
        let txt_zipcode_remitente = this.state.txt_zipcode_remitente;
        let txt_ciudad_remitente = this.state.txt_ciudad_remitente;
        let txt_pais_remitente = this.state.txt_pais_remitente;
        let txt_telefono_remitente = this.state.txt_telefono_remitente;
        let txt_empresa_remitente = this.state.txt_empresa_remitente;

        if (txt_nombre_remitente == null || txt_nombre_remitente.toString().trim().length == 0){
            cogoToast.warn("Nombre del remitente es necesario para continuar");
            return;
        }

        if (txt_empresa_remitente == null || txt_empresa_remitente.toString().trim().length == 0){
            cogoToast.warn("Empresa del remitente es necesario para continuar");
            return;
        }
        if (txt_email_remitente == null || txt_email_remitente.toString().trim().length == 0){
            cogoToast.warn("Email del remitente es necesario para continuar");
            return;
        }
        if (txt_direccion_remitente == null || txt_direccion_remitente.toString().trim().length == 0){
            cogoToast.warn("Dirección del remitente es necesario para continuar");
            return;
        }
        if (txt_zipcode_remitente == null || txt_zipcode_remitente.toString().trim().length == 0){
            cogoToast.warn("ZipCode del remitente es necesario para continuar");
            return;
        }
        if (txt_ciudad_remitente == null || txt_ciudad_remitente.toString().trim().length == 0){
            cogoToast.warn("Ciudad del remitente es necesario para continuar");
            return;
        }

        if (txt_pais_remitente == null || txt_pais_remitente.toString().trim().length == 0){
            cogoToast.warn("País del remitente es necesario para continuar");
            return;
        }
        if (txt_telefono_remitente == null || txt_telefono_remitente.toString().trim().length == 0){
            cogoToast.warn("Teléfono del remitente es necesario para continuar");
            return;
        }


        var data ={
            nombre: txt_nombre_remitente,
            empresa: txt_empresa_remitente,
            telefono:txt_telefono_remitente,
            correo_electronico:txt_email_remitente,
            direccion:txt_direccion_remitente,
            ciudad: txt_ciudad_remitente,
            pais: txt_pais_remitente,
            seccion: localStorage.getItem('seccion'),
            zip_code:txt_zipcode_remitente,
            estado: '',
        };

        const url_service = `${apiUrl.apiUrl}grabar_remitente`;

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                console.log(response.data);
                if ( response.data.codigo_respuesta == "1" )
                {
                    swal("Courier Internacional", "Remitente grabado correctamente", "success");
                }
                else
                    swal("Courier Internacional", "No se pudo grabar el remitente. \n Error: " + response.data.msg_error, "error");

            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });
    }
    GrabarDestinatario = e => {
        e.preventDefault();
        e.preventDefault();
        let txt_nombre_destinatario = this.state.txt_nombre_destinatario;
        let txt_email_destinatario = this.state.txt_email_destinatario;
        let txt_empresa_destinatario = this.state.txt_empresa_destinatario;
        let txt_direccion_destinatario = this.state.txt_direccion_destinatario;
        let txt_estado_destinatario = this.state.txt_estado_destinatario;
        let txt_zipcode_destinatario = this.state.txt_zipcode_destinatario;
        let txt_telefono_destinatario = this.state.txt_telefono_destinatario;
        let slPais = this.state.slPais;




        if (txt_nombre_destinatario == null || txt_nombre_destinatario.toString().trim().length == 0){
            cogoToast.warn("Nombre del destinatario es necesario para continuar");
            return;
        }

        if (txt_empresa_destinatario == null || txt_empresa_destinatario.toString().trim().length == 0){
            cogoToast.warn("Empresa del destinatario es necesario para continuar");
            return;
        }
        if (txt_email_destinatario == null || txt_email_destinatario.toString().trim().length == 0){
            cogoToast.warn("Email del destintario es necesario para continuar");
            return;
        }
        if (txt_direccion_destinatario == null || txt_direccion_destinatario.toString().trim().length == 0){
            cogoToast.warn("Dirección del destinatario es necesario para continuar");
            return;
        }
        if (txt_zipcode_destinatario == null || txt_zipcode_destinatario.toString().trim().length == 0){
            cogoToast.warn("ZipCode del destinatario es necesario para continuar");
            return;
        }
        if (txt_estado_destinatario == null || txt_estado_destinatario.toString().trim().length == 0){
            cogoToast.warn("Estado del destintario es necesario para continuar");
            return;
        }

        if (slPais == 0 ||slPais == null ){
            cogoToast.warn("Seleccione un destino");
            e.preventDefault();
            return;
        }

        if (txt_telefono_destinatario == null || txt_telefono_destinatario.toString().trim().length == 0){
            cogoToast.warn("Teléfono del destinatario es necesario para continuar");
            return;
        }


        var data ={
            nombre: txt_nombre_destinatario,
            empresa:txt_empresa_destinatario,
            telefono: txt_telefono_destinatario,
            correo_electronico:txt_email_destinatario,
            direccion:txt_direccion_destinatario,
            ciudad:txt_estado_destinatario,
            id_pais: slPais,
            seccion: localStorage.getItem('seccion'),
            zip_code: txt_zipcode_destinatario,
            estado: txt_estado_destinatario,
        };
        console.log(data);
        const url_service = `${apiUrl.apiUrl}grabar_destinatario`;

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                if ( response.data.codigo_respuesta == "1" )
                {
                    swal("Courier Internacional", "Destinatario grabado correctamente", "success");
                }
                else
                    swal("Courier Internacional", "No se pudo grabar el Destinatario. \n Error: " + response.data.msg_error, "error");

            },
             (error) => {
                 swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });
    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Creación de guías courier Internacional
                            <small>
                                Ingresa la informacion para poder realizar una guía internacional
                            </small>
                       </h1>
                    </div>
                    <form onSubmit={this.submitHandler} target="_blank" action="scripts/imprimirGuia.php" method="POST">
                        <Tabs defaultActiveKey="remitente" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="remitente" title="Datos del Remitente">
                                <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                                    <div className="panel-content">
                                        <div className="form-group">
                                            <h4>DATOS DEL REMITENTE</h4>
                                        </div>
                                        <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.slRemitentes} name="slRemitentes" onChange={e => this.change_remitente(e)}>
                                            <option value={0} selected>Seleccione un Remitente</option>
                                            <CatRemitentes></CatRemitentes>
                                        </Form.Control>
                                        <div className="form-group">
                                            <br/>
                                            <Button type="button" variant="success" block onClick = {e => this.GrabarRemitente(e)}  >Grabar nuevo Remitente</Button>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_nombre_remitente">Nombre:</label>
                                            <Form.Control value={this.state.txt_nombre_remitente} type="text"  placeholder="Nombre remitente" name="txt_nombre_remitente" maxLength={100} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_email_remitente">Email:</label>
                                            <Form.Control value={this.state.txt_email_remitente} type="text"  placeholder="Email remitente" name="txt_email_remitente" maxLength={100} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_empresa_remitente">Compañia:</label>
                                            <Form.Control value={this.state.txt_empresa_remitente} type="text"  placeholder="Compañia remitente" name="txt_empresa_remitente" maxLength={100} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_direccion_remitente">Dirección:</label>
                                            <Form.Control value={this.state.txt_direccion_remitente} type="text"  placeholder="Dirección remitente" name="txt_direccion_remitente" maxLength={250} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_zipcode_remitente">ZIP code:</label>
                                            <Form.Control value={this.state.txt_zipcode_remitente} type="text"  placeholder="ZIP Code remitente" name="txt_zipcode_remitente" maxLength={50} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_ciudad_remitente">Ciudad:</label>
                                            <Form.Control value={this.state.txt_ciudad_remitente} type="text"  placeholder="Ciudad remitente" name="txt_ciudad_remitente"  maxLength={50} onChange={e => this.change(e)}/>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_pais_remitente">País:</label>
                                            <Form.Control value={this.state.txt_pais_remitente} type="text"  placeholder="País Remitente" name="txt_pais_remitente"  maxLength={50} onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_telefono_remitente">Teléfono:</label>
                                            <Form.Control value={this.state.txt_telefono_remitente} type="text"  placeholder="Teléfono remitente" name="txt_telefono_remitente" maxLength={30} onChange={e => this.change(e)}/>
                                        </div>
                                      </div>
                                </div>
                            </Tab>
                            <Tab eventKey="destinatario" title="Datos del Destinatario">
                                <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                                    <div className="panel-content">
                                        <div className="form-group">
                                            <h4>DATOS DEL DESTINATARIO</h4>
                                        </div>
                                        <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.slDestinatarios} name="slDestinatarios" onChange={e => this.change_destinatario(e)}>
                                            <option value={0} selected>Seleccione un Destinatario</option>
                                            <CatDestinatarios></CatDestinatarios>
                                        </Form.Control>
                                        <div className="form-group">
                                            <br/>
                                            <Button type="button" variant="success" block onClick = {e => this.GrabarDestinatario(e)}  >Grabar nuevo Destinatario</Button>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_nombre_destinatario">Nombre:</label>
                                            <Form.Control value={this.state.txt_nombre_destinatario} type="text" maxLength={100}  placeholder="Nombre Destinatario" name="txt_nombre_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_email_destinatario">Email:</label>
                                            <Form.Control value={this.state.txt_email_destinatario} type="text"  maxLength={100} placeholder="Email Destinatario" name="txt_email_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_empresa_destinatario">Compañía:</label>
                                            <Form.Control value={this.state.txt_empresa_destinatario} type="text"  maxLength={100} placeholder="Empresa destinatario" name="txt_empresa_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_direccion_destinatario">Dirección:</label>
                                            <Form.Control value={this.state.txt_direccion_destinatario} type="text"  placeholder="Email destinatario"  maxLength={250} name="txt_direccion_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_estado_destinatario">Ciudad:</label>
                                            <Form.Control value={this.state.txt_estado_destinatario} type="text"  placeholder="Ciudad destinatario" maxLength={50} name="txt_estado_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_zipcode_destinatario">ZipCode:</label>
                                            <Form.Control value={this.state.txt_zipcode_destinatario} type="text"  placeholder="Teléfono destinatario" maxLength={50} name="txt_zipcode_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_telefono_destinatario">Teléfono:</label>
                                            <Form.Control value={this.state.txt_telefono_destinatario} type="text"  placeholder="Teléfono destinatario"  maxLength={30} name="txt_telefono_destinatario" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group" hidden="hidden">
                                            <label className="form-label" htmlFor="txt_pais" >PAIS:</label>
                                            <Form.Control value={this.state.txt_Pais} type="text"  placeholder="Pais"  maxLength={30} name="txt_pais"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">País:</label>
                                            <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.slPais} name="slPais" onChange={e => this.change2(e)}>
                                                <option value={0} selected>Seleccione un País</option>
                                                <CatPais></CatPais>
                                            </Form.Control>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="otros" title="Otros Datos">
                                <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                                    <div className="panel-content">
                                        <div className="form-group">
                                            <h4>OTROS DATOS</h4>
                                            <Form.Control value={this.state.seccion} type="hidden"  placeholder="" name="seccion" onChange={e => this.change(e)}/>
                                            <Form.Control value={this.state.persona_llama} type="hidden"  placeholder="" name="persona_llama" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_piezas">Piezas:</label>
                                            <Form.Control value={this.state.txt_piezas} type="number"  placeholder="Piezas"  name="txt_piezas" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_peso">Peso Lbs.(Por favor ingrese valores n&uacute;mericos sin coma. Ejemplo para 1,250.25 ingrese 1250.25):</label>
                                            <Form.Control value={this.state.txt_peso} type="number"  placeholder="Peso" name="txt_peso" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_valor_declarado">Valor declarado USD$( Por favor ingrese valores n&uacute;mericos sin coma. Ejemplo para 1,250.25 ingrese 1250.2):</label>
                                            <Form.Control value={this.state.txt_valor_declarado} type="number"  placeholder="Valor declarado USD$" name="txt_valor_declarado" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_descripcion">Descripción:</label>
                                            <Form.Control value={this.state.txt_descripcion} type="text" maxLength={50}   placeholder="Descripción" name="txt_descripcion" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txt_instrucciones">Instrucciones Especiales:</label>
                                            <Form.Control value={this.state.txt_instrucciones} type="text"  maxLength={500}  placeholder="Instrucciones Especiales" name="txt_instrucciones" onChange={e => this.change(e)}/>
                                        </div>


                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="solicitud" title="Datos de solicitud de courier">
                                <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                                    <div className="panel-content">
                                        <div className="form-group">
                                            <h4>DATOS DE SOLICITUD DE COURIER</h4>
                                        </div>
                                        <div className="form-group">
                                            <Button type="button" variant="success" block onClick = {e => this.CargarRemitente(e)}  >Utilizar datos del remitente</Button>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtEmpresa">Empresa:</label>
                                            <Form.Control value={this.state.txtEmpresa} type="text"  maxLength={100} placeholder="Empresa" name="txtEmpresa" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtDireccion">Dirección:</label>
                                            <Form.Control value={this.state.txtDireccion} type="text"  maxLength={500} placeholder="Dirección" name="txtDireccion" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtTelefono">Teléfono:</label>
                                            <Form.Control value={this.state.txtTelefono} type="text" maxLength={35}  placeholder="Teléfono" name="txtTelefono" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtEntrega">Persona Entrega Envío:</label>
                                            <Form.Control value={this.state.txtEntrega} type="text" maxLength={75}  placeholder="Persona entrega envío" name="txtEntrega" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtListo">Hora Envio Listo:</label>
                                            <Form.Control value={this.state.txtListo} type="text" maxLength={5}  placeholder="Hora envío listo" name="txtListo" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtEspera">Hora máxima espera:</label>
                                            <Form.Control value={this.state.txtEspera} type="text" maxLength={5} placeholder="Hora máxima espera" name="txtEspera" onChange={e => this.change(e)}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Tipo Envío:</label>
                                            <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.slTipo} name="slTipo" onChange={e => this.change(e)}>
                                                <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                                <option key={1} value={1}>DOCUMENTOS</option>
                                                <option key={2} value={2}>MUESTRAS</option>
                                            </Form.Control>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Uso:</label>
                                            <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.slTipoUso} name="slTipoUso" onChange={e => this.change(e)}>
                                                <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                                <option key={"E"} value={"E"}>EMPRESARIAL</option>
                                                <option key={"I"} value={"I"}>INDIVIDUAL</option>
                                            </Form.Control>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="txtObservaciones">Observaciones:</label>
                                            <Form.Control value={this.state.txtObservaciones} type="text" maxLength={500}  placeholder="Observaciones" name="txtObservaciones" onChange={e => this.change(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                        <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                            <div className="panel-content">
                                <Button type="submit" variant="primary" block >Crear Guía</Button>
                            </div>

                        </div>
                    </form>

                </main>

            </Welcome>
        )
    }
}
export default GuiaInternacional;


