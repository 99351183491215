import React from "react";

class Header extends React.Component {
    render() {
        return (
            <header className="page-header" role="banner">
                {/* we need this logo when user switches to nav-function-top */}
                <div className="page-logo">
                    <a
                        href="#"
                        className="page-logo-link press-scale-down d-flex align-items-center position-relative"
                        data-toggle="modal"
                        data-target="#modal-shortcut"
                    >
                        <img
                            src="img/logo.png"
                            alt="TransExpress Guatemala"
                            aria-roledescription="logo"
                        />
                        <span className="page-logo-text mr-1">
                            Trans-Express Guatemala
                        </span>
                        <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2" />
                        <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300" />
                    </a>
                </div>
                {/* DOC: nav menu layout change shortcut */}
                <div className="hidden-md-down dropdown-icon-menu position-relative">
                    <a
                        href="#"
                        className="header-btn btn js-waves-off"
                        data-action="toggle"
                        data-class="nav-function-hidden"
                        title="Hide Navigation"
                        onClick={this.props.handleHide}
                    >
                        <i className="ni ni-menu" />
                    </a>
                </div>
                {/* DOC: mobile button appears during mobile width */}
                <div className="hidden-lg-up">
                    <a
                        href="#"
                        className="header-btn btn press-scale-down"
                        data-action="toggle"
                        data-class="mobile-nav-on"
                        onClick={this.props.handleShowMobile}
                    >
                        <i className="ni ni-menu" />
                    </a>
                </div>
                <div className="search"></div>
                <div className="ml-auto d-flex">
                    {/* activate app search icon (mobile) */}
                    <div className="hidden-sm-up">
                        <a
                            href="#"
                            className="header-icon"
                            data-action="toggle"
                            data-class="mobile-search-on"
                            data-focus="search-field"
                            title="Search"
                        >
                            <i className="fal fa-search" />
                        </a>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
