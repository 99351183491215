import React, { Component } from "react";
import apiUrl from "../enviroments/enviroment";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Link, NavLink, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txtSeccion: "",
            txtNIT: "",
            txtEmail: "",
            txtUsuario: ""
        };
    }

    state = {
        txtSeccion: "",
        txtNIT: "",
        txtEmail: "",
        txtUsuario: ""
    };

    componentDidMount() {
        localStorage.clear();
    }

    RestablecerContrasena = async () => {

        var pSeccion = this.state.txtSeccion;
        var pNIT = this.state.txtNIT;
        var pEmail = this.state.txtEmail;
        var pUsuario = this.state.txtUsuario;

        const url_service =
            `${apiUrl.apiUrl}recuperar_contrasena`;

        var respuesta = false;

        if (pSeccion == null || pSeccion.toString().trim().length == 0) {
            cogoToast.warn("Ingrese su numero de sección para poder continuar");
            return;
        }
        if (pUsuario == null || pUsuario.toString().trim().length == 0) {
            cogoToast.warn("Ingrese su Usuario WEB registrado para poder continuar");
            return;
        }
        if (pNIT == null || pNIT.toString().trim().length == 0) {
            cogoToast.warn("Ingrese su N.I.T. para poder continuar");
            return;
        }
        if (pEmail == null || pEmail.toString().trim().length == 0) {
            cogoToast.warn("Ingrese su Email registrado para poder continuar");
            return;
        }

        var data ={
            username: pUsuario,
            seccion: pSeccion,
            nit: pNIT,
            email: pEmail
        };

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                let respuesta = response.data[0].resultado;
                let mensaje = response.data[0].mensaje;

                if (respuesta == "1"){
                    swal("Restablecer contraseña", mensaje, "success");

                    this.setState({
                        TxtUsuario: '',
                        TxtEmail: '',
                        TxtContrasenaVieja: '',
                        TxtContrasenaNueva: '',
                        TxtContrasenaConfirma: '',
                    })
                }else{

                    swal({
                        title: "Restablecer contraseña",
                        text: mensaje,
                        icon: "error",
                        button: "Ir a Whatsapp por ayuda",
                    })
                        .then((value) => {
                            let number = '+502-54939039';
                            let message =  "Cuenta de correo y sección no registradas en el Sistema Transexpress.." ;
                            const URL = 'https://wa.me';
                            number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                            let url = `${URL}/${number}`;
                            url += `?text=${encodeURI(message)}`;
                            window.open(url);
                        });

                }
            }, (error) => {
                swal(
                    "Login",
                    "Se ha producido un error: ",
                    "error"
                );
            });
    };

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type,
        });
    };

    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.RecuperarUsuario();
        }
    };

    render() {
        return (
            <div>
                <div className="page-wrapper auth">
                    <div className="page-inner bg-brand-gradient">
                        <div className="page-content-wrapper bg-transparent m-0">
                            <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                                <div className="d-flex align-items-center container p-0">
                                    <img
                                        src={"../src/img/logos/ICONO-TE.png"}
                                        alt="SmartAdmin WebApp"
                                        width="200"
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                        </div>
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                            <div className="card p-4 rounded-plus bg-faded">
                                                <form
                                                    id="js-login"
                                                    noValidate
                                                    action="intel_analytics_dashboard.html"
                                                >
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="txtSeccion"
                                                        >
                                                            Secci&oacute;n
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .txtSeccion
                                                            }
                                                            className="form-control form-control-lg"
                                                            type="text"
                                                            placeholder="Ingrese su sección"
                                                            name="txtSeccion"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="txtUsuario"
                                                        >
                                                            Usuario Web
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .txtUsuario
                                                            }
                                                            className="form-control form-control-lg"
                                                            type="text"
                                                            placeholder="Ingrese su usuario WEB"
                                                            name="txtUsuario"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="txtEmail"
                                                        >
                                                            Email registrado
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .txtEmail
                                                            }
                                                            className="form-control form-control-lg"
                                                            type="text"
                                                            placeholder="Ingrese su email registrado"
                                                            name="txtEmail"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            className="form-label"
                                                            htmlFor="txtNIT"
                                                        >
                                                            N.I.T.
                                                        </label>
                                                        <Form.Control
                                                            value={
                                                                this.state
                                                                    .txtNIT
                                                            }
                                                            className="form-control form-control-lg"
                                                            type="text"
                                                            placeholder="Ingrese su N.I.T. registrado"
                                                            name="txtNIT"
                                                            onChange={(e) =>
                                                                this.change(e)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="row no-gutters">
                                                        <div className="col-lg-12 pr-lg-1 my-2">
                                                            <Button
                                                                className={
                                                                    "btn btn-info btn-block btn-lg"
                                                                }
                                                                onClick={
                                                                    this
                                                                        .RestablecerContrasena
                                                                }
                                                            >
                                                                Restablecer su contraseña
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                        <div className="col-lg-12 pr-lg-1 my-2 text-center">
                                                            <Link to={`/Login`}>
                                                                <a
                                                                    href="#"
                                                                    className="btn-link text-dark ml-auto ml-sm-0"
                                                                >
                                                                    Volver a página principal
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-md-6 col-lg-5 col-xl-4 ml-auto">
                                        </div>
                                    </div>
                                    <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                        2021 ©{" "}
                                        <a
                                            href="http://www.transexpress.com.gt/"
                                            className="text-white opacity-40 fw-500"
                                            title="Transexpress"
                                            target="_blank" rel="noreferrer"
                                        >
                                            Transexpress
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    change = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
}

export default withRouter(Login);
