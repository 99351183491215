
import keys from "./claves";

export default class generarDescargo{
    constructor(code, firstname, lastname, address1, postalcode, locality, phonenumber, email, totalamount, expirationyear, number, securitycode, expirationmonth, tipo, costumerid, address, id) {
        this.code = code;
        this.firstname = firstname;
        this.lastname = lastname;
        this.address1 = address1;
        this.postalcode = postalcode;
        this.locality = locality;
        this.phonenumber = phonenumber;
        this.email = email;
        this.totalamount = totalamount;
        this.expirationyear = expirationyear;
        this.number = number;
        this.securitycode = securitycode;
        this.expirationmonth = expirationmonth;
        this.tipo = tipo;
        this.costumerid = costumerid;
        this.address = address;
        this.id = id;
    }

    crearDescargo(){
        return new Promise((resolve, reject) => {
            let jsonCyber
            var axios = require('axios');
            const FormData = require('form-data');
            let data = new FormData();
            data.append('jsonCyber', this.GenerarJsonCyber());
            console.log(this.GenerarJsonCyber());

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://users.transexpress.com.gt/apiimp.php',
                headers: { 
                    'id': keys.id_web, 
                    'key': keys.key_web, 
                    'secret': keys.secret_web,
                //...data.getHeaders()
                },
                data : data
            };

            axios(config)
                .then(function (response) {
                    let resp  = JSON.stringify(response);
                    let js = JSON.parse(resp);
                    resolve(js);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        })
    }

    crearDescargoIMP(){
        return new Promise((resolve, reject) => {            
            var axios = require('axios');
            const FormData = require('form-data');
            let data = new FormData();
            data.append('jsonCyber', this.GenerarJsonCyber());

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://users.transexpress.com.gt/apiimp.php',
                headers: { 
                    'id': keys.id_imp, 
                    'key': keys.key_imp, 
                    'secret': keys.secret_imp,
                //...data.getHeaders()
                },
                data : data
            };

            axios(config)
                .then(function (response) {
                    let resp  = JSON.stringify(response);
                    let js = JSON.parse(resp);
                    resolve(js);
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                });
        })
    }

    GenerarJsonCyber(){
        var cyber = '';
        if(this.tipo == 1){
            let letter = this.number.charAt(0);
            let typecard = ''

            if(letter == '4'){
                typecard = '001'
            }else if(letter == '5'){
                typecard = '002'
            }else {
                swal("Ayuda", "Tarjeta no valida", "error");
                return;
            }            
            cyber = '{' +
                        '"clientReferenceInformation": {' +
                        '"code": "' + this.code + '"' +
                    '},' +
                    '"processingInformation": {' +
                        '"commerceIndicator": "internet",' +
                        '"capture": true' +
                    '},' +
                    '"orderInformation": {' +
                        '"billTo": {' +
                            '"firstName": "' + this.firstname + '",' +
                            '"lastName": "' + this.lastname + '",' +
                            '"address1": "' + this.address1 + '",' +
                            '"postalCode": "' + this.postalcode + '",' +
                            '"locality": "' + this.locality + '",' +
                            '"country": "GT",' +
                            '"phoneNumber": "' + this.phonenumber + '",' +
                            '"email":"' + this.email +'"' +
                        '},' +
                        '"amountDetails": {' +
                            '"totalAmount": "' + this.totalamount + '",' +
                            '"currency": "GTQ"' +
                        '}' +
                    '},' +
                    '"paymentInformation": {' +
                        '"card": {' +
                            '"expirationYear": "' + this.expirationyear + '",' +
                            '"number": "' + this.number + '",' +
                            '"securityCode": "' + this.securitycode + '",' +
                            '"expirationMonth": "' + this.expirationmonth + '",' +
                            '"type": "' + typecard + '"' +
                        '}' +
                    '}' +
                '}';
        }
        

        
        if(this.tipo == 2){
            cyber = '{' +
                '"clientReferenceInformation": {' +
                    '"code": "TC50171_3"' +
                '},' +
                '"paymentInformation": {' +
                    '"customer": {' +
                        '"id": "' + this.costumerid + '"' +
                    '},' +
                    '"paymentInstrument": {' +
                        '"id": "' + this.id + '"' +
                    '},' +
                    '"shippingAddress": {' +
                        '"id": "' + this.address + '"' +
                    '}' +
                '},' +
                '"orderInformation": {' +
                    '"amountDetails": {' +
                        '"totalAmount": "' + this.totalamount + '",' +
                        '"currency": "GTQ"' +
                    '}' +
                '}' +
            '}'
        }
        return cyber;
    }

}

