import keys from "./claves";

export default class generarClienteDireccion{
    constructor(merchantCustomerID, email, code, firstName, lastName, phoneNumber, carNumber, expirationMonth, expirationYear){
        this.merchantCustomerID = merchantCustomerID;
        this.email = email;
        this.code = code;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.value = firstName;
        this.carNumber = carNumber;
        this.expirationMonth = expirationMonth;
        this.expirationYear = expirationYear;        
    }

    async crearClienteWeb(){
        
        /*
        'id': keys.id_web, 
            'key': keys.key_web, 
            'secret': keys.secret_web,
        */
        //console.log('---------------');
        let resultadoWeb = await this.ws(keys.id_web, keys.key_web, keys.secret_web, '1');
        let resultadoImp = await this.ws(keys.id_imp, keys.key_imp, keys.secret_imp, '3');
        let resultadoApp = await this.ws(keys.id_app, keys.key_app, keys.secret_app, '2');

        if(resultadoWeb && resultadoImp && resultadoApp){
            return 'Token grabado.';
        }else{
            return 'Token no registrado'
        }
        //Promise.all(resultadoWeb, resultadoImp);
        //return '0';

        /*if(resultadoWeb){
            console.log('Error');
        }else{
            let resultadoImp = await this.ws(keys.id_imp, keys.key_imp, keys.secret_imp, '3');
            console.log(resultadoImp);
            if(resultadoImp == '0'){
                console.log('Error');
            }else{
                console.log('Token registrado');
            }
        }*/
    }

    crearClienteImp(){

    }

    crearClienteApp(){

    }

    ws(id, key, secret, afiliacion){
        return new Promise((resolve) =>{
            const superagent = require('superagent');
            var request = superagent('post', 'https://users.transexpress.com.gt/costumer.php');

            /*data.append('id_afiliacion', afiliacion);
            data.append('id_cuenta', localStorage.getItem('id_cuenta'));
            data.append('cliente', jsonCostumer);
            data.append('direccion', jsonDireccion);
            data.append('instrument', jsonInstrument);
            data.append('paymentInstrument', jsonPaymentInstrument);*/

            var headerParams = {};
            headerParams['id'] = id;
            headerParams['key'] = key;
            headerParams['secret'] = secret;
            request.set(this.normalizeParams(headerParams));
            request.timeout(60000);
            request.field('id_afiliacion', afiliacion);
            request.field('id_cuenta', localStorage.getItem('id_cuenta'));
            request.field('cliente', this.jsonCostumer());
            request.field('direccion', this.jsonDireccion());
            request.field('instrument', this.jsonInstrument());
            request.field('payment_instrument', this.jsonPaymentInstrument());
            return;
            /*console.log(afiliacion);
            console.log(localStorage.getItem('id_cuenta'));
            console.log(this.jsonCostumer());
            console.log(this.jsonDireccion());
            console.log(this.jsonInstrument());
            console.log(this.jsonPaymentInstrument());*/
            //return;
            /*request.type(contentType);
            request.send(bodyParam);
            request.accept(acceptType);*/

            var dataresponse;
            request.end(function(error, response) {
                var data = response.body;
                if (data == null || (typeof data === 'object' && typeof data.length === 'undefined' && !Object.keys(data).length)) {
                    // SuperAgent does not always produce a body; use the unparsed response as a fallback
                    data = response.text;
                }

                //dataresponse = JSON.stringify(data);
                //res.send(data);
                //console.log(data)
                var _status = -1;
                if (response['status'] >= 200 && response['status'] <= 299) {
                    console.log(response['status']);
                    _status = 0;
                    resolve(true);
                }else if(response['status'] == 401){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Dirección)");
                }else if(response['status'] == 402){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Instrumento)");
                }else if(response['status'] == 403){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Instrumento pago)");
                }else if(response['status'] == 400){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Cliente)");
                }
            });
            /*var axios = require('axios');
            let data = this.data(afiliacion);
            //console.log(data);
            //return;
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://users.transexpress.com.gt/costumer.php',
                headers: { 
                    'id': id, 
                    'key': key, 
                    'secret': secret,
                },
                data : data
            };
            axios(config)
                .then(function (response) {
                    let resp  = JSON.stringify(response);
                    let js = JSON.parse(resp);
                    console.log(js);
                    resolve(true);
                })
                .catch(function (error) {
                    reject('No se grabo cliente ni dirección');
                });*/
        })
    }

    /*data(afiliacion){
        const FormData = require('form-data');
        let data = new FormData();

        let jsonCostumer = this.jsonCostumer();
        let jsonDireccion = this.jsonDireccion();
        let jsonInstrument = this.jsonInstrument();
        let jsonPaymentInstrument = this.jsonPaymentInstrument();
        
        data.append('id_afiliacion', afiliacion);
        data.append('id_cuenta', localStorage.getItem('id_cuenta'));
        data.append('cliente', jsonCostumer);
        data.append('direccion', jsonDireccion);
        data.append('instrument', jsonInstrument);
        data.append('paymentInstrument', jsonPaymentInstrument);

        console.log(jsonCostumer);
        console.log(jsonDireccion);
        console.log(jsonInstrument);
        console.log(jsonPaymentInstrument);
        return data;
    }*/

    jsonCostumer(){
        let jsonCostumer = 
                '{' +
                    '"buyerInformation":{' +
                        '"merchantCustomerID":"' + this.merchantCustomerID + '",' +
                        '"email":"' + this.email + '"' +
                    '},' +
                    '"clientReferenceInformation":{' +
                        '"code":"' + this.code + '"' +
                    '},' +
                    '"merchantDefinedInformation":[' +
                        '{' +
                            '"name":"data1",' +
                            '"value":"' + this.value + '"' +
                        '}' +
                    ']' +
                '}';
        return jsonCostumer;
    }

    jsonDireccion(){
        let jsonDireccion = 
            '{' +
                '"default":"true",' +
                '"shipTo":{' +
                    '"firstName":"' + this.firstName + '",' +
                    '"lastName":"' + this.lastName + ' ",' +
                    '"address1":"Ciudad de Guatemala",' +
                    '"locality":"Guatemala",' +
                    '"postalCode":"01000",' +
                    '"country":"GT",' +
                    '"email":"' + this.email + '",' +
                    '"phoneNumber":"' + this.phoneNumber + '"' +
                '}' +
            '}';
        return jsonDireccion;
    }

    jsonInstrument(){
        let jsonTarjeta = 
        '{' +
            '"card": {' +
              '"number": "' + this.carNumber + '"' +
            '}' +
          '}';
        return jsonTarjeta;
    }

    jsonPaymentInstrument(){
        let type = this.typeTarjeta();
        if(this.expirationMonth.length = 0){
            this.expirationMonth = '0' + this.expirationMonth;
            console.log(this.expirationMonth)
        }
        let jsonDireccion = 
            '{' +
                '"default":"true",' +
                '"card": {' +
                    '"expirationMonth": "' + this.expirationMonth + '",' +
                    '"expirationYear": "' + this.expirationYear + '",' +
                    '"type": "' + type + '"' +
                '},'+
                '"billTo":{' +
                    '"firstName":"' + this.firstName + '",' +
                    '"lastName":" ' + this.lastName + ' ",' +
                    '"address1":"Ciudad de Guatemala",' +
                    '"locality":"Guatemala",' +
                    '"postalCode":"01000",' +
                    '"country":"GT",' +
                    '"email":"' + this.email + '",' +
                    '"phoneNumber":"' + this.phoneNumber + '"' +
                '},' +
                '"instrumentIdentifier": {' +
                    '"id": "----------"' +
                  '}' +
            '}';
        return jsonDireccion;
    }

    typeTarjeta(){
        let type = this.carNumber.charAt(0);
        let t;
        if(type == '5' || type == '2'){
            t = '002';
        }else if(type == '4'){
            t = '001';
        }else{
            t = '000';
        }

        return t;
    }

    normalizeParams(params) {
        var newParams = {};
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key] != undefined && params[key] != null) {
                var value = params[key];
                if (Array.isArray(value)) {
                    newParams[key] = value;
                } else {
                    newParams[key] = this.paramToString(value);
                }
            }
        }
        return newParams;
    }

    paramToString(param) {
        if (param == undefined || param == null) {
            return '';
        }
        if (param instanceof Date) {
            return param.toJSON();
        }
        return param.toString();
    }
}
