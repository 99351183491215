/*const crypto = require("crypto");

var requestHost = 'apitest.cybersource.com';
var merchantId = 'visanetgt_transexpress';
var merchantKeyId = 'a4d0efac-8e23-4394-8db8-918e37213f49';
var merchantSecretKey = 'KbZaALRZnMAEh8NJPmIAHEso95DlAo6G0Z+lJWaAbKo=';*/

//const axios = require("axios");

export default class generarFactura{
    constructor(data) {
        this.data = data;
    }

    crearFactura(){
        return new Promise((resolve, reject) => {
            var axios = require('axios');
            var data = this.data;

            var config = {
                method: 'post',
                url: 'https://webservices.transexpress.com.gt/api/ser_fact2/0',
                headers: {
                    'userid': 'transexpress',
                    'pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                    'Content-Type': 'application/json'
                },
                data : JSON.stringify(this.data)
            };

            axios(config)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        })
        //return this.data;
    }

}

/*module.exports = generarFactura*/