import logo from './logo.svg';
import './App.css';
import Routing from "./Routes/routes";
function App() {
  return (
   <Routing></Routing>
  );
}

export default App;
