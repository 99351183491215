import React, {useEffect, useState} from "react";
import Welcome from "../master_pobox";
import {Button, Form, Modal, Alert, InputGroup, Row, Card, Col, Nav} from "react-bootstrap";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import "../css/preloader3.css";
import generarClienteDireccion from "../Components/Clases/claseTokenCostumer";
import wsObtenerToken from "../Components/Clases/wsObtenerToken";
import generarTokenTar from "../Components/Clases/claseTokenTar";
import claves from "../Components/Clases/claves";
import CardGroup from 'react-bootstrap/CardGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class MisTarjetas extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            mesTarjeta: [],
            anioTarjeta: [],
            token: [],
            tarjetas: [],
            tarjetasImp: [],
        };
        this.setState({ loading: false});
    }

    state = {
        txtNombreTarjeta: '',
        txtApellidoTarjeta: '',
        txtFechaVencimientomes: '',
        txtFechaVencimientoanio: '',
        txtCvv: '',
        formColor: '#E5E5E5',
        formColornom: '#E5E5E5',
        formColormes: '#E5E5E5',
        formColoranio: '#E5E5E5',
        formColorcvv: '#E5E5E5',
        formColorape: '#E5E5E5',
        txtPredeterminada: false,
        tarjetaReg: false,        
    }

    changetar = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        var dateObj = new Date();
        switch(e.target.name) {
            case 'txtTarjeta':
                let variable = e.target.value;
                let dummyString = variable;
                dummyString = dummyString.replace('.','');
                if(dummyString.length > 19){
                    dummyString = dummyString.replace(/.$/,"")
                }else if(dummyString.length >= 16){
                    var luhn = this.luhn_validate(dummyString);
                    if(luhn){
                        this.state.tarjetavalida = true;
                        this.setState({formColor: '#2684E7'});
                    }else{
                        this.setState({formColor: '#E72626'});
                        this.state.tarjetavalida = false;
                    }
                }else{
                    this.setState({formColor: '#E72626'});
                    this.state.tarjetavalida = false;
                }
                this.setState({txtTarjeta: dummyString});
                break;
            case 'txtNombreTarjeta':
                if(e.target.value.length == 0){
                    this.setState({formColornom: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColornom: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'txtApellidoTarjeta':
                if(e.target.value.length == 0){
                    this.setState({formColorape: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColorape: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'CmbMesTarjeta':
                if(e.target.value == null || this.state.CmbAnioTarjeta == null){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                    return;
                }
                if(this.state.CmbAnioTarjeta < dateObj.getUTCFullYear()){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else if((this.state.CmbAnioTarjeta == dateObj.getUTCFullYear() && e.target.value <= dateObj.getUTCMonth())){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColormes: '#2684E7'});
                    this.setState({formColoranio: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'CmbAnioTarjeta':
                if(this.state.CmbMesTarjeta == null || e.target.value == null){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                    return;
                }
                if(e.target.value < dateObj.getUTCFullYear()){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else if((e.target.value == dateObj.getUTCFullYear() && this.state.CmbMesTarjeta <= dateObj.getUTCMonth())){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColormes: '#2684E7'});
                    this.setState({formColoranio: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'txtCvv':
                if(e.target.value.length > 0 && e.target.value.length < 5){
                    let variablecvv = e.target.value;
                    const regexpSize = /([0-9]+)/;
                    let dummyStringcvv = variablecvv.match(regexpSize);
                    this.setState({txtCvv: ''})
                    if(dummyStringcvv != null) {
                        this.setState({txtCvv: dummyStringcvv[0]})
                    }
                    this.state.tarjetavalida = true;
                    this.setState({formColorcvv: '#2684E7'});
                }else if(e.target.value.length >= 5){
                    let dummyStringcvv = e.target.value;
                    dummyStringcvv = dummyStringcvv.replace(/.$/,"");
                    this.setState({txtCvv: dummyStringcvv});
                }else{
                    this.state.tarjetavalida = false;
                    this.setState({formColorcvv: '#E72626'});
                }
                break;
        }
    }
    luhn_validate(fullcode) {
        return this.luhn_checksum(fullcode) == 0
    }
    luhn_checksum(code) {
        var len = code.length
        var parity = len % 2
        var sum = 0
        for (var i = len-1; i >= 0; i--) {
            var d = parseInt(code.charAt(i))
            if (i % 2 == parity) { d *= 2 }
            if (d > 9) { d -= 9 }
            sum += d
        }
        return sum % 10
    }

    onSubmit = async e => {
        e.preventDefault()
        if(this.state.tarjetavalida){
            this.setState({ loading: true});
            let txttarjeta = this.state.txtTarjeta;
            let txtNombreTarjeta = this.state.txtNombreTarjeta;
            let txtApellidoTarjeta = this.state.txtApellidoTarjeta;
            let txtFechaVencimientoanio = this.state.CmbAnioTarjeta;
            let txtFechaVencimientomes = this.state.CmbMesTarjeta;
            let txtcvv = this.state.txtCvv;

            
            /*if(txtcvv == undefined || txtNombreTarjeta == undefined || txtApellidoTarjeta == undefined || txttarjeta == undefined || txtFechaVencimientoanio == undefined || txtFechaVencimientomes == undefined){
                this.setState({ loading: false});
                cogoToast.warn("Datos de tarjeta no completos");
                return;
            }*/

            var llaves = [];

            
            /*console.log(claves.idw)
            console.log(claves.id_web)
            console.log(claves.key_web)
            console.log(claves.secret_web)*/
            llaves.push({id:claves.idw, idc:claves.id_web, key:claves.key_web, secret:claves.secret_web});
            llaves.push({id:claves.idi, idc:claves.id_imp, key:claves.key_imp, secret:claves.secret_imp});
            llaves.push({id:claves.ida, idc:claves.id_app, key:claves.key_app, secret:claves.secret_app});
            
            //console.log(llaves);
            //console.log(this.state.token);
            this.setState({ loading: false});
            //return;
            if(this.state.tarjetaReg){            
                let tokens = this.state.token;
                //console.log(tokens);
                if(tokens.length > 0){
                    for (const property in tokens) {
                        const llave = llaves.find(obj => {
                            return (obj.id === tokens[property].id_afiliacion);
                        });
                        
                        //console.log(txttarjeta, txtFechaVencimientomes, txtFechaVencimientoanio, tokens[property].customer, llave.idc, llave.key, llave.secret, txtNombreTarjeta, '', localStorage.getItem('email'), localStorage.getItem('rtu_celular'))
                        if(llave != undefined){
                            //console.log(llave);
                            let tarj;
                            const saveTarjeta = new generarTokenTar(txttarjeta, txtFechaVencimientomes, txtFechaVencimientoanio, tokens[property].customer, llave.idc, llave.key, llave.secret, txtNombreTarjeta, '', localStorage.getItem('email'), localStorage.getItem('rtu_celular'));
                            /*tarj = await */saveTarjeta.saveTar();
                            /*console.log(saveTarjeta);
                            if(!tarj){
                                //console.log(tarj);
                                this.setState({ loading: false});
                                swal("Ayuda", "Ha ocurrido un error al registrar tarjeta", "error");
                                return;
                            }*/
                        }
                    }
                    swal("Trans-Express", "Registro completado", "success");
                    this.limpiar();
                }
            }else{
                const saveCostumer = new generarClienteDireccion(localStorage.getItem('seccion'), localStorage.getItem('email'), localStorage.getItem('id_cuenta'), txtNombreTarjeta, txtApellidoTarjeta, localStorage.getItem('rtu_celular'), txttarjeta, txtFechaVencimientomes, txtFechaVencimientoanio, this.state.tarjetaReg);
                costumer = await saveCostumer.crearClienteWeb();                
                swal("Trans-Express", "Registro completado", "success");
                this.limpiar()                
            }
        }else {            
            this.setState({ loading: false});
            cogoToast.warn("Datos de tarjeta no completos");
            return;
        }
        //componentDidMount();
        this.setState({ loading: false});
    }

    limpiar(){
        this.setState({txtTarjeta: ''});
        this.setState({txtNombreTarjeta: ''});
        this.setState({txtApellidoTarjeta: ''});
        this.setState({CmbAnioTarjeta: 0});
        this.setState({CmbMesTarjeta: 0});
        this.setState({txtCvv: ''});
    }

    async componentDidMount() {

        const d = new Date();
        let year = d.getFullYear();
        
        //for (let i = 1; i <= 12; i++) {

            this.state.mesTarjeta.push({ value:'01', label: '01' });
            this.state.mesTarjeta.push({ value:'02', label: '02' });
            this.state.mesTarjeta.push({ value:'03', label: '03' });
            this.state.mesTarjeta.push({ value:'04', label: '04' });
            this.state.mesTarjeta.push({ value:'05', label: '05' });
            this.state.mesTarjeta.push({ value:'06', label: '06' });
            this.state.mesTarjeta.push({ value:'07', label: '07' });
            this.state.mesTarjeta.push({ value:'08', label: '08' });
            this.state.mesTarjeta.push({ value:'09', label: '09' });
            this.state.mesTarjeta.push({ value:'10', label: '10' });
            this.state.mesTarjeta.push({ value:'11', label: '11' });
            this.state.mesTarjeta.push({ value:'12', label: '12' });
        //}
        for (let i = year; i <= (year+10); i++) {
            this.state.anioTarjeta.push({ value:i, label: i });
        }
        const tokens = new wsObtenerToken();
        const token = await tokens.obtenerTokens();
        this.setState({token: token});
        //console.log(this.state.token);
        //this.recargar_paquetes();
        this.obtiene_tarjeta(claves.idw, claves.id_web, claves.key_web, claves.secret_web);
        this.obtiene_tarjeta(claves.idi, claves.id_imp, claves.key_imp, claves.secret_imp);
        this.obtiene_tarjeta(claves.ida, claves.id_app, claves.key_app, claves.secret_app);
    }

    async obtiene_tarjeta(i, id, key, secret){
        let result = '';
        const tokens = new wsObtenerToken();
        const token = await tokens.obtenerTokens();
        this.setState({token: token});
        
        if(token.length > 0){            

            for (const property in token) {        
                if(i == token[property].id_afiliacion){
                    const axios2 = require('axios');
                    const FormData2 = require('form-data');
                    let data2 = new FormData2();
                    data2.append('customerId', token[property].customer);
                    let config2 = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://users.transexpress.com.gt/apiimpget.php',
                    headers: {
                        'id': id,//'visanetgt_transexpress', 
                        'key': key,//'eb395bb1-8dfa-46eb-af8b-aa28897fdd39', 
                        'secret': secret//'l8xMjp2hoylBbq+4RQUJ08UVd7d5/wGfrX7E+1/yMPU=',
                    },
                    data : data2
                    };
                    axios2.request(config2)
                    .then((response) => {
                        console.log(response);
                        if(i == '1'){
                            //console.log('------------------');
                            response.data._embedded.paymentInstruments.costumerid = token[property].customer;
                            response.data._embedded.paymentInstruments.address = token[property].address;
                            //console.log(response.data._embedded.paymentInstruments);
                            this.setState({tarjetas: response.data._embedded.paymentInstruments});
                            this.setState({tarjetaReg: '1'});
                            this.setState({CmbToken: response.data._embedded.paymentInstruments[0].id});
                            this.setState({CmbTokenNum: response.data._embedded.paymentInstruments[0]._embedded.instrumentIdentifier.card.number});
                        }else if(i == '3'){
                            //console.log('------------------');
                            response.data._embedded.paymentInstruments.costumerid = token[property].customer;
                            response.data._embedded.paymentInstruments.address = token[property].address;
                            //console.log(response.data._embedded.paymentInstruments);
                            this.setState({tarjetasImp: response.data._embedded.paymentInstruments});
                        }
                    })
                    .catch((error) => {
                        console.log(error);                    
                    });
                    
                }
            }
            
        }
    }

    onModify(e, id){
        alert(id);
    }

    

    render(){
        const {loading } = this.state;
        //const [modalShow, setModalShow] = React.useState(false);

        function tarjetasRegistradas(){
            return(
                <>Hola</>
            );
        }

        function MyVerticallyCenteredModal(props) {
            console.log('----')
            return (
              <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar tarjeta
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <h4>Tarjeta: {props.id.id._embedded.instrumentIdentifier.card.number}</h4>
                    <br></br>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="MonthUpdate">Mes</InputGroup.Text>
                        <Form.Control
                        value={props.id.id.card.expirationMonth}
                        placeholder="Mes"
                        aria-describedby="MonthUpdate"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="MonthUpdate">Año</InputGroup.Text>
                        <Form.Control
                        value={props.id.id.card.expirationYear}
                        placeholder="Año"
                        aria-describedby="MonthUpdate"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="MonthUpdate">CVV</InputGroup.Text>
                        <Form.Control
                        placeholder="CVV"
                        aria-describedby="MonthUpdate"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={props.onHide}>Actualizar</Button>
                    <Button variant="danger" onClick={props.onHide}>Close</Button>
                </Modal.Footer>
              </Modal>
            );
            /*{props.id._embedded.instrumentIdentifier.card.number}*/
        }

        function App(id) {
            const [modalShow, setModalShow] = React.useState(false);
            return(
                <>
                <Button variant="success" onClick={() => setModalShow(true)}>Actualizar</Button>        
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    id={id}
                />
                </>
            );
            
        }
        
        return (
            <Welcome>                
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-address-book"/> MIS TARJETAS
                            <small>
                                Tarjetas de cobro
                            </small>
                        </h1>
                    </div>
                    <div>
                    
                        <Tab.Container
                            defaultActiveKey="tarjetas"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            fill
                            >
                            <Nav  class="nav nav-pills nav-fill">
                                <Nav.Item>
                                    <Nav.Link eventKey="tarjetas">Mis Tarjetas</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="add">Agregar</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <br></br>
                            <br></br>
                            <Tab.Content>                                
                                <Tab.Pane eventKey="tarjetas" title="Mis tarjetas">
                                    <div className="panel-content">                                    
                                        <Row xs={1} md={2} className="g-4">
                                            {this.state.tarjetas.map(opt => (
                                                <Col key={opt}>
                                                <Card>
                                                    <Card.Header className="card text-white bg-primary"><h5>Visa</h5></Card.Header>
                                                    <Card.Body>
                                                        <Card.Text>
                                                        <h3>{opt._embedded.instrumentIdentifier.card.number}</h3>
                                                        {opt.card.expirationMonth}/{opt.card.expirationYear}
                                                        </Card.Text>
                                                        <App id={opt/*._embedded.instrumentIdentifier.card.number*/}/>
                                                        <Button variant="danger">Eliminar</Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            ))}
                                        </Row>    
                                    </div>                                    
                                </Tab.Pane>
                                <Tab.Pane eventKey="add" title="Agregar Tarjeta">
                                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                                        <div className="panel-content">
                                            <form>
                                                <Form>
                                                    <Form.Group className="mb-3" >
                                                        <label className="form-label" >Numero de tarjeta</label>
                                                        <Form.Control value={this.state.txtTarjeta} type="number" step="0" placeholder="No. Tarjeta" name="txtTarjeta" style={{borderColor: this.state.formColor}} onChange={e => this.changetar(e)}/>
                                                    </Form.Group>                                    
                                                    
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <label className="form-label" >Fecha Vencimiento</label>
                                                        <Form.Control as="select" className="custom-select form-control" value={this.state.CmbMesTarjeta} name="CmbMesTarjeta" style={{borderColor: this.state.formColormes}} onChange={e => this.changetar(e)}>
                                                            <option key={0} value={0} disabled selected hidden>Mes</option>
                                                            {this.state.mesTarjeta.map(opt => (
                                                                <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                    
                                                    
                                                        <Form.Control as="select" className="custom-select form-control" placeholder="Año" value={this.state.CmbAnioTarjeta} name="CmbAnioTarjeta" style={{borderColor: this.state.formColoranio}} onChange={e => this.changetar(e)}>
                                                            <option key={0} value={0} disabled selected hidden>Año</option>
                                                            {this.state.anioTarjeta.map(opt => (
                                                                <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <label className="form-label" >CVV</label>
                                                        <Form.Control value={this.state.txtCvv} type="password" step="0.01" placeholder="CVV" name="txtCvv" style={{borderColor: this.state.formColorcvv}} onChange={e => this.changetar(e)}/>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <label className="form-label" >Nombre</label>
                                                        <Form.Control value={this.state.txtNombreTarjeta} type="text" placeholder="Nombre Tarjeta" name="txtNombreTarjeta" style={{borderColor: this.state.formColornom}} onChange={e => this.changetar(e)}/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <label className="form-label" >Apellido</label>
                                                        <Form.Control value={this.state.txtApellidoTarjeta} type="text" placeholder="Nombre Tarjeta" name="txtApellidoTarjeta" style={{borderColor: this.state.formColorape}} onChange={e => this.changetar(e)}/>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <label className="form-label" >Establecer como predeterminada</label>
                                                        <Form.Check label="" value={this.state.txtPredeterminada} type="checkbox" name="txtPredeterminada" onChange={e => this.changetar(e)}/>
                                                    </Form.Group>
                                                    <br></br>
                                                </Form>
                                                <Button id="envioinfo" variant="primary" block disabled={loading} onClick = {e => this.onSubmit(e)} >{loading ? 'Cargando…' : 'Enviar Información'}</Button>
                                            </form>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>                                    
                </main>                
            </Welcome>
        )
    }
}

export default MisTarjetas;