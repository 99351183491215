import React from 'react';
import apiUrl from "../../enviroments/enviroment";
import axios from 'axios';

class CatDestinatarios extends React.Component {

    state = {
        destinatarios: [],
    };


    componentDidMount() {
        this.peticion();
    }

    peticion  = async () => {
        axios.get(`${apiUrl.apiUrl}destinatarios_courier/` + localStorage.getItem('seccion'), {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({destinatarios: res.data});
        });
        console.log(this.state.destinatarios);
    }

    render(){
        return(
            this.state.destinatarios.map((gen) => <option  key={gen.id_destinatario} value={gen.id_destinatario}>{gen.nombre}</option>)
        );
    }

}

export default CatDestinatarios;
