const swal = require("sweetalert");

export default class generarCyber {
    constructor(code, firstname, lastname, address1, postalcode, locality, phonenumber, email, totalamount, expirationyear, number, securitycode, expirationmonth) {
        this.code = code;
        this.firstname = firstname;
        this.lastname = lastname;
        this.address1 = address1;
        this.postalcode = postalcode;
        this.locality = locality;
        this.phonenumber = phonenumber;
        this.email = email;
        this.totalamount = totalamount;
        this.expirationyear = expirationyear;
        this.number = number;
        this.securitycode = securitycode;
        this.expirationmonth = expirationmonth;
    }

    GenerarJsonCyber(){

        let letter = this.number.charAt(0);
        let typecard = ''

        if(letter == '4'){
            typecard = '001'
        }else if(letter == '5'){
            typecard = '002'
        }else {
            swal("Ayuda", "Tarjeta no valida", "error");
            return;
        }

        var cyber = '';
        cyber = '{' +
            '"clientReferenceInformation": {' +
            '"code": "' + this.code + '"' +
            '},' +
            '"processingInformation": {' +
            '"commerceIndicator": "internet",' +
            '"capture": true' +
            '},' +
            '"orderInformation": {' +
            '"billTo": {' +
            '"firstName": "' + this.firstname + '",' +
            '"lastName": "' + this.lastname + '",' +
            '"address1": "' + this.address1 + '",' +
            '"postalCode": "' + this.postalcode + '",' +
            '"locality": "' + this.locality + '",' +
            '"country": "GT",' +
            '"phoneNumber": "' + this.phonenumber + '",' +
            '"email":"' + this.email +'"' +
            '},' +
            '"amountDetails": {' +
            '"totalAmount": "' + this.totalamount + '",' +
            '"currency": "GTQ"' +
            '}' +
            '},' +
            '"paymentInformation": {' +
            '"card": {' +
            '"expirationYear": "' + this.expirationyear + '",' +
            '"number": "' + this.number + '",' +
            '"securityCode": "' + this.securitycode + '",' +
            '"expirationMonth": "' + this.expirationmonth + '",' +
            '"type": "' + typecard + '"' +
            '}' +
            '}' +
            '}';

            console.log(cyber);
        return cyber;
    }
}

//module.exports = generarCyber