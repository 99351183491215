import React from 'react';
import apiUrl from "../../enviroments/enviroment";
import axios from 'axios';

class CatArancel extends React.Component {

    state = {
        aranceles: [],
    };


    componentDidMount() {
        this.peticion();
    }

    peticion  = async () => {
        axios.get(`${apiUrl.apiUrl}contenido`, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({aranceles: res.data});
        });
        console.log(this.state.aranceles);
    }

    render(){
        return(
            this.state.aranceles.map((gen) => <option  key={gen.ID_CONTENIDO} value={gen.ID_CONTENIDO}>{gen.DESCRIPCION} - {gen.PORCENTAJE_ARANCEL} %</option>)
        );
    }

}

export default CatArancel;
