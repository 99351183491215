import React from "react";
import Welcome from "../master";
import {Button, Form} from "react-bootstrap";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class CambioContrasena extends React.Component{
    state = {
        TxtUsuario: '',
        TxtEmail: '',
        TxtContrasenaVieja: '',
        TxtContrasenaNueva: '',
        TxtContrasenaConfirma: '',
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        let TxtUsuario = this.state.TxtUsuario;
        let TxtEmail = this.state.TxtEmail;
        let TxtContrasenaVieja = this.state.TxtContrasenaVieja;
        let TxtContrasenaNueva = this.state.TxtContrasenaNueva;
        let TxtContrasenaConfirma = this.state.TxtContrasenaConfirma;
        //?=.\W+)(?![.\n]).$
        //var strongcaracter = new RegExp('(?=.\W+)(?![.\n]).$');
        //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])((?=.\W+)(?![.\n]).$)(?=.{8,})");
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.!@#\$%\^&\*])(?=.{8,})");
        //var strongRegex = new RegExp("(?=^.{8,}$)((?=.\d)(?=.\W+))(?![.\n])(?=.[A-Z])(?=.[a-z]).*$");

        if(!strongRegex.test(TxtContrasenaNueva)){
            cogoToast.warn("Contraseña no segura, debe contener mas de 8 caracteres, una letra mayuscula, una minuscula y un caracter especial");
            return;
        }

        var sha256 = require("js-sha256");
        const url_service = `${apiUrl.apiUrl}cambiar_password`;

        if (TxtUsuario == null || TxtUsuario.toString().trim().length == 0){
            cogoToast.warn("El usuario es necesario para continuar");
            return;
        }

        if (TxtEmail == null || TxtEmail.toString().trim().length == 0){
            cogoToast.warn("El email es necesario para continuar");
            return;
        }

        if (TxtContrasenaVieja == null || TxtContrasenaVieja.toString().trim().length == 0){
            cogoToast.warn("La contraseña antigua es necesaria para continuar");
            return;
        }

        if (TxtContrasenaNueva == null || TxtContrasenaNueva.toString().trim().length == 0){
            cogoToast.warn("El Contraseña Nueva es necesaria para continuar");
            return;
        }

        if (TxtContrasenaConfirma == null || TxtContrasenaConfirma.toString().trim().length == 0){
            cogoToast.warn("Debes confirmar tu contraseña para continuar");
            return;
        }

        if (TxtContrasenaNueva != TxtContrasenaConfirma){
            cogoToast.warn("Las contraseñas no coinciden, verifica para poder continuar");
            return;
        }

        var data ={
            username: TxtUsuario,
            email: TxtEmail,
            contrasena_anterior: sha256(TxtContrasenaVieja),
            contrasena_nueva: sha256(TxtContrasenaNueva)
        };

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                let respuesta = response.data[0].resultado;

                if (respuesta == "1"){
                    swal("Cambio Contraseña", "Se ha realizado su cambio de contraseña", "success");

                    this.setState({
                        TxtUsuario: '',
                        TxtEmail: '',
                        TxtContrasenaVieja: '',
                        TxtContrasenaNueva: '',
                        TxtContrasenaConfirma: '',
                    })
                }else{
                    swal("Cambio Contraseña", "Ha ocurrido un error, si persiste comunicate con el administrador", "error");
                }
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });


    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Cambiar la Contraseña
                            <small>
                                Ingresa la informacion requerida para poder realizar el cambio de tu contraseña
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Usuario</label>
                                    <Form.Control value={this.state.TxtUsuario}  type="text" placeholder="Ingrese el usuario" name="TxtUsuario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Email</label>
                                    <Form.Control value={this.state.TxtEmail}  type="text" placeholder="Ingrese el email" name="TxtEmail" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Contraseña Antigua</label>
                                    <Form.Control value={this.state.TxtContrasenaVieja}  type="password" placeholder="Ingrese la contraseña antigua" name="TxtContrasenaVieja" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Contraseña Nueva</label>
                                    <Form.Control value={this.state.TxtContrasenaNueva}  type="password" placeholder="Ingrese la contraseña nueva" name="TxtContrasenaNueva" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Confirma la Contraseña Nueva</label>
                                    <Form.Control value={this.state.TxtContrasenaConfirma}  type="password" placeholder="Ingrese la contraseña nueva nuevamente" name="TxtContrasenaConfirma" onChange={e => this.change(e)}/>
                                </div>

                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Cambiar Contraseña</Button>
                            </form>
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default CambioContrasena;
