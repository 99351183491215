import React from "react";
import apiUrl from "../enviroments/enviroment";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import swal from "sweetalert";

class CrearCasillero extends React.Component {
    state = {
        TxtNombreCliente: "",
        TxtApellidoCliente: "",
        TxtTelefono: "",
        TxtCelular: "",
        TxtEmail: "",
        TxtNIT: "",
        TxtUsuario: "",
        TxtFechaNac: "",
        TxtIngresaContrasena: "",
        TxtRepiteContrasena: "",
        TxtDirMensajeria: "",
        TxtDirFacturacion: "",
    };

    change = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();

        var P_TxtNombreCliente = this.state.TxtNombreCliente;
        var P_TxtApellidoCliente = this.state.TxtApellidoCliente;
        var P_TxtTelefono = this.state.TxtTelefono;
        var P_TxtCelular = this.state.TxtCelular;
        var P_TxtEmail = this.state.TxtEmail;
        var P_TxtNIT = this.state.TxtNIT;
        var P_TxtUsuario = this.state.TxtUsuario;
        var P_TxtFechaNac = this.state.TxtFechaNac;
        var P_TxtIngresaContrasena = this.state.TxtIngresaContrasena;
        var P_TxtRepiteContrasena = this.state.TxtRepiteContrasena;
        var P_TxtDirMensajeria = this.state.TxtDirMensajeria;
        var P_TxtDirFacturacion = this.state.TxtDirFacturacion;

        const url_service = `${apiUrl.apiUrl}suscripcion_prime`;
        var respuesta = false;

        if (
            P_TxtNombreCliente == null ||
            P_TxtNombreCliente.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "Los nombres del clientes son necesarios para poder continuar"
            );
            return;
        }
        if (
            P_TxtApellidoCliente == null ||
            P_TxtApellidoCliente.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "Los apellidos del cliente son necesarios para poder continuar"
            );
            return;
        }
        if (
            P_TxtTelefono == null ||
            P_TxtTelefono.toString().trim().length == 0
        ) {
            cogoToast.warn("El telefono es necesario para poder continuar");
            return;
        }
        if (
            P_TxtCelular == null ||
            P_TxtCelular.toString().trim().length == 0
        ) {
            cogoToast.warn("El celular es necesario para poder continuar");
            return;
        }
        if (P_TxtEmail == null || P_TxtEmail.toString().trim().length == 0) {
            cogoToast.warn("El email es necesario para poder continuar");
            return;
        }
        if (P_TxtNIT == null || P_TxtNIT.toString().trim().length == 0) {
            cogoToast.warn("El NIT es necesario para poder continuar");
            return;
        }
        if (
            P_TxtUsuario == null ||
            P_TxtUsuario.toString().trim().length == 0
        ) {
            cogoToast.warn("El usuario es necesario para poder continuar");
            return;
        }
        if (
            P_TxtFechaNac == null ||
            P_TxtFechaNac.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "La fecha de nacimiento es necesaria para poder continuar"
            );
            return;
        }
        if (
            P_TxtIngresaContrasena == null ||
            P_TxtIngresaContrasena.toString().trim().length == 0
        ) {
            cogoToast.warn("La contraseña es necesario para poder continuar");
            return;
        }
        if (
            P_TxtRepiteContrasena == null ||
            P_TxtRepiteContrasena.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "Se nececita repetir la contraseña para poder continuar"
            );
            return;
        }
        if (
            P_TxtDirMensajeria == null ||
            P_TxtDirMensajeria.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "La direccion de mensajeria es necesaria para poder continuar"
            );
            return;
        }
        if (
            P_TxtDirFacturacion == null ||
            P_TxtDirFacturacion.toString().trim().length == 0
        ) {
            cogoToast.warn(
                "La direccion de facturación es necesaria para poder continuar"
            );
            return;
        }

        var sha256 = require("js-sha256");
        var data = {
            nombre_titular: P_TxtNombreCliente,
            apellido_titular: P_TxtApellidoCliente,
            telefono_titular: P_TxtTelefono,
            celular_titular: P_TxtCelular,
            email_titular: P_TxtEmail,
            nit_titular: P_TxtNIT,
            username: P_TxtUsuario,
            contrasena: sha256(P_TxtIngresaContrasena),
            fecha_nacimiento_titular: P_TxtFechaNac,
            direccion_mensajeria: P_TxtDirMensajeria,
            direccion_cobro_facturacion: P_TxtDirFacturacion,
        };

        axios
            .post(url_service, data, {
                headers: {
                    userid: "transexpress",
                    pwduser: "JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S",
                },
            })
            .then(
                (response) => {
                    respuesta = response.data[0];
                    console.log(respuesta);

                    if (respuesta.resultado == "1") {
                        swal(
                            "Suscripcion Prime",
                            "Bienvenido a la familia Transexpress, tu sección es la " +
                                respuesta.SECCION +
                                " y el PIN es el siguiente: " +
                                respuesta.PIN,
                            "success"
                        );

                        this.setState({
                            TxtNombreCliente: "",
                            TxtApellidoCliente: "",
                            TxtTelefono: "",
                            TxtCelular: "",
                            TxtEmail: "",
                            TxtNIT: "",
                            TxtUsuario: "",
                            TxtFechaNac: "",
                            TxtIngresaContrasena: "",
                            TxtRepiteContrasena: "",
                            TxtDirMensajeria: "",
                            TxtDirFacturacion: "",
                        });
                    } else {
                        swal(
                            "Suscripcion Prime",
                            "Ha ocurrido un error " +
                                respuesta.error +
                                ", contactate con el administrador",
                            "error"
                        );
                    }
                },
                (error) => {
                    swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
                }
            );
    };

    render() {
        return (
            <div>
                <div>
                    <div className="page-wrapper auth">
                        <div className="page-inner bg-brand-gradient">
                            <div className="page-content-wrapper bg-transparent m-0">
                                <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                                    <div className="d-flex align-items-center container p-0">
                                        <img
                                            src={
                                                "../src/img/logos/ICONO-TE.png"
                                            }
                                            width="200"
                                            aria-roledescription="logo"
                                        />
                                        <span className="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                                            Ya estas registrado?
                                        </span>
                                        <Link to={`/Login`}>
                                            <a
                                                href="#"
                                                className="btn-link text-white ml-auto ml-sm-0"
                                            >
                                                Ingresar a Plataforma
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                                <div
                                    className="flex-1"
                                    style={{
                                        background:
                                            "url(img/svg/pattern-1.svg) no-repeat center bottom fixed",
                                        backgroundSize: "cover",
                                    }}
                                >
                                    <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <h2 className="fs-xxl fw-500 mt-4 text-white text-center">
                                                    Crea tu casillero con
                                                    nosotros
                                                </h2>
                                                <br />
                                            </div>
                                            <div className="col-xl-6 ml-auto mr-auto">
                                                <div className="card p-4 rounded-plus bg-faded">
                                                    <form
                                                        id="js-login"
                                                        noValidate
                                                        action="intel_analytics_dashboard.html"
                                                    >
                                                        <div align="center">
                                                            <img
                                                                src={
                                                                    "src/img/ICONO-PENCIL.png"
                                                                }
                                                                alt="SmartAdmin WebApp"
                                                                width="200"
                                                            />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-6 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    nombre
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtNombreCliente
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    placeholder="Ingrese nombres del titular"
                                                                    name="TxtNombreCliente"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                            <div className="col-6 pl-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Apellido
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtApellidoCliente
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    placeholder="Ingrese apellidos del titular"
                                                                    name="TxtApellidoCliente"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-6 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Telefono
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtTelefono
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="number"
                                                                    placeholder="Ingrese telefono"
                                                                    name="TxtTelefono"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                            <div className="col-6 pl-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Celular
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtCelular
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="number"
                                                                    placeholder="Ingrese celular"
                                                                    name="TxtCelular"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-8 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    email
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtEmail
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="email"
                                                                    placeholder="Ingrese el email del titular"
                                                                    name="TxtEmail"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                            <div className="col-4 pl-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    NIT
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtNIT
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    placeholder="Ingrese tu NIT"
                                                                    name="TxtNIT"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-6 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Usuario
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtUsuario
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    placeholder="Ingresa tu usuario"
                                                                    name="TxtUsuario"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                            <div className="col-6 pl-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Fecha de
                                                                    Nacimiento
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtFechaNac
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="date"
                                                                    placeholder="Ingrese tu NIT"
                                                                    name="TxtFechaNac"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-6 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Ingresa tu
                                                                    Contraseña
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtIngresaContrasena
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="password"
                                                                    placeholder="Ingresa tu contraseña"
                                                                    name="TxtIngresaContrasena"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                            <div className="col-6 pl-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Repite tu
                                                                    Contraseña
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtRepiteContrasena
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="password"
                                                                    placeholder="Repita tu contraseña"
                                                                    name="TxtRepiteContrasena"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                                <div className="invalid-feedback">
                                                                    No, you
                                                                    missed this
                                                                    one.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-12 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Dirección de
                                                                    Mensajería
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtDirMensajeria
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    as="textarea"
                                                                    row="10"
                                                                    placeholder="Ingresa tu dirección de mensajeria"
                                                                    name="TxtDirMensajeria"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-12 pr-1">
                                                                <label
                                                                    className="col-xl-12 form-label"
                                                                    htmlFor="fname"
                                                                >
                                                                    Dirección de
                                                                    Facturación
                                                                </label>
                                                                <Form.Control
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .TxtDirFacturacion
                                                                    }
                                                                    className="form-control form-control-lg"
                                                                    type="text"
                                                                    as="textarea"
                                                                    row="10"
                                                                    placeholder="Ingresa tu dirección de facturación"
                                                                    name="TxtDirFacturacion"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.change(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row no-gutters">
                                                            <div className="col-md-4 ml-auto text-right">
                                                                <Button
                                                                    className={
                                                                        "btn btn-info btn-block btn-lg"
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.onSubmit(
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    Registrar
                                                                    Casillero
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearCasillero;
