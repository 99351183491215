import React from "react";
import {BrowserRouter as Router,Switch, Route} from "react-router-dom";
import ConfigRouting from './ConfigRouting.js';
import {map} from 'lodash';

export default function Routing() {
    return (
        <Router>
            <Switch>
                {map(ConfigRouting, (route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}>
                        <route.page />
                    </Route>
                ))}
            </Switch>
        </Router>
    );
}
