import React from "react";
import Welcome from "../master_local";
import {Button, Form, Modal, Alert} from "react-bootstrap";
import CatDepartmaento from "../Components/Servicios/CatDepartmaento"
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class GuiaLocal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            municipios: [],
            tmpMunicipios_remintete: [],
            tmpMunicipios_destinatario: [],
            txtNombreRemitente: '',
            txtEmpresaRemitente: '',
            txtEmailRemitente: '',
            txtNitRemitente: '',
            txtDireccionRecolecta: '',
            txtDireccionFactura: '',
            txtDepartamentoRemitente: '',
            txtMuniRemitente: '',
            txtTelefonoRemitente: '',
            txtTelefonoDestinatario: '',
            txtNombreDistanatario: '',
            txtContactoDestinatario: '',
            txtEmpresaDestinatario: '',
            txtEmailDestinatario: '',
            txtDireccionDestinatario: '',
            txtDepartamentoDestinatario: '',
            txtMunicipioDestinatario: '',
            txtMontoCOD: '',
            txtPeso: '',
            txtTipoEnvio: '',
            txtDescripcion: '',
            txtZonaEntrega: '',
            txtObservaciones: '',
            txtNombreMunicipio: '',

        }
    }
    state = {
        txtNombreRemitente: '',
        txtEmpresaRemitente: '',
        txtEmailRemitente: '',
        txtNitRemitente: '',
        txtDireccionRecolecta: '',
        txtDireccionFactura: '',
        txtDepartamentoRemitente: '',
        txtMuniRemitente: '',
        txtTelefonoRemitente: '',
        txtTelefonoDestinatario: '',
        txtNombreDistanatario: '',
        txtContactoDestinatario: '',
        txtEmpresaDestinatario: '',
        txtEmailDestinatario: '',
        txtDireccionDestinatario: '',
        txtDepartamentoDestinatario: '',
        txtMunicipioDestinatario: '',
        txtMontoCOD: '',
        txtPeso: '',
        txtTipoEnvio: '',
        txtDescripcion: '',
        txtZonaEntrega: '',
        txtObservaciones: '',
        municipios: [],
        tmpMunicipios_remintete: [],
        tmpMunicipios_destinatario: [],
        txtNombreMunicipio: '',

    }
    change_departamento_remitente = e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_departamento == e.target.value );
        this.setState({
            [e.target.name]: e.target.value, tmpMunicipios_remintete: temporal
        })
    }
    change_departamento_destinatario= e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_departamento == e.target.value );
        this.setState({
            [e.target.name]: e.target.value, tmpMunicipios_destinatario: temporal
        })
    }

    change_municipio_destinatario= e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_municipio == e.target.value );
        if ( temporal.length > 0 )
        {
            this.state.txtNombreMunicipio = temporal[0].nombre;
        }
        else
            this.state.txtNombreMunicipio = "";
        console.log(this.state.txtNombreMunicipio );
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = e => {
        e.preventDefault();
        let txtNombreRemitente = this.state.txtNombreRemitente;
        let txtEmpresaRemitente = this.state.txtEmpresaRemitente;
        let txtEmailRemitente = this.state.txtEmailRemitente;
        let txtNitRemitente = this.state.txtNitRemitente;
        let txtDireccionRecolecta = this.state.txtDireccionRecolecta;
        let txtDireccionFactura = this.state.txtDireccionFactura;
        let txtDepartamentoRemitente = this.state.txtDepartamentoRemitente;
        let txtMuniRemitente = this.state.txtMuniRemitente;
        let txtTelefonoRemitente = this.state.txtTelefonoRemitente;
        let txtTelefonoDestinatario = this.state.txtTelefonoDestinatario;
        let txtNombreDistanatario = this.state.txtNombreDistanatario;
        let txtContactoDestinatario = this.state.txtContactoDestinatario;
        let txtEmpresaDestinatario = this.state.txtEmpresaDestinatario;
        let txtEmailDestinatario = this.state.txtEmailDestinatario;
        let txtDireccionDestinatario = this.state.txtDireccionDestinatario;
        let txtDepartamentoDestinatario = this.state.txtDepartamentoDestinatario;
        let txtMunicipioDestinatario = this.state.txtMunicipioDestinatario;
        let txtMontoCOD = this.state.txtMontoCOD;
        let txtPeso = this.state.txtPeso;
        let txtTipoEnvio = this.state.txtTipoEnvio;
        let txtDescripcion = this.state.txtDescripcion;
        let txtZonaEntrega = this.state.txtZonaEntrega;
        let txtObservaciones = this.state.txtObservaciones;

        if (txtNombreRemitente == null || txtNombreRemitente.toString().trim().length == 0){
            cogoToast.warn("Nombre del remitente es necesario para continuar");
            return;
        }

        if (txtEmpresaRemitente == null || txtEmpresaRemitente.toString().trim().length == 0){
            cogoToast.warn("Empresa del remitente es necesario para continuar");
            return;
        }

        if (txtEmailRemitente == null || txtEmailRemitente.toString().trim().length == 0){
            cogoToast.warn("Email del remitente es necesario para continuar");
            return;
        }

        if (txtNitRemitente == null || txtNitRemitente.toString().trim().length == 0){
            cogoToast.warn("NIT del remitente es necesario para continuar");
            return;
        }

        if (txtDireccionRecolecta == null || txtDireccionRecolecta.toString().trim().length == 0){
            cogoToast.warn("Dirección de recolecta es necesario para continuar");
            return;
        }

        if (txtDireccionFactura == null || txtDireccionFactura.toString().trim().length == 0){
            cogoToast.warn("Dirección de facturación es necesario para continuar");
            return;
        }

        if (txtDepartamentoRemitente == 0 ||txtDepartamentoRemitente == null ){
            cogoToast.warn("Seleccione un departamento remitente");
            return;
        }

        if (txtMuniRemitente == 0 ||txtMuniRemitente == null ){
            cogoToast.warn("Seleccione un Municipio remitente");
            return;
        }

        if (txtTelefonoRemitente == null || txtTelefonoRemitente.toString().trim().length == 0){
            cogoToast.warn("Teléfono del remitente es necesario para continuar");
            return;
        }

        if (txtNombreDistanatario == null || txtNombreDistanatario.toString().trim().length == 0){
            cogoToast.warn("Nombre del destinatario es necesario para continuar");
            return;
        }

        if (txtContactoDestinatario == null || txtContactoDestinatario.toString().trim().length == 0){
            cogoToast.warn("Contacto del destinatario es necesario para continuar");
            return;
        }

        if (txtEmpresaDestinatario == null || txtEmpresaDestinatario.toString().trim().length == 0){
            cogoToast.warn("Empresa del destinatario es necesario para continuar");
            return;
        }

        if (txtEmailDestinatario == null || txtEmailDestinatario.toString().trim().length == 0){
            cogoToast.warn("Email del destinatario es necesario para continuar");
            return;
        }

        if (txtDireccionDestinatario == null || txtDireccionDestinatario.toString().trim().length == 0){
            cogoToast.warn("Dirección del destinatario es necesario para continuar");
            return;
        }

        if (txtTelefonoDestinatario == null || txtTelefonoDestinatario.toString().trim().length == 0){
            cogoToast.warn("Teléfono del destinatario es necesario para continuar");
            return;
        }

        if (txtDepartamentoDestinatario == 0 ||txtDepartamentoDestinatario == null ){
            cogoToast.warn("Seleccione un departamento destinatario");
            return;
        }

        if (txtMunicipioDestinatario == 0 ||txtMunicipioDestinatario == null ){
            cogoToast.warn("Seleccione un municipio destinatario");
            return;
        }

        if (txtMontoCOD == null || txtMontoCOD.toString().trim().length == 0){
            txtMontoCOD = "0";
        }

        if (txtPeso == null || txtPeso.toString().trim().length == 0){
            cogoToast.warn("Peso aproximado es necesario para continuar");
            return;
        }

        if (txtTipoEnvio == 0 ||txtTipoEnvio == null ){
            cogoToast.warn("Seleccione un tipo de envío");
            return;
        }

        if (txtDescripcion == null || txtDescripcion.toString().trim().length == 0){
            cogoToast.warn("Descripción es necesario para continuar");
            return;
        }
        if (txtZonaEntrega == null ){
            txtZonaEntrega = "1";
            return;
        }

        if (txtObservaciones == null || txtObservaciones.toString().trim().length == 0){
            cogoToast.warn("Observaciones es necesario para continuar");
            return;
        }

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let day = newDate.getDay();

        var today = new Date();
        var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

        var data ={
            seccion: localStorage.getItem('seccion'),
            txt_nombre_remitente:txtNombreRemitente,
            txt_nombre_destino: "GUATEMALA",
            txt_empresa_remitente:txtEmpresaRemitente,
            txt_email_remitente:txtEmailRemitente,
            txt_nit_remitente:txtNitRemitente,
            txt_direccion_remitente: txtDireccionRecolecta,
            txt_direccion_remitente_fiscal: txtDireccionFactura,
            slDepartamento_remitente: txtDepartamentoRemitente,
            slMunicipio_remitente: txtMuniRemitente,
            txt_telefono_remitente: txtTelefonoRemitente,
            txt_zipcode_remitente: "01013",
            txt_nombre_destinatario: txtNombreDistanatario,
            txt_contacto_entrega: txtContactoDestinatario,
            txt_empresa_destinatario: txtEmpresaDestinatario,
            txt_correo_electronico_dest: txtEmailDestinatario,
            txt_telefono_destinatario: txtTelefonoDestinatario,
            slDepartamento: txtDepartamentoDestinatario,
            slMunicipio:txtMunicipioDestinatario,
            txt_direccion_destinatario: txtDireccionDestinatario,
            txt_zip_code_destinatario: "01013",
            txt_montoCod: txtMontoCOD,
            txt_peso: txtPeso,
            slTipo: txtTipoEnvio,
            txt_descripcion: txtDescripcion,
            slPagaEnvio: "Remitente",
            slZonaEntrega: txtZonaEntrega,
            txtObservaciones: txtObservaciones,
            id_pais: "87",
            id_destino:"87",
            fecha_movimiento: day + "/" + month + "/" + year,
            hora_movimiento: time,
        };

        const url_service = `${apiUrl.apiUrl}grabar_courier_local`;

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                if ( response.data.codigo_respuesta == "1" )
                {
                    swal("Courier Local", "Guía generada correctamente.\n No. de guía: " + response.data.no_guia , "success");
                    let tipo = "";
                    if ( txtTipoEnvio == "1" )
                        tipo = "DOCUMENTOS";
                    else
                        tipo = "MUESTRAS";
                    let url = "";
                    url += "scripts/jsImprimirGuiaCLocal.php"
                    url += "?txt_no_guia= " + response.data.no_guia;
                    url += "&nombre=" + txtNombreRemitente ;
                    url += "&nombre_destinatario=" + txtNombreRemitente ;
                    url += "&contacto_entrega=" + txtContactoDestinatario ;
                    url += "&telefono_destinatario=" + txtTelefonoDestinatario ;
                    url += "&direccion_destinatario=" + txtDireccionDestinatario ;
                    url += "&txt_nombre_municipio=" + this.state.txtNombreMunicipio ;
                    url += "&txt_nombre_destino=" + txtNombreDistanatario ;
                    url += "&monto_cod=" + txtMontoCOD ;
                    url += "&txt_contenido=" + tipo ;
                    url += "&zona_entrega=" + txtZonaEntrega ;
                    url += "&observaciones=" + txtObservaciones ;
                    window.open(url);
                    this.setState( {
                        txtNombreRemitente: '',
                        txtEmpresaRemitente: '',
                        txtEmailRemitente: '',
                        txtNitRemitente: '',
                        txtDireccionRecolecta: '',
                        txtDireccionFactura: '',
                        txtDepartamentoRemitente: '0',
                        txtMuniRemitente: '0',
                        txtTelefonoRemitente: '',
                        txtTelefonoDestinatario: '',
                        txtNombreDistanatario: '',
                        txtContactoDestinatario: '',
                        txtEmpresaDestinatario: '',
                        txtEmailDestinatario: '',
                        txtDireccionDestinatario: '',
                        txtDepartamentoDestinatario: '0',
                        txtMunicipioDestinatario: '0',
                        txtMontoCOD: '',
                        txtPeso: '',
                        txtTipoEnvio: '',
                        txtDescripcion: '',
                        txtZonaEntrega: '',
                        txtObservaciones: '',
                    });
                }
                else
                    swal("Courier Local", "No se pudo generar la guía. \n Error: " + response.data.msg_error, "error");

            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });

    }
    componentDidMount() {

        axios.get(`${apiUrl.apiUrl}municipios` , {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({municipios: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Creación de guías courier local
                            <small>
                                Ingresa la informacion para poder realizar una guía de courier local
                                <br/><br/>
                                TERMINOS Y CONDICIONES:
                                <br/><br/>
                                Por favor imprima 2 copias de la guía que el sistema genera, una de ellas deberá entregarsela a nuestro representante y la segunda copia es su constancia de recepción.<br/>
                                Cuando el Piloto llegue a recoger la guía le solicitara una copia de su DPI para poder escanearla y adjuntarla con la guía para el proceso de envío de su Guía de Courier Local<br/>
                                El cobro puede variar por lo siguiente:<br/>
                                a) Área extendida dentro de la ciudad y/o zona roja.<br/>
                                b) Variación en peso y/o medidas.<br/>
                            </small>

                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <h4>DATOS DEL REMITENTE</h4>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtNombreRemitente">Nombre remitente:</label>
                                    <Form.Control value={this.state.txtNombreRemitente} type="text"  placeholder="Nombre remitente" name="txtNombreRemitente" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtEmpresaRemitente">Empresa remitente:</label>
                                    <Form.Control value={this.state.txtEmpresaRemitente} type="text"  placeholder="Empresa remitente" name="txtEmpresaRemitente" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtEmailRemitente">Email remitente:</label>
                                    <Form.Control value={this.state.txtEmailRemitente} type="text"  placeholder="Email remitente" name="txtEmailRemitente" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtNitRemitente">NIT remitente:</label>
                                    <Form.Control value={this.state.txtNitRemitente} type="text"  placeholder="NIT remitente" name="txtNitRemitente" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtDireccionRecolecta">Dirección Recolecta:</label>
                                    <Form.Control value={this.state.txtDireccionRecolecta} type="text"  placeholder="Direccion recolecta" name="txtDireccionRecolecta" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtDireccionFactura">Dirección Facturación:</label>
                                    <Form.Control value={this.state.txtDireccionFactura} type="text"  placeholder="Direccion Factiración" name="txtDireccionFactura" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Departamento Remitente</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtDepartamentoRemitente} name="txtDepartamentoRemitente" onChange={e => this.change_departamento_remitente(e)}>
                                        <option value={0} selected>Seleccione un departamento</option>
                                        <CatDepartmaento></CatDepartmaento>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Municipio Remitente</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtMuniRemitente } name="txtMuniRemitente" onChange={e => this.change(e)}>
                                        <option key={0} value={0}  selected>Seleccione un Municipio</option>
                                        {this.state.tmpMunicipios_remintete.map(opt => (
                                            <option key={opt.id_municipio} value={opt.id_municipio}>{opt.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtTelefonoRemitente">Teléfono remitente:</label>
                                    <Form.Control value={this.state.txtTelefonoRemitente} type="text"  placeholder="Teléfono remitente" name="txtTelefonoRemitente" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <h4>DATOS DEL DESTINATARIO</h4>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtNombreDistanatario">Nombre destinatario:</label>
                                    <Form.Control value={this.state.txtNombreDistanatario} type="text"  placeholder="Nombre Destinatario" name="txtNombreDistanatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtContactoDestinatario">Contacto entrega:</label>
                                    <Form.Control value={this.state.txtContactoDestinatario} type="text"  placeholder="Contacto entrega" name="txtContactoDestinatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtEmpresaDestinatario">Empresa destinatario:</label>
                                    <Form.Control value={this.state.txtEmpresaDestinatario} type="text"  placeholder="Empresa destinatario" name="txtEmpresaDestinatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtEmailDestinatario">Email destinatario:</label>
                                    <Form.Control value={this.state.txtEmailDestinatario} type="text"  placeholder="Email destinatario" name="txtEmailDestinatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtDireccionDestinatario">Dirección destinatario:</label>
                                    <Form.Control value={this.state.txtDireccionDestinatario} type="text"  placeholder="Dirección destinatario" name="txtDireccionDestinatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtTelefonoDestinatario">Dirección destinatario:</label>
                                    <Form.Control value={this.state.txtTelefonoDestinatario} type="text"  placeholder="Teléfono destinatario" name="txtTelefonoDestinatario" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Departamento Destinatario</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtDepartamentoDestinatario} name="txtDepartamentoDestinatario" onChange={e => this.change_departamento_destinatario(e)}>
                                        <option value={0} selected>Seleccione un departamento</option>
                                        <CatDepartmaento></CatDepartmaento>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Municipio Destinatario</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtMunicipioDestinatario } name="txtMunicipioDestinatario" onChange={e => this.change_municipio_destinatario(e)}>
                                        <option key={0} value={0}  selected>Seleccione un Municipio</option>
                                        {this.state.tmpMunicipios_destinatario.map(opt => (
                                            <option key={opt.id_municipio} value={opt.id_municipio}>{opt.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <h4>DATOS DE LA GUIA</h4>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtMontoCOD">Monto COD:</label>
                                    <Form.Control value={this.state.txtMontoCOD} type="number"  placeholder="Monto COD"  name="txtMontoCOD" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtPeso">Peso Aproximado en LBS:</label>
                                    <Form.Control value={this.state.txtPeso} type="number"  placeholder="Peso" name="txtPeso" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Tipo Envío:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtTipoEnvio} name="txtTipoEnvio" onChange={e => this.change(e)}>
                                        <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                        <option key={1} value={1}>DOCUMENTOS</option>
                                        <option key={2} value={2}>MUESTRAS</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtDescripcion">Descripción:</label>
                                    <Form.Control value={this.state.txtDescripcion} type="text"  placeholder="Descripción" name="txtDescripcion" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Zonas:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.txtZonaEntrega} name="txtZonaEntrega" onChange={e => this.change(e)}>
                                        <option key={"1"} value={"1"}>1</option>
                                        <option key={"2"} value={"2"}>2</option>
                                        <option key={"3"} value={"3"}>3</option>
                                        <option key={"4"} value={"4"}>4</option>
                                        <option key={"5"} value={"5"}>5</option>
                                        <option key={"6"} value={"6"}>6</option>
                                        <option key={"7"} value={"7"}>7</option>
                                        <option key={"8"} value={"8"}>8</option>
                                        <option key={"9"} value={"9"}>9</option>
                                        <option key={"10"} value={"10"}>10</option>
                                        <option key={"11"} value={"11"}>11</option>
                                        <option key={"12"} value={"12"}>12</option>
                                        <option key={"13"} value={"13"}>13</option>
                                        <option key={"14"} value={"14"}>14</option>
                                        <option key={"15"} value={"15"}>15</option>
                                        <option key={"16"} value={"16"}>16</option>
                                        <option key={"17"} value={"17"}>17</option>
                                        <option key={"18"} value={"18"}>18</option>
                                        <option key={"19"} value={"19"}>19</option>
                                        <option key={"20"} value={"20"}>20</option>
                                        <option key={"21"} value={"21"}>21</option>
                                        <option key={"22"} value={"22"}>22</option>
                                        <option key={"23"} value={"23"}>23</option>
                                        <option key={"24"} value={"24"}>24</option>
                                        <option key={"25"} value={"25"}>25</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="txtObservaciones">Observaciones:</label>
                                    <Form.Control value={this.state.txtObservaciones} type="text"  placeholder="Observaciones" name="txtObservaciones" onChange={e => this.change(e)}/>
                                </div>
                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Crear Guía</Button>
                                <div className="form-group">
                                    <small>
                                        <br/><br/>
                                        TERMINOS Y CONDICIONES:
                                        <br/><br/>
                                        Por favor imprima 2 copias de la guía que el sistema genera, una de ellas deberá entregarsela a nuestro representante y la segunda copia es su constancia de recepción.<br/>
                                        Cuando el Piloto llegue a recoger la guía le solicitara una copia de su DPI para poder escanearla y adjuntarla con la guía para el proceso de envío de su Guía de Courier Local<br/>
                                        El cobro puede variar por lo siguiente:<br/>
                                        a) Área extendida dentro de la ciudad y/o zona roja.<br/>
                                        b) Variación en peso y/o medidas.<br/>
                                    </small>
                                </div>
                            </form>
                        </div>

                    </div>
                </main>

            </Welcome>
        )
    }
}
export default GuiaLocal;
