import React from "react";
import Welcome from "../master_internacional";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';

class MisPaquetes extends React.Component{

    state = {

    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g text-center" style={{backgroundImage: `url("src/img/servicio_courier_intenacional.jpg")`,height: 190}}>

                    </div>



                </main>
            </Welcome>
        )
    }
}
export default MisPaquetes;
