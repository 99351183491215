import React, {useEffect, useState} from "react";
import Welcome from "../master_pobox";
import {Button, Form, Modal, Alert, InputGroup, Row, Card} from "react-bootstrap";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";
import { FaCreditCard } from 'react-icons/fa';
import "../css/preloader3.css";
import {Link} from "react-router-dom";
import generarDescargo from "../Components/Clases/claseprueba";
import generarfactura from "../Components/Clases/claseFactura";
import generarClienteDireccion from "../Components/Clases/claseTokenCostumer";
import generarRecibo from "../Components/Clases/claseRecibo";
import wsObtenerToken from "../Components/Clases/wsObtenerToken";
import generarTokenTar from "../Components/Clases/claseTokenTar"
import claves from "../Components/Clases/claves"
import { keys } from "lodash";
function Demo1() {
    return null;
}

class DespachoDomicilio extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            paquetes: [],
            paquetes_agregados: [],
            total_pagar: '',
            opciones: [],
            mesTarjeta: [],
            anioTarjeta: [],
            chkGrabarTarjeta: false,
            validated: false,
            setValidated : false,
            tarjetas: [],
            tarjetasImp: [],
            tarjetasApp: [],
            direcciones: [],
            tarjetaReg: '0',
            txtTarjeta: ''
        };
        this.hideComponent = this.hideComponent.bind(this);
        this.setState({ loading: false});
    }

    state = {
        CmbFormaPago: '',
        txtMontoPagar: '',
        TxtDireccion: '',
        total_pagar: '',
        paquetes: [],
        paquetes_agregados: [],
        opciones: [],
        txtNombreTarjeta: '',
        txtFechaVencimientomes: '',
        txtFechaVencimientoanio: '',
        txtCvv: '',
        mesTarjeta: [],
        CmbMesTarjeta: '',
        CmbAnioTarjeta: '',
        CmbDireccion: '',
        CmbToken: '',
        sessionid: Math.random().toString(36).substr(2, 9),
        txtTarjeta: '',
        formColor: '#E5E5E5',
        formColornom: '#E5E5E5',
        formColormes: '#E5E5E5',
        formColoranio: '#E5E5E5',
        formColorcvv: '#E5E5E5',
        tarjetavalida: false,
        isLoading: false,
        CmbTokenNum: '',
        CmbToken: [],
        token: []
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value            
        })
    }

    changetar = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
        var dateObj = new Date();
        switch(e.target.name) {
            case 'txtTarjeta':
                let variable = e.target.value;
                let dummyString = variable;
                dummyString = dummyString.replace('.','');
                if(dummyString.length > 19){
                    dummyString = dummyString.replace(/.$/,"")
                }else if(dummyString.length >= 16){
                    var luhn = this.luhn_validate(dummyString);
                    if(luhn){
                        this.state.tarjetavalida = true;
                        this.setState({formColor: '#2684E7'});
                    }else{
                        this.setState({formColor: '#E72626'});
                        this.state.tarjetavalida = false;
                    }
                }else{
                    this.setState({formColor: '#E72626'});
                    this.state.tarjetavalida = false;
                }
                this.setState({txtTarjeta: dummyString});
                break;
            case 'txtNombreTarjeta':
                if(e.target.value.length == 0){
                    this.setState({formColornom: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColornom: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'CmbMesTarjeta':
                if(e.target.value == null || this.state.CmbAnioTarjeta == null){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                    return;
                }
                if(this.state.CmbAnioTarjeta < dateObj.getUTCFullYear()){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else if((this.state.CmbAnioTarjeta == dateObj.getUTCFullYear() && e.target.value <= dateObj.getUTCMonth())){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColormes: '#2684E7'});
                    this.setState({formColoranio: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'CmbAnioTarjeta':
                if(this.state.CmbMesTarjeta == null || e.target.value == null){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                    return;
                }
                if(e.target.value < dateObj.getUTCFullYear()){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else if((e.target.value == dateObj.getUTCFullYear() && this.state.CmbMesTarjeta <= dateObj.getUTCMonth())){
                    this.setState({formColormes: '#E72626'});
                    this.setState({formColoranio: '#E72626'});
                    this.state.tarjetavalida = false;
                }else{
                    this.setState({formColormes: '#2684E7'});
                    this.setState({formColoranio: '#2684E7'});
                    this.state.tarjetavalida = true;
                }
                break;
            case 'txtCvv':
                if(e.target.value.length > 0 && e.target.value.length < 5){
                    let variablecvv = e.target.value;
                    const regexpSize = /([0-9]+)/;
                    let dummyStringcvv = variablecvv.match(regexpSize);
                    this.setState({txtCvv: ''})
                    if(dummyStringcvv != null) {
                        this.setState({txtCvv: dummyStringcvv[0]})
                    }
                    this.state.tarjetavalida = true;
                    this.setState({formColorcvv: '#2684E7'});
                }else if(e.target.value.length >= 5){
                    let dummyStringcvv = e.target.value;
                    dummyStringcvv = dummyStringcvv.replace(/.$/,"");
                    this.setState({txtCvv: dummyStringcvv});
                }else{
                    this.state.tarjetavalida = false;
                    this.setState({formColorcvv: '#E72626'});
                }
                break;
        }
    }
    luhn_validate(fullcode) {
        return this.luhn_checksum(fullcode) == 0
    }
    luhn_checksum(code) {
        var len = code.length
        var parity = len % 2
        var sum = 0
        for (var i = len-1; i >= 0; i--) {
            var d = parseInt(code.charAt(i))
            if (i % 2 == parity) { d *= 2 }
            if (d > 9) { d -= 9 }
            sum += d
        }
        return sum % 10
    }

    onSubmit = async e => {
        e.preventDefault()
        this.setState({ loading: true});

        let CmbFormaPago = this.state.CmbFormaPago;
        let TxtMontoPagar = this.state.TxtMontoPagar;
        let CmbDireccion = this.state.CmbDireccion;

        var x;
        for (x of this.state.direcciones) {
            if(x.direccion_entrega == CmbDireccion){
                if(x.id_departamento != '1') {
                    cogoToast.warn("Dirección sin cobertura, confirme sus paquetes desde la APP");
                    this.setState({ loading: false});
                    return;
                }
            }
        }

        if (CmbFormaPago == null || CmbFormaPago.toString().trim().length == 0) {
            cogoToast.warn("Seleccione la forma de pago");
            this.setState({ loading: false});
            return;
        }

        if (TxtMontoPagar == null || TxtMontoPagar.toString().trim().length == 0) {
            cogoToast.warn("Debe haber un monto a pagar para continuar");
            this.setState({ loading: false});
            return;
        }

        if (this.state.paquetes_agregados.length == 0) {
            cogoToast.warn("Debe Seleccionar al menos un paquete");
            this.setState({ loading: false});
            return;
        }

        if (CmbFormaPago == 10) {
            var fo = [];
            let cyberFlete;
            var tipodescargo = 0;
            let chkGrabarTarjeta = this.state.chkGrabarTarjeta;
            let txttarjeta = this.state.txtTarjeta;
            let txtNombreTarjeta = this.state.txtNombreTarjeta;
            let txtFechaVencimientoanio = this.state.CmbAnioTarjeta;
            let txtFechaVencimientomes = this.state.CmbMesTarjeta;
            let txtCvv = this.state.txtCvv;
                /*SI ES PRIMERA VEZ SE REALIZARA VALIDACIÓN DE DATOS DE TARJETA*/
                //console.log(this.state.tarjetavalida);
            
            if ((this.state.tarjetaReg == '0' && this.state.tarjetavalida) || (this.state.tarjetaReg == '2' && this.state.tarjetavalida)) {
                if (txttarjeta == null || txttarjeta.toString().trim().length == 0) {
                    cogoToast.warn("Numero de tarjeta vacia");
                    this.setState({ loading: false});
                    return;
                }
                if (txtNombreTarjeta == null || txtNombreTarjeta.toString().trim().length == 0) {
                    cogoToast.warn("Nombre de tarjeta vacia");
                    this.setState({ loading: false});
                    return;
                }
                if (txtFechaVencimientomes == null || txtFechaVencimientomes.toString().trim().length == 0 || txtFechaVencimientomes == 0) {
                    cogoToast.warn("Mes no valido");
                    this.setState({ loading: false});
                    return;
                }
                if (txtFechaVencimientoanio == null || txtFechaVencimientoanio.toString().trim().length == 0 || txtFechaVencimientoanio == 0) {
                    cogoToast.warn("Año no valido");
                    this.setState({ loading: false});
                    return;
                }
                if (txtCvv == null || txtCvv.toString().trim().length == 0) {
                    cogoToast.warn("CVV no valido");
                    this.setState({ loading: false});
                    return;
                }

                tipodescargo = 1;                
            } else if(this.state.tarjetaReg == '1'){
                tipodescargo = 2;
            }else {
                this.setState({ loading: false});
                cogoToast.warn("Datos de tarjeta no completos");
                return;
            }

            var llaves = [];
            llaves.push({id:claves.idw, idc:claves.id_web, key:claves.key_web, secret:claves.secret_web});
            llaves.push({id:claves.idi, idc:claves.id_imp, key:claves.key_imp, secret:claves.secret_imp});


            const found = this.state.tarjetas.find(obj => {
                return (obj.id === this.state.CmbToken && obj.id === this.state.CmbToken);
            });

            const foundimp = this.state.tarjetasImp.find(obj => {
                return (obj._embedded.instrumentIdentifier.card.number === found._embedded.instrumentIdentifier.card.number);
            });

            var fa = [];
            var o = {};
            var key = 'factura';
            o[key] = [];
            var montotot = 0;
            var montoimp = 0;
            var montofle = 0.00;
            var valortotfleimp = 0;
            let bandera = true;

            for (var i = 0, len = this.state.paquetes_agregados.length; i < len; i++) {
                if(this.state.paquetes_agregados[i].es_notacobro == '1'){
                    var factagrega = {
                        factura_numero: this.state.paquetes_agregados[i].factura,
                        factura_serie: this.state.paquetes_agregados[i].serie
                    };
                    o[key].push(factagrega);
                }else if(this.state.paquetes_agregados[i].es_notacobro == '0'){
                    var factdetalle = {
                        detalle:{
                            factura: this.state.paquetes_agregados[i].factura,
                            serie: this.state.paquetes_agregados[i].serie
                        }
                    };
                    fa.push(factdetalle);
                }

                montotot = parseFloat(montotot) + parseFloat(this.state.paquetes_agregados[i].montof);
                montoimp = parseFloat(montoimp) + parseFloat(this.state.paquetes_agregados[i].monton);
            }

            montofle = (parseFloat(montotot) - parseFloat(montoimp));
            //Estas facturas son generadas solo si vienen como nota de cobro de lo contrarios se agrega a documentos ya pagados vease variables factdetalle, fa
            try{
                if(o["factura"].length > 0){
                    let fact;
                    const generarFacturas = new generarfactura(o);
                    fact = await generarFacturas.crearFactura();
                    const prueb = JSON.parse(fact);
                    if(prueb.length > 0){
                        for (var i = 0, len = prueb.length; i < len; i++) {
                            var factdetalle = {
                                detalle:{
                                    factura: prueb[i]['detalle'].factura,
                                    serie: prueb[i]['detalle'].serie
                                }
                            };
                            fa.push(factdetalle);
                        }
                    }else{
                        this.setState({ loading: false});
                        swal("Ayuda", "Ha ocurrido un error con el descargo", "error");
                        return;
                    }
                }
            } catch (error) {
                this.valTarDefault()
                this.recargar_paquetes()
                this.setState({ loading: false});
                swal("Ayuda", "Ha ocurrido un error", "error");
                return;
            }

            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*-------------------------------------------------------------generamos cobro flete-------------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            if(montofle > 0){                
                let generarDescargo2 = new generarDescargo(localStorage.getItem('seccion') + JSON.stringify(this.state.paquetes_agregados[0]['codigo']).replace(/"/g, ''), localStorage.getItem('rtu_nombre'), localStorage.getItem('rtu_apellido'), localStorage.getItem('rtu_direccion'), '01000', 'GUATEMALA', localStorage.getItem('rtu_celular'), localStorage.getItem('email'), montofle, txtFechaVencimientoanio, txttarjeta, txtCvv, txtFechaVencimientomes, tipodescargo, this.state.tarjetas.costumerid, this.state.tarjetas.address, found.id);
                cyberFlete = await generarDescargo2.crearDescargo();
                console.log(cyberFlete);
                if ((cyberFlete.data.status == 'AUTHORIZED' || cyberFlete.data.status == 'ACCEPTED') && parseFloat(cyberFlete.data.orderInformation.amountDetails.authorizedAmount) > 0) {
                    //alert(cyberFlete.status)
                    var factfor = {
                        formapago:{
                            monto: cyberFlete.data.orderInformation.amountDetails.authorizedAmount,
                            banco: '127',
                            documento: cyberFlete.data.processorInformation.systemTraceAuditNumber,
                            tarjeta: txttarjeta.substr(txttarjeta.length-4),
                            autorizacion: cyberFlete.data.processorInformation.approvalCode,
                            forma_pago: '3'
                        }
                    };
                    fo.push(factfor);
                    valortotfleimp = valortotfleimp + parseFloat(cyberFlete.data.orderInformation.amountDetails.authorizedAmount)
                }else{
                    this.valTarDefault()
                    this.recargar_paquetes()
                    this.setState({ loading: false});
                    swal("Ayuda", "Ha ocurrido un error con el descargo1", "error");
                    return;
                }
            }
            return;
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*-------------------------------------------------------------generamos cobro flete-------------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*------------------------------------------------------------generamos cobro impuesto-----------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            let cyberImp;
            if(montoimp > 0){
                let generarDescargoImp = new generarDescargo(localStorage.getItem('seccion') + JSON.stringify(this.state.paquetes_agregados[0]['codigo']).replace(/"/g, ''), localStorage.getItem('rtu_nombre'), localStorage.getItem('rtu_apellido'), localStorage.getItem('rtu_direccion'), '01000', 'GUATEMALA', localStorage.getItem('rtu_celular'), localStorage.getItem('email'), montoimp, txtFechaVencimientoanio, txttarjeta, txtCvv, txtFechaVencimientomes, tipodescargo, this.state.tarjetasImp.costumerid, this.state.tarjetasImp.address, foundimp.id);
                cyberImp = await generarDescargoImp.crearDescargoIMP();
                console.log(cyberImp);
                if ((cyberImp.data.status == 'AUTHORIZED' || cyberImp.data.status == 'ACCEPTED') && parseFloat(cyberImp.data.orderInformation.amountDetails.authorizedAmount) > 0) {
                    var factfor = {
                        formapago:{
                            monto: cyberImp.data.orderInformation.amountDetails.authorizedAmount,
                            banco: '142',
                            documento: cyberImp.data.processorInformation.systemTraceAuditNumber,
                            tarjeta: txttarjeta.substr(txttarjeta.length-4),
                            autorizacion: cyberImp.data.processorInformation.approvalCode,
                            forma_pago: '3'
                        }
                    };

                    valortotfleimp = valortotfleimp + parseFloat(cyberImp.data.orderInformation.amountDetails.authorizedAmount)
                    fo.push(factfor);
                }else{
                    bandera = false;
                    this.valTarDefault()
                    this.recargar_paquetes()
                }
            }
            return;
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*------------------------------------------------------------generamos cobro impuesto-----------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            /*----------------------------------------------------generamos recibo y validamos informacion---------------------------------------------*/
            /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            try {
                /*return;
                var en = []
                var factenc = {
                    encabezado:{
                        seccion: localStorage.getItem("seccion"),
                        entidad: "642",
                        valor:  valortotfleimp
                    }
                };
                en.push(factenc);
                let rect;
                const generarRecibo2 = new generarRecibo(en, fa, fo);
                rect = await generarRecibo2.crearRecibo();
                console.log(rect);
                let rect2;
                rect2 = JSON.parse(rect);

                if(rect2[0].numero_recibo != ""){//rect[0]['numero_recibo']
                    //si no se realizo alguno de los dos descargos (si corresponde, flete o impuesto), pero se genero cualquiera siempre genera recibo pero no confirma paquetes                        
                    if(!bandera){
                        this.valTarDefault()
                        this.recargar_paquetes()
                        this.setState({ loading: false});
                        swal("Ayuda", "Ha ocurrido un error con el descargo 3", "error");
                        return;
                    }else{
                        await this.confirmacion(CmbFormaPago, CmbDireccion);                        
                        if(chkGrabarTarjeta){
                            if(this.state.tarjetaReg == '0'){
                                let costumer;
                                const saveCostumer = new generarClienteDireccion(localStorage.getItem('seccion'), localStorage.getItem('email'), localStorage.getItem('id_cuenta'), localStorage.getItem('rtu_nombre'), localStorage.getItem('rtu_apellido'), localStorage.getItem('rtu_celular'), txttarjeta, txtFechaVencimientomes, txtFechaVencimientoanio, this.state.tarjetaReg);
                                costumer = await saveCostumer.crearClienteWeb();
                            }else if(this.state.tarjetaReg == '2'){
                                let tokens = this.state.token;
                                if(tokens.length > 0){            
                                    for (const property in tokens) {                                            
                                        const llave = llaves.find(obj => {
                                            return (obj.id === tokens[property].id_afiliacion);
                                        });
                                        let tarj;
                                        const saveTarjeta = new generarTokenTar(txttarjeta, txtFechaVencimientomes, txtFechaVencimientoanio, tokens[property].customer, llave.idc, llave.key, llave.secret, txtNombreTarjeta, '', localStorage.getItem('email'), localStorage.getItem('rtu_celular'));
                                        tarj = await saveTarjeta.saveTar();
                                    }
                                }
                            }
                        }
                    }
                }else {                    
                    this.valTarDefault()
                    this.recargar_paquetes()
                    this.setState({ loading: false});
                    swal("Ayuda", "Ha ocurrido un error, contactate con nosotros 4", "error");
                    return;
                }*/
                /*-----------------------------------------------------------------------------------------------------------------------------------------*/
                /*----------------------------------------------------generamos recibo y validamos informacion---------------------------------------------*/
                /*-----------------------------------------------------------------------------------------------------------------------------------------*/
            } catch (error) {
                console.log(error);
                this.valTarDefault()
                this.recargar_paquetes()
                this.setState({ loading: false});
                swal("Ayuda", "Ha ocurrido un error al confirmar los paquetes", "error");
                return;
            }
            
        }else{
            await this.confirmacion(CmbFormaPago, CmbDireccion);
        }
        this.setState({ loading: false});
        this.valTarDefault();
        this.recargar_paquetes();
    }

    confirmacion(CmbFormaPago, CmbDireccion){
        return new Promise((resolve, reject) => {
            console.log(CmbFormaPago);
            let jsonData = "";
            let jsonTmp = "";
            let observaciones = "";
            var respuesta = "";
            jsonData += "[";
            this.state.paquetes_agregados.map((gen) =>
                jsonData += '{"codigo_barra":"' + gen.codigo + '","anio":"' + gen.año + '","monto":"' + (Math.round(gen.montof * 100) / 100).toFixed(2) + '"},'
            )
            jsonTmp = jsonData.substring(0, jsonData.length-1);
            jsonData = jsonTmp;
            jsonData += "]";

            observaciones = "Confirmación realizada desde la WEB";


            var FormData = require('form-data');
            var data = new FormData();
            data.append('seccion', localStorage.getItem('seccion'));
            data.append('jsonPaquetes', jsonData);
            data.append('persona_pagara', localStorage.getItem('nombre'));
            data.append('fomaPago', CmbFormaPago);
            data.append('observaciones', observaciones);
            data.append('direccion_entrega', CmbDireccion);
            data.append('monto', this.state.total_pagar);

            var config = {
                method: 'post',
                url: `${apiUrl.apiUrl}confirmacion_paquetes`,
                headers: {
                    'Userid': 'transexpress',
                    'Pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
                },
                data : data
            };

            var axios = require('axios');
            this.setState({TxtMontoPagar: "",CmbFormaPago: "0"});
            axios(config).then((response) =>{                
                swal("Despacho a Domicilio", "Sus paquetes han sido correctamente confirmados", "success");
                //let p_direccion = localStorage.getItem("direccion_entrega");
                resolve();
                this.setState({TxtMontoPagar: "",CmbFormaPago: "0"});
            },(error) =>{
                reject()
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            }).catch(function (error) {
                reject()
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });
        })
    }

    componentDidMount() {
        if(localStorage.getItem('retenida') == '1'){
            swal("Ayuda", "Para obtener tus paquetes debes contactarnos al WhatsApp o pbx 2286 3800", "error");
            return;
        }
        const d = new Date();
        let year = d.getFullYear();
        let p_seccion = localStorage.getItem("seccion");
        let p_direccion = localStorage.getItem("direccion_entrega");
        this.setState({TxtDireccion: p_direccion});
        //if ( localStorage.getItem("seccion") == "220894" || localStorage.getItem("seccion") == "5368")
        //this.state.opciones.push({ value:"10", label: "Tarjeta" });
        this.state.opciones.push({ value:"12", label: "Pago contra entrega" });


        for (let i = 1; i <= 12; i++) {
            this.state.mesTarjeta.push({ value:i, label: i });
        }
        for (let i = year; i <= (year+5); i++) {
            this.state.anioTarjeta.push({ value:i, label: i });
        }

        //if ( localStorage.getItem("seccion") == "220894"){
            this.obtiene_tarjeta(claves.idw, claves.id_web, claves.key_web, claves.secret_web);
            this.obtiene_tarjeta(claves.idi, claves.id_imp, claves.key_imp, claves.secret_imp);
        //}
        this.obtiene_direccion();
        this.recargar_paquetes();
    }

    hideComponent(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        e.preventDefault();
        let CmbFormaPago = e.target.value;
        //let tarjeta = document.getElementById("divTarjeta");
        let tarjetaReg = document.getElementById("divTarjetaReg");
        //console.log(CmbFormaPago);
        if (CmbFormaPago == 10) {
            if (this.state.tarjetaReg == '0') {
                alert('No tiene una tarjeta registrada')
            } else {
                tarjetaReg.removeAttribute("hidden");
            }
        }else{
            tarjetaReg.setAttribute("hidden", "hidden");
        }
    }

    hideComponentClick(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
        e.preventDefault();
        let tarjeta = document.getElementById("divTarjeta");
        let tarjetaReg = document.getElementById("divTarjetaReg");

        tarjeta.removeAttribute("hidden");
        tarjetaReg.setAttribute("hidden", "hidden");
        this.setState({ tarjetaReg: '2' })
    }

    handleChange(evt) {
        this.setState({ chkGrabarTarjeta: evt.target.checked });
    }

    paquete_seleccionado(data,e)
    {
        let isChecked = e.target.checked;
        if ( isChecked )
        {
            this.state.paquetes_agregados.push(data.gen);
            let total_a_pagar =  this.state.total_pagar * 1 ;
            total_a_pagar = total_a_pagar + ( data.gen.montof * 1 )
            this.state.total_pagar = ( total_a_pagar * 1 ).toFixed(2);
            this.setState({TxtMontoPagar:this.state.total_pagar });
        }
        else
        {
            let codigo = data.gen.codigo;
            let total_a_pagar =  this.state.total_pagar * 1 ;
            total_a_pagar = total_a_pagar - ( data.gen.montof * 1 )
            this.state.total_pagar = ( total_a_pagar * 1 ).toFixed(2);
            const temporal = this.state.paquetes_agregados.filter(paquete => paquete.codigo !== codigo );
            this.state.paquetes_agregados = temporal;
            this.setState({TxtMontoPagar:this.state.total_pagar });
        }
    }

    recargar_paquetes()
    {
        axios.get(`${apiUrl.apiUrl}paquetes_disponibles/` + localStorage.getItem('seccion'), {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            
            for (const property in res.data) {
                //console.log(res.data[property]);
                let a = '';
                a = res.data[property].jsonTotales;
                let b = JSON.parse(a);
                res.data[property].montof = b[0].valor;
                res.data[property].flete = b[0].flete;
                res.data[property].impuesto = b[0].impuesto;
                /*console.log(res.data[property]);
                console.log(b);*/
            }
            this.setState({paquetes: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }
    obtiene_direccion(){
        var config = {
            method: 'get',
            url: 'https://webservices.transexpress.com.gt/api/obtiene_dir_cliente?seccion=' + localStorage.getItem('seccion'),
            headers: {
                'userid': 'transexpress',
                'pwduser': 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S'
            }
        };

        axios(config).then(response => {
            this.setState({direcciones: response.data});
            var obj = response.data;
            var x;
            for (x of obj) {
                if(x.predeterminada == '1'){
                    this.setState({CmbDireccion: x.direccion_entrega});
                }
            }
        }).catch(function (error) {
            swal("Ayuda", error, "error");
        });
    }

    async obtiene_tarjeta(i, id, key, secret){
        let result = '';
        const tokens = new wsObtenerToken();
        const token = await tokens.obtenerTokens();
        this.setState({token: token});

        if(token.length > 0){            

            for (const property in token) {        
                if(i == token[property].id_afiliacion){
                    const axios2 = require('axios');
                    const FormData2 = require('form-data');
                    let data2 = new FormData2();
                    data2.append('customerId', token[property].customer);
                    let config2 = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://users.transexpress.com.gt/apiimpget.php',
                    headers: {
                        'id': id,//'visanetgt_transexpress', 
                        'key': key,//'eb395bb1-8dfa-46eb-af8b-aa28897fdd39', 
                        'secret': secret//'l8xMjp2hoylBbq+4RQUJ08UVd7d5/wGfrX7E+1/yMPU=',
                    },
                    data : data2
                    };
                    axios2.request(config2)
                    .then((response) => {
                        if(i == '1'){
                            //console.log('------------------');
                            response.data._embedded.paymentInstruments.costumerid = token[property].customer;
                            response.data._embedded.paymentInstruments.address = token[property].address;
                            //console.log(response.data._embedded.paymentInstruments);
                            this.setState({tarjetas: response.data._embedded.paymentInstruments});
                            this.setState({tarjetaReg: '1'});
                            this.setState({CmbToken: response.data._embedded.paymentInstruments[0].id});
                            this.setState({CmbTokenNum: response.data._embedded.paymentInstruments[0]._embedded.instrumentIdentifier.card.number});
                        }else if(i == '3'){
                            //console.log('------------------');
                            response.data._embedded.paymentInstruments.costumerid = token[property].customer;
                            response.data._embedded.paymentInstruments.address = token[property].address;
                            //console.log(response.data._embedded.paymentInstruments);
                            this.setState({tarjetasImp: response.data._embedded.paymentInstruments});
                        }
                    })
                    .catch((error) => {
                    console.log(error);
                    });
                }
            }
        }
    }

    valTarDefault(){
        this.setState({txtCvv: ''});
        this.setState({txtTarjeta: ''});
        this.setState({txtNombreTarjeta: ''});
        this.setState({CmbAnioTarjeta: ''});
        this.setState({CmbMesTarjeta: ''});
        this.setState({TxtMontoPagar: "0",CmbFormaPago: "0"});
        this.setState({formColor: '#E5E5E5'});
        this.setState({formColornom: '#E5E5E5'});
        this.setState({formColormes: '#E5E5E5'});
        this.setState({formColoranio: '#E5E5E5'});
        this.setState({formColorcvv: '#E5E5E5'});
        this.setState({paquetes_agregados: []});
        this.setState({paquetes: []});
        this.setState({total_pagar: "0"});
        let tarjeta = document.getElementById("divTarjeta");
        tarjeta.setAttribute("hidden", "hidden");
        this.setState({ loading: false});
    }

    render(){
        //
        const {loading } = this.state;
        /*
        1. Si su confirmación es antes de las11:30 AM sus paquetes se enviaran en la ruta de la tarde el mismo día.<br/>
        2. Si su confirmación es antes de las 4:30 PM sus paquetes se enviaran en la ruta de la mañana del siguiente día.<br/>
        3. Si la dirección no es correcta o la visita no se pudo realizar por error en el dato ingresado, la siguiente entrega tendrá costo.
        */
        return (
            <Welcome>

                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Despacho a Domicilio
                            <small>
                                Seleccion los paquetes que desea sean llevados a su domicilio e ingrese la información requerida<br/><br/>
                                IMPORTANTE:<br/><br/>

                                1. Tus paquetes son entregados en 24 horas hábiles después de confirmado.<br/>
                                2. La primera entrega es de cortesía y no tiene ningún costo.<br/>
                                3. Si es necesario realizar una segunda visita para entregar el mismo paquete debido a razones ajenas a nosotros, se aplicará un cargo por la visita adiciona.<br/>                                
                            </small>
                        </h1>
                    </div>


                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            {this.state.paquetes.map((gen) =>
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <img src="src/img/ICONO-PAQUETE.png" width="100%"/>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type={'checkbox'}
                                                            id={gen.codigo}
                                                            label=""
                                                            onChange = {e => this.paquete_seleccionado({gen},e)}
                                                        />
                                                        <h3><b>GUA{gen.codigo}</b></h3>
                                                        <h5><b>CONTENIDO:</b> {gen.remitente}</h5>
                                                        <h5><b>REMITENTE:</b> {gen.peso}</h5>
                                                        <h5><b>PESO:</b> {gen.peso}</h5>
                                                        <h5><b>CONSIGNATARIO:</b> {gen.consignatario}</h5>
                                                        <h5><b>MONTO A PAGAR:</b> {(Math.round( gen.montof * 100) / 100).toFixed(2)}</h5>
                                                        <h5><b>FECHA:</b> {gen.fechaDisponible}</h5>
                                                    </div>
                                                    <div className="col-sm-2">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Monto a Pagar</label>
                                    <Form.Control value={this.state.TxtMontoPagar} type="number" step="0.01" placeholder="Monto a pagar" name="TxtMontoPagar" onChange={e => this.change(e)} readOnly/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Seleccione una dirección registrada</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="1" key={this.state.CmbDireccion} value={this.state.CmbDireccion} name="CmbDireccion" onChange={e => this.change(e)}>
                                        {this.state.direcciones.map(opt => (
                                            <option key={opt.id_entrega} value={opt.direccion_entrega}>{opt.direccion_entrega}</option>
                                        ))}
                                    </Form.Control>
                                    <Link to="/Direcciones">Ver mis direcciones</Link>

                                </div>


                                
                                

                                <div className="form-group">
                                    <label className="form-label">Forma de Pago</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.CmbFormaPago} name="CmbFormaPago" onChange={e => this.hideComponent(e, "1")}>
                                        <option key={0} value={0} selected>Seleccione una forma de pago</option>
                                        {this.state.opciones.map(opt => (
                                            <option key={opt.value} value={opt.value}>{opt.label}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group" hidden="hidden" id="divTarjetaReg">
                                    <label className="form-label">Seleccione una tarjeta</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue={this.state.CmbTokenNum} value={this.state.CmbToken} name ="CmbToken" onChange={e => this.change(e)}>
                                        {this.state.tarjetas.map(opt => (
                                            <option value={opt.id}>{opt._embedded.instrumentIdentifier.card.number}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                
                                <Button id="envioinfo" variant="primary" block disabled={loading} onClick = {e => this.onSubmit(e)} >{loading ? 'Cargando…' : 'Enviar Información'}</Button>
                            </form>
                        </div>
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default DespachoDomicilio;
