import React from "react";
import Welcome from "../master";
import {Button, Form} from "react-bootstrap";
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";


class Ayuda extends React.Component{
    state = {
        CmbTipoAyuda: '',
        TxtEmail: '',
        TxtTelefono: '',
        TxtConsulta: ''
    }

    openWhatsApp = e =>
    {
        e.preventDefault();
        let TxtEmail = this.state.TxtEmail;
        let TxtTelefono = this.state.TxtTelefono;
        let TxtConsulta = this.state.TxtConsulta;

        if (TxtEmail == null || TxtEmail.toString().trim().length == 0){
            cogoToast.warn("El email es necesario para continuar");
            return;
        }
        if (TxtTelefono == null || TxtTelefono.toString().trim().length == 0){
            cogoToast.warn("El telefono es necesario para continuar");
            return;
        }
        if (TxtConsulta == null || TxtConsulta.toString().trim().length == 0){
            cogoToast.warn("La consulta es necesaria para continuar");
            return;
        }

        let number = '+502-59382295';
        let message =  "Su correo: " +TxtEmail + "\n" ;
        message +=  "Su Teléfono: " +TxtTelefono + "\n" ;
        message +=  "Su Consulta: " +TxtConsulta + "\n" ;
        const URL = 'https://wa.me';
        number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
        let url = `${URL}/${number}`;
        url += `?text=${encodeURI(message)}`;
        window.open(url);

    };

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();
        const url_service = `${apiUrl.apiUrl}envio_consulta`;

        let CmbTipoAyuda = this.state.CmbTipoAyuda;
        let TxtEmail = this.state.TxtEmail;
        let TxtTelefono = this.state.TxtTelefono;
        let TxtConsulta = this.state.TxtConsulta;


        if (CmbTipoAyuda == null || CmbTipoAyuda.toString().trim().length == 0){
            cogoToast.warn("El tipo de ayuda es necesario para continuar");
            return;
        }
        if (TxtEmail == null || TxtEmail.toString().trim().length == 0){
            cogoToast.warn("El email es necesario para continuar");
            return;
        }
        if (TxtTelefono == null || TxtTelefono.toString().trim().length == 0){
            cogoToast.warn("El telefono es necesario para continuar");
            return;
        }
        if (TxtConsulta == null || TxtConsulta.toString().trim().length == 0){
            cogoToast.warn("La consulta es necesaria para continuar");
            return;
        }

        var data ={
            seccion: localStorage.getItem('seccion'),
            pin: localStorage.getItem('pin'),
            correo: TxtEmail,
            telefono: TxtTelefono,
            detalle: TxtConsulta,
            tipo: CmbTipoAyuda

        };

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                let respuesta = response.data[0].enviado;
                if (respuesta == "1"){
                    swal("Ayuda", "Se ha enviado tu mensaje exitosamente", "success");

                    this.setState({
                        CmbTipoAyuda: '',
                        TxtEmail: localStorage.getItem('email'),
                        TxtTelefono: '',
                        TxtConsulta: ''
                    })
                }else{
                    swal("Ayuda", "Ha ocurrido un error, contactate con el administrador", "error");
                }
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });

    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Solicitud de soporte
                            <small>
                                Ingresa la informacion requerida para poder solicitar soporte
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">TIPO AYUDA:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.CmbTipoAyuda} name="CmbTipoAyuda" onChange={e => this.change(e)}>
                                        <option selected>Seleccione un valor</option>
                                        <option value={"PB"}>POBOX</option>
                                        <option value={"CI"}>Courier Internacional</option>
                                        <option value={"CL"}>Courier Local</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">EMAIL:</label>
                                    <Form.Control value={this.state.TxtEmail}  type="text" placeholder="Ingrese el email" name="TxtEmail" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">TELEFONO:</label>
                                    <Form.Control value={this.state.TxtTelefono}  type="text" placeholder="Ingrese tu telefono" name="TxtTelefono" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">CONSULTA:</label>
                                    <Form.Control value={this.state.TxtConsulta}  type="text" as="textarea" row={5} placeholder="Ingresa tu consulta" name="TxtConsulta" onChange={e => this.change(e)}/>
                                </div>

                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Enviar</Button>
                                <Button variant="success" block onClick = {e => this.openWhatsApp(e)} >Whatsapp</Button>
                            </form>
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default Ayuda;
//
// SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]Se debe especificar al menos uno de los parámetros siguientes. '@recipients, @copy_recipients, @blind_copy_recipients'. (SQL: EXEC SP_CORREO_SOPORTE 5363,2828107,jgarcia@genesisempresarial.com,40282551,Ninguna,1)
