import React from 'react';
import apiUrl from '../enviroments/enviroment';
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import cogoToast from 'cogo-toast';
import {Link} from "react-router-dom";
import swal from "sweetalert";


class Registrate extends React.Component {
    state = {
        TxtNombreCliente: '',
        TxtUsuario: '',
        TxtEmail: '',
        Txtseccion: '',
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        var  PNombreCliente =  this.state.TxtNombreCliente;
        var  PUsuario =  this.state.TxtUsuario;
        var  PEmail =  this.state.TxtEmail;
        var  PSeccion =  this.state.Txtseccion;

        const url_service = `${apiUrl.apiUrl}add_user`;
        var respuesta = false;

        if (PNombreCliente == null || PNombreCliente.toString().trim().length == 0){
            cogoToast.warn("El nombre del cliente es necesario para poder continuar");
            return;
        }

        if (PUsuario == null || PUsuario.toString().trim().length == 0){
            cogoToast.warn("El usuario es necesario para poder continuar");
            return;
        }

        if (PEmail == null || PEmail.toString().trim().length == 0){
            cogoToast.warn("El email es necesario para poder continuar");
            return;
        }

        if (PSeccion == null || PSeccion.toString().trim().length == 0){
            cogoToast.warn("La sección es necesaria para poder continuar");
            return;
        }

        var data ={
            nombre: PNombreCliente,
            usuario: PUsuario,
            email: PEmail,
            seccion: PSeccion,
        };

        axios.post(url_service, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                console.log(response.data);
                 respuesta = response.data[0].resultado;

                 console.log(respuesta);

                 if(respuesta == "1"){
                     swal("Registro de Usuario", "Tu usuario ha sido creado exitosamente, ya puedes ingresar", "success");
                     return;
                 }else if(respuesta == "0"){

                     swal({
                         title: "Información",
                         text: "Usuario \"" + PUsuario + "\" ya existe. No puede registrarlo más de una vez",
                         icon: "error",
                         button: "Ir a Whatsapp por ayuda",
                     })
                         .then((value) => {
                             let number = '+502-54939039';
                             let message =  "Usuario \"" + PUsuario + "\" ya existe. No puede registrarlo más de una vez" ;
                             const URL = 'https://wa.me';
                             number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                             let url = `${URL}/${number}`;
                             url += `?text=${encodeURI(message)}`;
                             window.open(url);
                         });
                 }else if(respuesta == "2"){
                     swal({
                         title: "Información",
                         text: "Cuenta de correo \"" + PEmail + "\" ya existe. No puede registrarlo más de una vez",
                         icon: "error",
                         button: "Ir a Whatsapp por ayuda",
                     })
                         .then((value) => {
                             let number = '+502-54939039';
                             let message =  "Cuenta de correo \"" + PEmail + "\" ya existe. No puede registrarlo más de una vez" ;
                             const URL = 'https://wa.me';
                             number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                             let url = `${URL}/${number}`;
                             url += `?text=${encodeURI(message)}`;
                             window.open(url);
                         });
                 }else if(respuesta == "3")
                 {
                     swal({
                         title: "Información",
                         text: "Cuenta de correo y sección no registradas en el Sistema Transexpress..",
                         icon: "error",
                         button: "Ir a Whatsapp por ayuda",
                     })
                         .then((value) => {
                             let number = '+502-54939039';
                             let message =  "Cuenta de correo y sección no registradas en el Sistema Transexpress.." ;
                             const URL = 'https://wa.me';
                             number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                             let url = `${URL}/${number}`;
                             url += `?text=${encodeURI(message)}`;
                             window.open(url);
                         });
                 }else{
                     swal({
                         title: "Información",
                         text: "Se produjo un error al crear la cuenta..",
                         icon: "error",
                         button: "Ir a Whatsapp por ayuda",
                     })
                         .then((value) => {
                             let number = '+502-54939039';
                             let message =  "Se produjo un error al crear la cuenta.." ;
                             const URL = 'https://wa.me';
                             number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                             let url = `${URL}/${number}`;
                             url += `?text=${encodeURI(message)}`;
                             window.open(url);
                         });
                 }

            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });

    }


    render() {
        return  <div>
                        <div>
                            <div className="page-wrapper auth">
                                <div className="page-inner bg-brand-gradient">
                                    <div className="page-content-wrapper bg-transparent m-0">
                                        <div className="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                                            <div className="d-flex align-items-center container p-0">
                                                        <img src={"../src/img/logos/ICONO-TE.png"}  width="200" aria-roledescription="logo" />
                                                <span className="text-white opacity-50 ml-auto mr-2 hidden-sm-down">
                                                  Ya estas registrado?
                                                </span>
                                                <Link to={`/Login`}>
                                                    <a href="#" className="btn-link text-white ml-auto ml-sm-0">
                                                        Ingresar a Plataforma
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex-1" style={{background: 'url(img/svg/pattern-1.svg) no-repeat center bottom fixed', backgroundSize: 'cover'}}>
                                            <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <h2 className="fs-xxl fw-500 mt-4 text-white text-center">
                                                            Registrate para tener acceso a nuestra plataforma
                                                        </h2>
                                                        <br/>
                                                    </div>
                                                    <div className="col-xl-6 ml-auto mr-auto">
                                                        <div className="card p-4 rounded-plus bg-faded">
                                                            <form id="js-login" noValidate action="intel_analytics_dashboard.html">

                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="emailverify">Nombre Cliente</label>
                                                                    <Form.Control value={this.state.TxtNombreCliente}  className="form-control form-control-lg" type="text" placeholder="Ingrese su nombre" name="TxtNombreCliente" onChange={e => this.change(e)}/>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="emailverify">Usuario</label>
                                                                    <Form.Control value={this.state.TxtUsuario}  className="form-control form-control-lg" type="text" placeholder="Ingrese su usuario" name="TxtUsuario" onChange={e => this.change(e)}/>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="emailverify">Email</label>
                                                                    <Form.Control value={this.state.TxtEmail}  className="form-control form-control-lg" type="email" placeholder="Ingrese su email" name="TxtEmail" onChange={e => this.change(e)}/>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label className="form-label" htmlFor="emailverify">Sección</label>
                                                                    <Form.Control value={this.state.Txtseccion}  className="form-control form-control-lg" type="number" placeholder="Ingrese su sección" name="Txtseccion" onChange={e => this.change(e)}/>
                                                                </div>

                                                                <div className="row no-gutters">
                                                                    <div className="col-md-4 ml-auto text-right">
                                                                        <Button className={"btn btn-info btn-block btn-lg"} onClick = {e => this.onSubmit(e)} >Registrate</Button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                                2021 © Transexpress
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

        </div>

    }
}

export default Registrate;
