

export default [
    {
        path:"/CotizacionLocal",
        name: "Cotización",
        type: "option",
        icono: "fal fa-newspaper"
    },
    {
        path:"/RecolectaLocal",
        name: "Solicitud de Recolecta",
        type: "option",
        icono: "fal fa-clipboard-list-check"
    },
    {
        path:"/GuiaLocal",
        name: "Creación de Guias",
        type: "option",
        icono: "fal fa-clipboard-list-check"
    },
    {
        path:"/CourierLocal",
        name: "Seguimiento por fechas",
        type: "option",
        icono: "fal fa-calendar-day"
    },
    {
        path:"/CourierLocalGuia",
        name: "Seguimiento por guía",
        type: "option",
        icono: "fal fa-box-open"
    },
    {
        path:"/AyudaLoc",
        name: "Ayuda",
        type: "option",
        icono: "fal fa-info-circle"
    },
    {
        path: "/Inicio",
        name: "Volver menu principal",
        type: "option",
        icono: "fal fa-shield-alt"
    }

]
