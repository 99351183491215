import React from "react";
import Welcome from "../master_internacional";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';
import swal from "sweetalert";

class FuelCharge extends React.Component{

    state = {
        contactos: []
    };

    constructor(props) {
        super(props);
        this.state = {
            contactos: []
        }
    }

    componentDidMount() {
        axios.get(`${apiUrl.apiUrl}fuelcharge`, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            console.log(res.data[0]);
            this.setState({contactos: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> FUELCHARGE
                            <small>
                                Tabla de FuelCharge
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            {this.state.contactos.map((gen) =>
                                <div className="col-sm-12 row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <img src="src/img/ICONO-COTIZACION.png" width="100%"/>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <h5><b>PORCENTAJE:</b> {gen.porcentaje}</h5>
                                                        <h5><b>FECHA INICIO:</b> {gen.fecha_inicio}</h5>
                                                        <h5><b>FECHA FIN:</b> {gen.fecha_fin}</h5>
                                                    </div>
                                                    <div className="col-sm-2">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            )}
                        </div>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default FuelCharge;
