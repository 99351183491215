import React from "react";
import Welcome from "../master_pobox";
import apiUrl from "../enviroments/enviroment";
import axios from 'axios';
import {Button, Form} from "react-bootstrap";
import { DateTime } from "luxon";
import swal from "sweetalert";
import Table from 'react-bootstrap/Table';

class EstadoCuenta extends React.Component{

    state = {
        EstadoCuenta: [],
        TxtFechaInicial: '',
        TxtFechaFinal: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            EstadoCuenta: []
        }
    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        let TxtFechaInicial = this.state.TxtFechaInicial;
        let TxtFechaFinal = this.state.TxtFechaFinal;
        let Seccion = localStorage.getItem('seccion');

        let startDate = DateTime.fromFormat(TxtFechaInicial, "yyyy-MM-dd");
        let newStartDate = startDate.c.year.toString() + ('00' + startDate.c.month.toString()).slice(-2) + ('00' + startDate.c.day.toString()).slice(-2);

        let finishDate = DateTime.fromFormat(TxtFechaFinal, "yyyy-MM-dd");
        let newFinishDate = finishDate.c.year.toString() + ('00' + finishDate.c.month.toString()).slice(-2) + ('00' + finishDate.c.day.toString()).slice(-2);

        this.peticion(Seccion,newStartDate,newFinishDate);

    }

    peticion(seccion, fchin, fchfn){
        axios.get(apiUrl.apiUrl+ "facturas/" + seccion + "/" + fchin + "/" + fchfn, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({EstadoCuenta: res.data});
        },(error) => {
            swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
        });
    }

    /*
    * <div className="panel-content">
                            {this.state.EstadoCuenta.map((gen) =>
                                    <div className="col-sm-12 row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <img src="src/img/ICONO-GUARDAR.png" width="100%"/>
                                                </div>
                                                <div className="col-sm-8">
                                                    <h3><b>{gen.serie_fel} - {gen.factura_fel}</b></h3>
                                                    <br/>
                                                    <h5><b>FECHA:</b> {gen.fecha}</h5>
                                                    <h5><b>CLIENTE:</b> {gen.nombre_cliente}</h5>
                                                    <h5><b>NIT:</b> {gen.nit_cliente}</h5>
                                                    <h5><b>SERIE-NO:</b> {gen.serie_fel} - {gen.factura_fel} </h5>
                                                    <h5><b>VALOR: Q.</b> { (gen.valor * 1 ).toLocaleString('en-US', {maximumFractionDigits:2}) }</h5>
                                                    <h5><b>SALDO: Q.</b> {(gen.saldo* 1 ).toLocaleString('en-US', {maximumFractionDigits:2}) }</h5>
                                                    { gen.url_pdf != "" &&
                                                        <h5><a href={gen.url_pdf} target="_blank" rel="noreferrer">Ver factura</a> </h5>
                                                    }
                                                </div>
                                                <div className="col-sm-2">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                               )}
                        </div>
    * */

    /*
    * <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> ESTADO DE CUENTA
                            <small>
                                Ingresa las fechas a realizar la busqueda
                            </small>
                        </h1>
                    </div>
    * */

    render(){
        return (
            <Welcome>


                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader" >
                        <h1 className="subheader-block"  >
                            <i className="fal fa-info-circle"/>
                            <label className="label letras">
                                ESTADO DE CUENTA
                            </label>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="col-sm-12 row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <label className="label other">
                                                    <i className="fal fa-file-invoice fa-6x"/>
                                                </label>
                                            </div>
                                            <div className="col-sm-4">
                                                    <label className="col-xl-12 form-label" htmlFor="fname">Fecha Inicial:</label>
                                                    <Form.Control value={this.state.TxtFechaInicial}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaInicial" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-4">
                                                <label className="col-xl-12 form-label" htmlFor="fname">Fecha Final:</label>
                                                <Form.Control value={this.state.TxtFechaFinal}  className="form-control form-control-lg" type="date" placeholder="Ingrese nombres del titular" name="TxtFechaFinal" onChange={e => this.change(e)}/>
                                            </div>
                                            <div className="col-sm-2">
                                                <Button onClick = {e => this.onSubmit(e)} >Busqueda</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <br/>
                        <br/>

                        <Table responsive striped bordered hover size="sm">
                            <thead >
                                <tr class="bg-primary text-white">
                                    <th>Factura</th>
                                    <th>Fecha</th>
                                    <th>Nombre factura</th>
                                    <th>Nit factura</th>

                                    <th>Valor Factura</th>
                                    <th>Saldo pendiente</th>
                                    <th>Factura</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.EstadoCuenta.map((gen) =>
                                    <tr>
                                        <td>{gen.serie_fel} - {gen.factura_fel}</td>
                                        <td>{gen.fecha}</td>
                                        <td>{gen.nombre_cliente}</td>
                                        <td>{gen.nit_cliente}</td>

                                        <td>Q.{ (gen.valor * 1 ).toLocaleString('en-US', {maximumFractionDigits:2}) }</td>
                                        <td>Q.{(gen.saldo* 1 ).toLocaleString('en-US', {maximumFractionDigits:2}) }</td>
                                        <td>{ gen.url_pdf != "" &&
                                        <h5><a href={gen.url_pdf} target="_blank" rel="noreferrer">Ver factura</a> </h5>
                                        }</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                    </div>
                </main>
            </Welcome>
        )
    }
}
export default EstadoCuenta;
