import React from "react";
import Welcome from "../master_internacional";
import {Button, Form, Modal, Alert} from "react-bootstrap";
import CatPais from "../Components/Servicios/CatPais"
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class SolicitudSuministros extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            txtCantidad: '',
            txtObservaciones: '',
            cmbTipoSuministro: '',
        }
    }
    state = {
        cmbTipoSuministro: '',
        txtCantidad: '',
        txtObservaciones: '',
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = e => {
        e.preventDefault();

        let cmbTipoSuministro = this.state.cmbTipoSuministro;
        let txtCantidad = this.state.txtCantidad;
        let txtObservaciones = this.state.txtObservaciones;

        if (cmbTipoSuministro == 0 ||cmbTipoSuministro == null ){
            cogoToast.warn("Seleccione un tipo de envio");
            return;
        }

        if (txtCantidad == null || txtCantidad.toString().trim().length == 0){
            cogoToast.warn("La Cantidad es necesario para continuar");
            return;
        }

        if (txtObservaciones == null || txtObservaciones.toString().trim().length == 0){
            cogoToast.warn("Las observaciones son necesario para continuar");
            return;
        }

        var data ={
            seccion: localStorage.getItem('seccion'),
            id_suministro: cmbTipoSuministro,
            cantidad: txtCantidad,
            observaciones: txtObservaciones
        };
        let url = apiUrl.apiUrl + "solicitar_suministros";


        axios.post(url, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                swal("Solicitud de suministros", "SOLICITUD ENVIADA EXITOSAMENTE", "success");
                this.setState({ txtCantidad: "",txtObservaciones: "",cmbTipoSuministro: "0"});
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });



    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Solicitud de Suministros
                            <small>
                                Ingrese la información necesaria para la solicitud
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">Tipo de suministro:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbTipoSuministro} name="cmbTipoSuministro" onChange={e => this.change(e)}>
                                        <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                        <option key={1} value={1}>GUIAS COURIER</option>
                                        <option key={2} value={2}>SOBRES COURIER</option>
                                        <option key={3} value={3}>BOLSAS COURIER</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtPeso">Cantidad Solicitada:</label>
                                    <Form.Control value={this.state.txtCantidad} type="number"  placeholder="Ingrese la cantidad solicitada" name="txtCantidad" onChange={e => this.change(e)}/>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtLargo">Observaciones:</label>
                                    <Form.Control value={this.state.txtObservaciones} type="text"  placeholder="Observaciones" name="txtObservaciones" onChange={e => this.change(e)}/>
                                </div>

                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Enviar Solicitud</Button>
                            </form>
                        </div>

                    </div>
                </main>




            </Welcome>
        )
    }
}
export default SolicitudSuministros;
