import React from "react";
import Welcome from "../master_pobox";
import {Button, Form, Modal, Alert} from "react-bootstrap";
import CatArancel from "../Components/Servicios/CatArancel"
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class Cotizacion extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            ModalCotizacion: false,
            cotizacion: [],
            txtMPeso: '',
            txtMFact: '',
        }
    }

    state = {
        CmbAranceles: '',
        TxtPeso: '',
        TxtValFact: ''

    }

    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = e => {
        e.preventDefault();

        let CmbAranceles = this.state.CmbAranceles;
        let TxtPeso = this.state.TxtPeso;
        let TxtValFact = this.state.TxtValFact;
        this.state.txtMPeso = this.state.TxtPeso;
        this.state.txtMFact = this.state.TxtValFact;


        if (CmbAranceles == null || CmbAranceles.toString().trim().length == 0){
            cogoToast.warn("Los aranceles son necesarios para continuar");
            return;
        }

        if (TxtPeso == null || TxtPeso.toString().trim().length == 0){
            cogoToast.warn("El peso es necesario para continuar");
            return;
        }

        if (TxtValFact == null || TxtValFact.toString().trim().length == 0){
            cogoToast.warn("El valor de la factura es necesaria para continuar");
            return;
        }

        let peso = ( this.state.TxtPeso * 1);
        if ( peso > 79.9 )
        {
            swal({
                title: "Información",
                text: "Por requisitos especiales de importación en mi cotización favor cotizar al 502-54939039",
                icon: "error",
                button: "Ir a Whatsapp",
            })
                .then((value) => {
                    let number = '+502-54939039';
                    let message =  "Por requisitos especiales de importación en mi cotización favor cotizar al 502-54939039" ;
                    const URL = 'https://wa.me';
                    number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                    let url = `${URL}/${number}`;
                    url += `?text=${encodeURI(message)}`;
                    window.open(url);
                });
            return;
        }

        var data ={
            seccion: localStorage.getItem('seccion'),
            direccion: CmbAranceles,
            telefono: TxtPeso,
            nombre_entrega: TxtValFact
        };

        let url = apiUrl.apiUrl + "cotizacion_con_seccion/" + localStorage.getItem('seccion') + "/" + TxtValFact + "/" + TxtPeso + "/" + CmbAranceles;

        axios.get(url, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then((response) => {

                let data;

                data = response.data[0];

                let gastos_mercaderia = data.gastos_de_mercaderia * 1;
                let gastos_flete = data.gastos_flete * 1 ;
                let seguro = data.seguro * 1;
                let tipo_cambio = data.tipo_cambio * 1 ;
                let cif = (gastos_mercaderia + gastos_flete + seguro) / tipo_cambio;

                if ( cif > 1000 )
                {
                    swal({
                        title: "Información",
                        text: "Por requisitos especiales de importación en mi cotización favor cotizar al 502-54939039",
                        icon: "error",
                        button: "Ir a Whatsapp",
                    })
                        .then((value) => {
                            let number = '+502-54939039';
                            let message =  "Por requisitos especiales de importación en mi cotización favor cotizar al 502-54939039" ;
                            const URL = 'https://wa.me';
                            number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');
                            let url = `${URL}/${number}`;
                            url += `?text=${encodeURI(message)}`;
                            window.open(url);
                        });
                    return;
                }

               this.setState({
                   cotizacion: response.data[0],
                   CmbAranceles: '',
                   TxtPeso: '',
                   TxtValFact: '',
               })

                this.setState({
                    ModalCotizacion: true
                })

            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });



    }


    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content" style={{backgroundColor: "#000e2e"}}>
                    <div className="subheader" >
                        <h1 className="subheader-block"  >
                            <i className="fal fa-info-circle"/>
                            <label className="label letras">
                                Cotización
                            </label>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">Aranceles</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.CmbAranceles} name="CmbAranceles" onChange={e => this.change(e)}>
                                        <option selected>Seleccione un tipo mercader&iacute;a</option>
                                        <CatArancel></CatArancel>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Peso de la Mercaderia</label>
                                    <Form.Control value={this.state.TxtPeso} type="number" step="0.01" placeholder="Ingrese el peso" name="TxtPeso" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="example-email-2">Valor de la Factura ($)</label>
                                    <Form.Control value={this.state.TxtValFact} type="number" step="0.01" placeholder="Ingrese el valor de la factura" name="TxtValFact" onChange={e => this.change(e)}/>
                                </div>

                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Calcular Compra</Button>
                            </form>
                        </div>

                    </div>
                </main>


                <Modal show={this.state.ModalCotizacion} onHide={() => this.setState({ ModalCotizacion: false })} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Alert >
                            <img src="src/img/logo_transexpress.png" width="75%" class="center"/>
                            <Alert.Heading><label className="label tran"><h1><b>Datos de su Cotización</b></h1></label></Alert.Heading>
                            <hr/>

                            <table>
                                <tr>
                                    <td style={{backgroundColor: "#589002"}}><h4><b>Valor de Mercadería: </b></h4></td>
                                    <td style={{backgroundColor: "#589002"}}><h4>Q. {(Math.round(this.state.cotizacion.gastos_de_mercaderia * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Gastos de Flete: </b></h4></td>
                                    <td><h4>Q. {(Math.round(this.state.cotizacion.gastos_flete * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Impuestos en Guatemala: </b></h4></td>
                                    <td><h4>Q. {(Math.round(this.state.cotizacion.impuestos_guatemala * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Gestión y Desconsolidación: </b></h4></td>
                                    <td><h4>Q. {(Math.round(this.state.cotizacion.gestion * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Resguardo:</b></h4></td>
                                    <td><h4>Q. {(Math.round(this.state.cotizacion.resguardo * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: "#9b9b9b"}}><h4><b>Gastos de importación: </b></h4></td>
                                    <td style={{backgroundColor: "#9b9b9b"}}><h4>Q. {((Math.round(this.state.cotizacion.gastos_flete * 100) / 100) + (Math.round(this.state.cotizacion.impuestos_guatemala * 100) / 100) +  (Math.round(this.state.cotizacion.gestion * 100) / 100) +  (Math.round(this.state.cotizacion.resguardo * 100) / 100)).toFixed(2)  }</h4></td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: "#2196F3"}}><h4><b>Total:</b></h4></td>
                                    <td style={{backgroundColor: "#2196F3"}}><h4>Q. {(Math.round(this.state.cotizacion.total * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                            </table>

                            <label className="label tran">
                                <h4><b>Información Adicional</b></h4>
                            </label>

                            <table>
                                <tr>
                                    <td><h4><b>Peso Cotizado: </b></h4></td>
                                    <td><h4>Lbs. {this.state.txtMPeso}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Valor de la Mercaderia: </b></h4></td>
                                    <td><h4>$. {this.state.txtMFact}</h4></td>
                                </tr>
                            </table>

                            <label className="label dangerletra">
                                <b><h4>IMPORTANTE:</h4></b>
                            </label><br/>
                            <h4><b>* Los gastos de importación pueden variar si el peso o el costo del producto es diferente al declarado o el Vista de Aduna le define otro valor.<br/><br/>* El cliente confirma el tipo, número, color, talla, modelo, funcionalidad, estado empaque y originalidad.</b></h4>
                        </Alert>


                    </Modal.Body>
                </Modal>

            </Welcome>
        )
    }
}
export default Cotizacion;
