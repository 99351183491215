import keys from "./claves";

export default class generarClienteDireccion{
    constructor(carNumber, expirationMonth, expirationYear, costumerid, id, key, secret, firstName, lastName, email, phoneNumber){
        this.carNumber = carNumber;
        this.expirationMonth = expirationMonth;
        this.expirationYear = expirationYear; 
        this.costumerid = costumerid;
        this.id = id;
        this.key = key;
        this.secret = secret;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phoneNumber = phoneNumber;
    }

    saveTar(){
        return new Promise((resolve) =>{
            const superagent = require('superagent');
            var request = superagent('post', 'https://users.transexpress.com.gt/savet.php');

            var headerParams = {};
            headerParams['id'] = this.id;
            headerParams['key'] = this.key;
            headerParams['secret'] = this.secret;
            request.set(this.normalizeParams(headerParams));
            request.timeout(60000);
            request.field('costumerid', this.costumerid);
            request.field('instrument', this.jsonInstrument());
            request.field('payment_instrument', this.jsonPaymentInstrument());
            //return;

            var dataresponse;
            console.log('------------------------')
            console.log(this.id);
            console.log(this.key);
            console.log(this.secret);
            console.log(this.costumerid);
            console.log(this.jsonInstrument());
            console.log(this.jsonPaymentInstrument());
            console.log('------------------------')
            //return;
            request.end(function(error, response) {
                /*console.log(error);
                console.log(response);*/
                var data = response.body;
                if (data == null || (typeof data === 'object' && typeof data.length === 'undefined' && !Object.keys(data).length)) {
                    data = response.text;
                }
                var _status = -1;
                if (response['status'] >= 200 && response['status'] <= 299) {
                    //console.log(response['status']);
                    _status = 0;
                    resolve(true);
                }else if(response['status'] == 401){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Dirección)");
                }else if(response['status'] == 402){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Instrumento)");
                }else if(response['status'] == 403){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Instrumento pago)");
                }else if(response['status'] == 400){
                    resolve(false);
                    //cogoToast.warn("No se pudo registrar el token (Cliente)");
                }
            });
        })
    }

    jsonInstrument(){
        let jsonTarjeta = 
        '{' +
            '"card": {' +
              '"number": "' + this.carNumber + '"' +
            '}' +
          '}';
        return jsonTarjeta;
    }

    jsonPaymentInstrument(){
        let type = this.typeTarjeta();
        let jsonDireccion = 
            '{' +
                '"default":"true",' +
                '"card": {' +
                    '"expirationMonth": "' + this.expirationMonth + '",' +
                    '"expirationYear": "' + this.expirationYear + '",' +
                    '"type": "' + type + '"' +
                '},'+
                '"billTo":{' +
                    '"firstName":"' + this.firstName + '",' +
                    '"lastName":" ' + this.lastName + ' ",' +
                    '"address1":"Ciudad de Guatemala",' +
                    '"locality":"Guatemala",' +
                    '"postalCode":"01000",' +
                    '"country":"GT",' +
                    '"email":"' + this.email + '",' +
                    '"phoneNumber":"' + this.phoneNumber + '"' +
                '},' +
                '"instrumentIdentifier": {' +
                    '"id": "----------"' +
                  '}' +
            '}';
        return jsonDireccion;
    }

    typeTarjeta(){
        let type = this.carNumber.charAt(0);
        let t;
        if(type == '5' || type == '2'){
            t = '002';
        }else if(type == '4'){
            t = '001';
        }else{
            t = '000';
        }

        return t;
    }

    normalizeParams(params) {
        var newParams = {};
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key] != undefined && params[key] != null) {
                var value = params[key];
                if (Array.isArray(value)) {
                    newParams[key] = value;
                } else {
                    newParams[key] = this.paramToString(value);
                }
            }
        }
        return newParams;
    }

    paramToString(param) {
        if (param == undefined || param == null) {
            return '';
        }
        if (param instanceof Date) {
            return param.toJSON();
        }
        return param.toString();
    }
}
