import React from "react";
import Welcome from "../master_pobox";
import {Button, Form, Modal, Alert, Tab, Tabs} from "react-bootstrap";

class pruebaa extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            txt_tarjeta: '',
            txt_fecha: '',
        }
    }
    state = {
        txt_tarjeta: '',
        txt_fecha: '',
    }

    processForm() {
        //alert('123456');
        /*var teamMembers=new Array();
        console.log($("#"));
        var schoolName=$("#schoolname").val();
        var teamMembers=new Array();
        teamMembers.push($("#txt_tarjeta").val());
        teamMembers.push($("#txt_fecha").val());*/
        //alert('123456');
        /*var settings = {
            "url": "https://users.transexpress.com.gt/wsRestApp.php",
            "method": "GET",
            "timeout": 0,
          };
          
          $.ajax(settings).done(function (response) {
            alert(response);
            console.log(response);
          });*/

        const axios = require('axios');

        const header = new Headers();
        /*header.append('Access-Control-Allow-Origin', '*');
        header.append('Access-Control-Allow-Methods: GET, POST');
        header.append("Access-Control-Allow-Headers: X-Requested-With");*/

        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://users.transexpress.com.gt/wsRestApp.php',
        headers: {}
        };

        axios.request(config)
        .then((response) => {
        console.log(response.data);
        })
        .catch((error) => {
        //console.log(error);
        });

        // You must return false to prevent the default form behavior
        // default being reloading the page
        return false;
    }

    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="form-group">
                        <label className="form-label" htmlFor="txt_tarjeta">Nombre:</label>
                        <Form.Control value={this.state.txt_tarjeta} type="text"  placeholder="Nombre remitente" name="txt_tarjeta" maxLength={100} onChange={e => this.change(e)}/>
                    </div>    
                    <div className="form-group">
                        <label className="form-label" htmlFor="txt_fecha">Nombre:</label>
                        <Form.Control value={this.state.txt_fecha} type="text"  placeholder="Nombre remitente" name="txt_fecha" maxLength={100} onChange={e => this.change(e)}/>
                    </div>    

                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <Button variant="primary" block onClick = {e => this.processForm(e)} >Enviar Información</Button>
                        </div>!
                    </div>
                </main>
            </Welcome>
        )
    }
}
export default pruebaa;
