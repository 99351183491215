

export default [

    {
        path:"/Cotizacion",
        name: "Cotización",
        type: "option",
        icono: "fal fa-file-invoice"
    },
    {
        path:"/Prealerta",
        name: "Pre-Alerta",
        type: "option",
        icono: "fal fa-person-sign "
    },
    {
        path:"/MisPaquetes",
        name: "Mis Paquetes",
        type: "option",
        icono: "fal fa-window-restore"
    },
    {
        path:"/EstadoCuenta",
        name: "Estado de cuenta",
        type: "option",
        icono: "fal fa-dollar-sign"
    },
    {
        path:"/DespachoADomiciolio",
        name: "Despacho a domicilio",
        type: "option",
        icono: "fal fa-cars"
    },
    /*{
        path: "/Tarjetas",
        name: "Mis tarjetas de pago",
        type: "option",
        icono: "fal fa-credit-card"
    },*/
    {
        path:"/Direcciones",
        name: "Mis direcciones de entrega",
        type: "option",
        icono: "fal fa-address-book"
    },
    {
        path:"/Contactos",
        name: "Mis contactos",
        type: "option",
        icono: "fal fa-calendar-day"
    },    
    {
        path:"/AyudaPobox",
        name: "Ayuda",
        type: "option",
        icono: "fal fa-info-circle"
    },    
    {
        path: "/Inicio",
        name: "Volver menu principal",
        type: "option",
        icono: "fal fa-shield-alt"
    }/*,
    {
        path:"/pruebaa",
        name: "pruebaa",
        type: "option",
        icono: "fal fa-file-invoice"
    }*/

]
