import React from "react";
import Welcome from "../master_local";
import {Button, Form, Modal, Alert} from "react-bootstrap";
import CatDepartmaento from "../Components/Servicios/CatDepartmaento"
import apiUrl from "../enviroments/enviroment";
import cogoToast from "cogo-toast";
import axios from "axios";
import swal from "sweetalert";

class CotizacionLocal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            VerCajasAdicionales: false,
            cotizacion: [],
            tipo_envio: [],
            municipios: [],
            tmpMunicipios: [],
            TxtPeso: '',
            TxtAlto: '',
            TxtLargo: '',
            TxtAncho: '',
            cmbCajasAdicionales: '',
            TxtNoCajasAdicionales: '',
            ModalCotizacion: false,

        }
    }
    state = {
        ModalCotizacion: false,
        cmbTipoEnvio: '',
        cmbDepartamento: '',
        cmbMunicipio: '',
        TxtPeso: '',
        TxtAlto: '',
        TxtAncho: '',
        cmbCajasAdicionales: '',
        TxtNoCajasAdicionales: '',
        TxtLargo: '',
        tipo_envio: [],
        municipios: [],
        tmpMunicipios: [],

    }
    change_cajas_adicionales = e => {
        let ver_cajas_adicionales = true;
        if (e.target.value == "N")
            ver_cajas_adicionales = false;
        this.setState({
            [e.target.name]: e.target.value, VerCajasAdicionales:ver_cajas_adicionales,TxtNoCajasAdicionales: "0",
        })
    }
    change_departamento = e => {
        const temporal = this.state.municipios.filter(municipio => municipio.id_departamento == e.target.value );
        this.setState({
            [e.target.name]: e.target.value, tmpMunicipios: temporal
        })
    }
    change = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onSubmit = e => {
        e.preventDefault();

        let cmbTipoEnvio = this.state.cmbTipoEnvio;
        let cmbDepartamento = this.state.cmbDepartamento;
        let cmbMunicipio = this.state.cmbMunicipio;
        let TxtPeso = this.state.TxtPeso;
        let TxtAlto = this.state.TxtAlto;
        let TxtAncho = this.state.TxtAncho;
        let TxtLargo = this.state.TxtLargo;
        let cmbCajasAdicionales = this.state.cmbCajasAdicionales;
        let TxtNoCajasAdicionales = this.state.TxtNoCajasAdicionales;

        if (cmbTipoEnvio == 0 ||cmbTipoEnvio == null ){
            cogoToast.warn("Seleccione un tipo de envio");
            return;
        }
        if (cmbDepartamento == 0 ||cmbDepartamento == null ){
            cogoToast.warn("Seleccione un departamento");
            return;
        }
        if (cmbMunicipio == 0 ||cmbMunicipio == null ){
            cogoToast.warn("Seleccione un municipio");
            return;
        }
        if (TxtPeso == null || TxtPeso.toString().trim().length == 0){
            cogoToast.warn("El peso es necesario para continuar");
            return;
        }
        if (TxtAlto == null || TxtAlto.toString().trim().length == 0){
            TxtAlto = "0";
        }
        if (TxtAncho == null || TxtAncho.toString().trim().length == 0){
            TxtAncho = "0";
        }
        if (TxtLargo == null || TxtLargo.toString().trim().length == 0){
            TxtLargo = "0";
        }
        if ( cmbCajasAdicionales == null || cmbCajasAdicionales == "" )
            cmbCajasAdicionales = "N";

        let cajas_adic = "";
        let envia_cajas_adic = "";
        if ( cmbCajasAdicionales == "S")
            envia_cajas_adic = "1";
        else
            envia_cajas_adic = "0";

        if ( TxtNoCajasAdicionales == null || TxtNoCajasAdicionales == "" )
            cajas_adic = "0";
        else
          cajas_adic =  TxtNoCajasAdicionales * 1;

        if ( cmbCajasAdicionales == "S" && cajas_adic == "0" )
        {
            cogoToast.warn("Ingrese el número de cajas adicionales");
            return;
        }

        var data ={
            departamento: cmbDepartamento,
            municipio: cmbMunicipio,
            tipo_envio: cmbTipoEnvio,
            seccion: "0",
            peso_neto: TxtPeso,
            alto: TxtAlto,
            largo: TxtAncho,
            ancho:TxtLargo,
            caja_adicional_en: envia_cajas_adic,
            no_paquete_caja: TxtNoCajasAdicionales,
            uuid: "",
        };
        let url = apiUrl.apiUrl + "cotizacion_local";

        axios.post(url, data, {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        })
            .then((response) => {
                console.log(response.data[0]);
                this.setState({
                    ModalCotizacion: true,
                    cotizacion: response.data[0]
                })
            }, (error) => {
                swal("Ayuda", "Ha ocurrido un error, contactate con el administrador del sitio web", "error");
            });



    }
    componentDidMount() {

        axios.get(`${apiUrl.apiUrl}municipios` , {
            headers: {
                userid: 'transexpress',
                pwduser: 'JTOYV9IlFarK6G2jPRo7dJxkmfHOhD9S',
            }
        }).then(res => {
            this.setState({municipios: res.data});
        });
    }
    render(){
        return (
            <Welcome>
                <main id="js-page-content" role="main" className="page-content">
                    <div className="subheader">
                        <h1 className="subheader-title">
                            <i className="fal fa-info-circle"/> Cotización Courier Local
                            <small>
                                Ingresa la informacion para poder realizar una cotización de courier local
                            </small>
                        </h1>
                    </div>
                    <div className="fs-lg fw-300 p-5 bg-white border-faded rounded mb-g">
                        <div className="panel-content">
                            <form>
                                <div className="form-group">
                                    <label className="form-label">Tipo Envío:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbTipoEnvio} name="cmbTipoEnvio" onChange={e => this.change(e)}>
                                        <option key={0} value={0} selected>Seleccione un tipo de envío</option>
                                        <option key={1} value={1}>DOCUMENTOS</option>
                                        <option key={2} value={2}>MUESTRAS</option>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Departamento</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbDepartamento} name="cmbDepartamento" onChange={e => this.change_departamento(e)}>
                                        <option value={0} selected>Seleccione un departamento</option>
                                        <CatDepartmaento></CatDepartmaento>
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Municipio</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbMunicipio } name="cmbMunicipio" onChange={e => this.change(e)}>
                                        <option key={0} value={0}  selected>Seleccione un Municipio</option>
                                        {this.state.tmpMunicipios.map(opt => (
                                            <option key={opt.id_municipio} value={opt.id_municipio}>{opt.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtPeso">Peso real envío Lbs:</label>
                                    <Form.Control value={this.state.TxtPeso} type="number" step="0.01" placeholder="Ingrese el peso" name="TxtPeso" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <h4>MEDIDAS EN CMS - PESO VOLUMÉTRICO</h4>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtAlto">Alto en centímetros</label>
                                    <Form.Control value={this.state.TxtAlto} type="number" step="0.01" placeholder="Alto en centímetros" name="TxtAlto" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtAncho">Ancho en centímetros</label>
                                    <Form.Control value={this.state.TxtAncho} type="number" step="0.01" placeholder="Ancho en centímetros" name="TxtAncho" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="TxtLargo">Largo en centímetros</label>
                                    <Form.Control value={this.state.TxtLargo} type="number" step="0.01" placeholder="Largo en centímetros" name="TxtLargo" onChange={e => this.change(e)}/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Cajas Adicionales:</label>
                                    <Form.Control  as="select" className="custom-select form-control" defaultValue="Seleccione..." value={this.state.cmbCajasAdicionales} name="cmbCajasAdicionales" onChange={e => this.change_cajas_adicionales(e)}>
                                        <option key={"N"} value={"N"}>NO</option>
                                        <option key={"S"} value={"S"}>SI</option>
                                    </Form.Control>
                                </div>
                                { this.state.VerCajasAdicionales &&
                                <div className="form-group">
                                    <label className="form-label" htmlFor="">No. Cajas Adicionales:</label>
                                    <Form.Control value={this.state.TxtNoCajasAdicionales} type="number" step="0.01" placeholder="Número de cajas adicionales" name="TxtNoCajasAdicionales" onChange={e => this.change(e)}/>
                                </div>
                                }
                                <Button variant="primary" block onClick = {e => this.onSubmit(e)} >Calcular Cotización</Button>
                            </form>
                        </div>

                    </div>
                </main>


                <Modal show={this.state.ModalCotizacion} onHide={() => this.setState({ ModalCotizacion: false })} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Alert variant="success">
                            <Alert.Heading>Cotización Local Generada</Alert.Heading>
                            <hr/>
                            <table>
                                <tr>
                                    <td><h4><b>Peso a cotizar:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{this.state.cotizacion.descripcion_peso }</h4></td>
                                </tr>

                                <tr>
                                    <td><h4><b>Peso Envío:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.peso_cotizacion * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Tipo Envío:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{ this.state.cotizacion.tipo_envio}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Departamento:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{ this.state.cotizacion.departamento_destinatario}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Municipio:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{ this.state.cotizacion.municipio_destinatario}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Sub Total Q.:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.tarifa_basica_envio * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Cajas Adicionales Q.:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{(Math.round(this.state.cotizacion.total_caja_adicional * 100) / 100).toFixed(2)}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4><b>Total con IVA Q.:</b></h4></td>
                                    <td width="100"></td>
                                    <td><h4>{((Math.round(this.state.cotizacion.total_cotizacion * 100) / 100) ).toFixed(2)  }</h4></td>
                                </tr>
                            </table>
                        </Alert>


                    </Modal.Body>
                </Modal>

            </Welcome>
        )
    }
}
export default CotizacionLocal;
